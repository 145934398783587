.home--banner-container {
  .home--banner {
    .container {
      max-width: 1440px;
    }
  }
}

.home--banner {
  position: relative;
  width: 100%;
  min-height: 380px;
  border-bottom: 1px solid #D5D5D5;

  @media screen and (max-width: 720px) {
    min-height: auto;
  }

  .container {
    position: relative;
    min-height: 380px;
    padding: 30px 20px;
    margin: 0 auto;
    box-sizing: border-box;
    width: calc(100% - 40px);
    max-width: 1355px;

    @media screen and (max-width: 720px) {
      min-height: auto;
      padding: 0;
      width: 100%;
    }

  }

  .home--banner-bg {
    display: block;
    background-color: white;
    width: 50%;
    left: 0;
    height: 100%;
    position: absolute;
    z-index: -1;

    @media screen and (max-width: 720px) {
      display: none;
    }

  }

  .home--banner-content {
    display: block;
    width: 42%;
    padding: 60px 0;

    @media screen and (max-width: 720px) {
      width: calc(100% - 40px) !important;
      padding: 20px 20px 30px 20px !important;
    }

    h1 {
      @include font(42px, black, $bold);
      margin: 0 0 20px 0;
      line-height: 53px;

      @media screen and (max-width: 500px) {
        font-size: 36px;
        line-height: 47px;
      }

    }

    p {
      @include font(16px, black, $regular);
      padding-bottom: 20px;

      @media screen and (max-width: 500px) {
        font-size: 14px;
      }

      a {
        color: $red;
        text-decoration: underline;
        font-weight: $semibold;
      }

      &:last-child {
        padding-bottom: 0;
      }

    }

    .buttons {
      @include flex;
      align-items: center;

      @media screen and (max-width: 380px) {
        flex-wrap: wrap;
      }

      a {
        display: inline-block;
        width: auto;
        // width: 50%;
        @include font(16px, white, $bold);
        text-decoration: none;
        text-align: center;
        border-radius: 4px;
        padding: 10px 12px 10px 20px;
        background-repeat: no-repeat;
        @include animate;
        background-color: $red;

        @media screen and (max-width: 380px) {
          width: 100% !important;
          max-width: 100% !important;
        }

        &:hover {

          &.red {
            background-color: $red-dark;
          }

          &.white {
            background-color: $grey;
          }

          &.blue {
            background-color: $dark-blue;
          }

        }

      }

    }

  }

  .home--announcement {
    display: block;
    width: calc(80% - 40px);
    max-width: calc(50% - 40px);
    text-align: center;
    background-color: $blue;
    text-align: left;
    @include flex;
    align-items: center;
    @include valign;
    position: absolute;
    right: 20px;

    @media screen and (max-width: 1030px) {
      flex-wrap: wrap;
      justify-content: center;
    }

    @media screen and (max-width: 720px) {
      position: static;
      width: 100%;
      margin-bottom: 20px;
      max-width: 100%;
      right: auto;
      bottom: auto;
      transform: translateY(0);

      &.spow--banner {
        background-color: $spow !important;

        .copy {
          p,h2,h3,a {
            color: white;
          }

        }

      }

    }

    .copy {
      display: inline-block;
      padding: 17px 20px 22px 20px;

      h2 {
        @include font(24px, white, $semibold);
        margin: 0 0 15px 0;
        padding-left: 38px;

        &:first-child {
          background-image: url('../img/index/announcment-icon.png');
          background-repeat: no-repeat;
          background-position: left center;
          background-size: 30px 25px;
        }

      }

      h3 {
        @include font(18px, white, $semibold);
        margin: 0 0 10px 0;
      }

      p {
        @include font(12px, white, $regular);
        padding-bottom: 15px;

        &:last-child { padding-bottom: 0 }

        a {
          text-decoration: underline;
          font-weight: $semibold;
          color: white;
        }

      }

    }

    &.spow--banner {
      background-color: $spow;

      .copy {
        display: inline-block;
        width: 60%;
        padding-right: 30px;

        @media screen and (max-width: 1030px) {
          order: 2;
          width: 100%;
          padding: 0;
          text-align: center;
        }

        @media screen and (max-width: 720px) {
          padding: 20px;
        }

        p {
          font-size: 16px;
        }

        .buttons {
          @include flex;
          align-items: center;

          @media screen and (max-width: 1030px) {
            justify-content: center;
          }

          @media screen and (max-width: 380px) {
            flex-wrap: wrap;
          }

          a {
            display: inline-block;
            width: auto;
            // width: 50%;
            @include font(16px, white, $bold);
            text-decoration: none;
            text-align: center;
            border-radius: 4px;
            padding: 10px 12px;
            background-repeat: no-repeat;
            @include animate;
            background-color: $red;

            @media screen and (max-width: 380px) {
              width: 100% !important;
              max-width: 100% !important;
            }

            &:hover {

              &.red {
                background-color: $red-dark;
              }

            }

          }

        }

      }

      .spow--banner-logo {
        display: inline-block;
        width: 40%;

        @media screen and (max-width: 1030px) {
          order: 1;
          margin-bottom: 20px;
        }

        @media screen and (max-width: 720px) {
          margin-bottom: 0;
          padding-top: 15px;
          width: 100%;
          max-width: 150px
        }

        img {
          display: block;
          width: 100%;
          height: auto;
        }

      }

    }

  }

  .home--banner-img {
    display: block;
    width: 50%;
    right: 0;
    position: absolute;
    height: 100%;
    z-index: -1;
    top: 0;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: cover;
    background-image: url('../img/index/banner.png');

    @media screen and (max-width: 720px) {
      position: static;
      width: 100%;
      min-height: 200px;
      height: auto;
      padding: 20px 0;

      &.hideme, &.spow--banner {
        display: none !important;
      }

    }

    &.spow--banner {
      background-color: $spow;
      background-image: none !important;
    }

  }

}

.home--cols {
  padding: 80px 0;

  @media screen and (max-width: 720px) {
    padding: 40px 0;
  }

  .home--cols-container {
    padding: 0 20px;
    margin: 0 auto;
    width: calc(100% - 40px);
    max-width: 1440px;
  }

  .flex {
    @include flex;

    @media screen and (max-width: 720px) {
      flex-wrap: wrap;
    }

    .col {
      display: inline-block;
      width: calc(100% / 3);
      margin-right: 20px;

      @media screen and (max-width: 720px) {
        width: calc(50% - 10px);

        &:nth-child(2) {
          margin-right: 0;
        }

      }

      @media screen and (max-width: 500px) {
        width: 100% !important;
        margin-right: 0 !important;
        margin-bottom: 20px;

        &:last-child { margin-bottom: 0 !important }

      }

      &:nth-child(3) {
        margin-right: 0;

        @media screen and (max-width: 720px) {
          margin-top: 20px;
        }

      }

      .image {
        display: block;
        width: 100%;
        background-image: url('../img/index/col.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        min-height: 180px;
      }

      .main--link {
        background-color: white;
        background-position: left 15px center;
        background-repeat: no-repeat;
        position: relative;

        a {
          display: block;
          text-decoration: none;
          @include font(22px, black, $regular);
          padding: 20px 45px 20px 57px;
          background-image: url('../img/global/arrow--red.svg');
          background-repeat: no-repeat;
          background-position: right 15px center;
          background-size: 12px 18px;
          @include animate;

          &:hover {
            background-color: #F7F7F7;
            color: $red;
          }

          span {
            display: inline-block;
            @include valign;
            position: absolute;
            left: 15px;
            width: 100%;
            max-width: 25px;

            img {
              display: block;
              width: 100%;
              height: auto;
            }

          }

        }

      }

      .links {

        ul {

          li {
            display: block;
            width: 100%;
            background-color: #F7F7F7;
            padding: 0;
            border-bottom: 1px solid #DBDBDB;
            background-image: url('../img/global/arrow--black.svg');
            background-repeat: no-repeat;
            background-position: right 15px center;
            background-size: 8px 12px;
            @include animate;

            &:last-child { border-bottom: 0 }

            &:hover {
              background-color: $grey;
            }

            a {
              display: block;
              width: calc(100% - 55px);
              padding: 18px 35px 18px 20px;
              text-decoration: none;
              @include font(15px, black, $regular);
            }

          }

        }

      }

    }

  }

}

.home--news {
  background-color: $light-blue;
  padding: 60px 0;

  @media screen and (max-width: 720px) {
    padding: 40px 0;
  }

  .news-container {
    width: calc(100% - 40px);
    max-width: 1440px;
    padding: 0 20px;
    margin: 0 auto;
  }

  h2 {
    @include font(25px, black, $regular);
    margin: 0 0 20px 0;
    padding-left: 50px;
    background-image: url('../img/index/icon--news.svg');
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 37px 27px;

    &:after {
      display: inline-block;
      content: '';
      background-image: url('../img/global/arrow--red.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 10px 14px;
      width: 10px;
      height: 14px;
      margin-left: 10px;
    }

  }

  .flex {
    @include flex;

    @media screen and (max-width: 650px) {
      flex-wrap: wrap;
    }

    .col {
      display: inline-block;
      width: calc(50% - 15px);

      @media screen and (max-width: 650px) {
        width: 100%;
        margin-right: 0 !important;
        margin-bottom: 20px;

        &:last-child { padding-bottom: 0 }

      }

      &:nth-child(1) {
        margin-right: 30px;
      }

      &.news--main {
        background-color: white;

        .img {
          background-image: url('../img/index/col.png');
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          min-height: 238px;
        }

        .copy {
          padding: 25px;

          h3 {
            @include font(22px, black, $bold);
            margin: 0 0 20px 0;
          }

          p {
            @include font(14px, black, $regular);

            &.date {
              font-size: 14px;
              color: #797979;
              margin-bottom: 10px;
            }

          }

          .read--more {
            display: block;
            @include font(14px, $red, $regular);
            text-decoration: none;
            margin-top: 25px;
            @include animate;

            &:hover {
              color: black;
            }

            &:after {
              display: inline-block;
              content: '';
              background-image: url('../img/global/arrow--red.svg');
              background-repeat: no-repeat;
              background-position: center;
              background-size: 7px 10px;
              width: 7px;
              height: 10px;
              margin-left: 6px;
              @include animate;
            }

          }

        }

      }

      .news--feed {
        background-color: white;
        border-top: 3px solid $blue;
        margin-bottom: 30px;
        padding: 25px;
        position: relative;

        .news--feed-slick {
          margin-bottom: 20px;

          li {
            &:focus {
              outline: none;
            }
          }

        }

        h4 {
          @include font(22px, black, $bold);
          margin: 0 0 20px 0;
        }

        p {
          @include font(14px, black, $regular);

          &.date {
            font-size: 14px;
            color: #797979;
            margin-bottom: 10px;
          }

        }

        .news--feed-nav {
          position: relative;
          padding-left: 20px;

          .news--feed-arrows {
            display: inline-block;

            &.-prev {
              margin-right: 7px;
              .slick-next {
                display: none !important;
              }
            }

            &.-next {
              .slick-prev {
                // display: none !important;
                position: absolute;
                left: 0;
              }
            }

            button {
              display: inline-block;
              background-image: url('../img/global/arrow--black.svg');
              background-repeat: no-repeat;
              background-position: center;
              background-size: 6px 7px;
              width: 10px;
              height: 7px;
              background-color: rgba(0,0,0,0);
              border: none;
              border-radius: 0;
              position: static;
              margin: 0;

              &:focus { outline: none }

              // &.feed-prev {
              //   transform: rotate(180deg);
              // }

            }

          }

          .news--feed-dots {
            display: inline-block;
            vertical-align: middle;

            .slick-dots {
              max-height: 5px;

              li {
                display: inline-block;
                width: 10px;
                height: 3px;
                background-color: #DDDDDD;
                margin-right: 8px;
                cursor: pointer;

                &.slick-active {
                  background-color: $blue;
                  border: 1px solid $blue;
                }

                button {
                  text-indent: -999999px;
                  background: none;
                  border: none;
                }

              }

            }

          }

        }

        .news--feed-more {
          position: absolute;
          right: 25px;
          bottom: 25px;

          a {
            display: block;
            @include font(14px, $red, $regular);
            text-decoration: none;
            margin-top: 25px;
            @include animate;

            &:hover {
              color: black;
            }

            &:after {
              display: inline-block;
              content: '';
              background-image: url('../img/global/arrow--red.svg');
              background-repeat: no-repeat;
              background-position: center;
              background-size: 7px 10px;
              width: 7px;
              height: 10px;
              margin-left: 6px;
              @include animate;
            }
          }

        }

      }

      .news--form {
        background-color: $blue;
        padding: 25px;

        h3 {
          @include font(22px, white, $regular);
          margin: 0 0 20px 0;

          &:before {
            display: inline-block;
            content: '';
            background-image: url('../img/index/icon--newsletter.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 30px 31px;
            width: 30px;
            height: 31px;
            margin-right: 10px;
            float: left;
          }

        }

        p {
          @include font(16px, white, $regular);
        }

        .news--form-flex {
          .col {

            &.main--form {
              margin-right: 15px;
              width: calc(100% - 150px);

              @media screen and (max-width: 650px) {
                width: 100%;
                margin-right: 0;
              }

            }

            &.submit--form {
              @media screen and (max-width: 650px) {
                margin-bottom: 0;
              }
            }

          }
        }

      }

    }

  }

}
