.home--banner {
  &.award {
    border-bottom: 0;

    .container {
      // max-width: 1340px;
    }

    .home--banner-content {

      h1 {
        line-height: 1.4;
        margin-bottom: 25px;
      }

      p {
        line-height: 1.6;
      }

      .buttons {
        margin-top: 15px;

        a {
          background-color: $gold;
          background-image: url("../img/global/arrow--white.svg");
          background-repeat: no-repeat;
          background-size: 8px 12px;
          background-position: right 20px center;
          padding: 15px 45px 15px 25px;

          &:hover {
            background-color: #AA9965;
          }

        }

      }

    }

    .home--banner-img {

      @media screen and (max-width: 720px) {
        position: relative;
        height: 60vw;
      }

      .award-logo {
        position: absolute;
        top: 20px;
        left: 20px;
        display: block;
        width: 100%;
        height: auto;
        max-width: 160px;

        @media screen and (max-width: 720px) {
          max-width: 25vw;
        }

      }

    }

  }
}


.award--links {
  background-color: #293787;
  padding: 30px 0;
  margin-top: 60px;

  .container {
    // max-width: 1340px;
  }

  .flex {
    @include flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
    }

    .link {
      display: inline-flex;
      display: -webkit-inline-flex;
      // width: calc(100% / 3);
      text-decoration: none;
      color: white;
      align-items: center;

      @media screen and (max-width: 767px) {
        max-width: 100% !important;
      }

      .icon {
        display: inline-block;
        background-repeat: no-repeat;
        background-position: center;

        @media screen and (max-width: 767px) {
          width: 50px !important;
          min-width: 50px !important;
        }

      }

      .copy {
        display: inline-block;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;

        @media screen and (max-width: 767px) {
          max-width: 100% !important;
        }

        h4 {
          @include font(18px, white, $regular);
          margin: 0;
        }

      }

      .arrow {
        display: inline-block;
        background-image: url("../img/global/arrow--white.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 14px 18px;
        width: 14px;
        height: 18px;
        position: static;
      }

      &:nth-child(1) {
        max-width: 260px;

        .icon {
          background-image: url('../img/awards/icon--article.svg');
          background-size: 39px 50px;
          height: 50px;
          width: 39px;
          min-width: 39px;
        }

        .copy {
          max-width: 180px;
        }

      }

      &:nth-child(2) {
        max-width: 390px;
        margin: 0 20px;

        @media screen and (max-width: 767px) {
          margin: 25px 0;
        }

        .icon {
          background-image: url('../img/awards/icon--play.svg');
          background-size: 50px 50px;
          height: 50px;
          width: 50px;
          min-width: 50px;
        }

        .copy {
          max-width: 295px;
        }

      }

      &:nth-child(3) {
        max-width: 300px;

        .icon {
          background-image: url('../img/awards/icon--web.svg');
          background-size: 26px 44px;
          height: 50px;
          width: 26px;
          min-width: 26px;
        }

        .copy {
          max-width: 230px;
        }

      }

    }

  }

}


.award--header {
  padding: 60px 0;

  @media screen and (max-width: 767px) {
    padding: 30px 0;
  }

  h2 {
    text-align: center;
    @include font(36px, black, $bold);
    margin-bottom: 0;
  }

}

.award--winners {
  padding: 60px 0;
  background-color: #EDEDED;

  @media screen and (max-width: 767px) {
    padding: 30px 0;
  }

  .container {
    // max-width: 1340px;
  }

  .award--winner {
    @include flex;
    margin-bottom: 50px;
    align-items: center;

    @media screen and (max-width: 800px) {
      flex-wrap: wrap;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:nth-child(even) {
      flex-direction: row-reverse;

      .copy {
        padding-left: 0;
        padding-right: 70px;

        @media screen and (max-width: 1200px) {
          padding-left: 0;
          padding-right: 40px;
        }

      }

    }

    .image {
      display: inline-block;
      width: 50%;
      min-height: 435px;
      // flex: 1;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      @media screen and (max-width: 800px) {
        width: 100%;
        height: 60vw;
        flex: auto;
        min-height: auto;
      }

    }

    .copy {
      display: inline-block;
      width: calc(50% - 70px);
      padding: 40px 0 40px 70px;

      @media screen and (max-width: 1200px) {
        padding-right: 0;
        padding-left: 40px;
        width: calc(50% - 40px);
      }

      @media screen and (max-width: 800px) {
        width: 100%;
        padding: 20px 0 !important;
      }

      h3 {
        @include font(32px, black, $bold);
        margin: 0 0 30px 0;
        line-height: 1.4;
      }

      p, li {
        @include font(14px, black, $regular);
        padding-bottom: 20px;
        line-height: 1.6;

        &:last-child {
          padding-bottom: 0;
        }

        a {
          color: black;
          text-decoration: underline;
          font-weight: $bold;
        }

      }

      ul {
        list-style: disc;
        padding-left: 20px;

        li {
          padding-bottom: 10px;
        }

      }

      .buttons {
        @include flex;
        margin-top: 15px;

        @media screen and (max-width: 550px) {
          flex-wrap: wrap;
          flex-direction: column;
        }

        a {
          display: inline-block;
          background-color: $gold;
          color: white;
          text-decoration: none;
          @include font(14px, white, $bold);
          background-image: url("../img/global/arrow--white.svg");
          background-repeat: no-repeat;
          background-size: 8px 12px;
          background-position: right 20px center;
          padding: 15px 40px 15px 20px;
          border-radius: 4px;
          text-align: center;
          margin-right: 15px;
          @include animate;

          @media screen and (max-width: 550px) {
            display: block;
            width: auto;
            margin-right: 0;
            margin-bottom: 20px;
          }

          &:last-child {
            margin-right: 0;
            margin-bottom: 0;
          }

          &:hover {
            background-color: #AA9965;
          }

        }

      }

    }

  }

}
