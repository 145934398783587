.contact-sections {
    @include clearfix;

    .contact-section {
        width: 32%;
        float: left;
        margin-right: 2%;

        &:nth-child(3) {
            margin-right: 0;
        }

        &--key {
            width: 45%;
            margin-right: 7.5%;

            &:nth-child(2) {
                margin-right: 0;
            }

            .contact-pod {
                a.break-word {
                    word-wrap: break-word;
                    padding-right: 10px;
                    display: block;
                }

                p {
                    margin: 10px 0;
                }
            }
        }

        @media screen and (max-width: 930px) {
            width: 100%;
        }
    }

        .contact-pod {
            @include clearfix;

            padding: 15px;
            border: 1px solid $text-black;
            // border-radius: 15px;
            margin-bottom: 20px;
            font-size: 15px;

            &.red-bold {
                p {
                    strong {
                        color: $red;
                    }
                }
            }

            a {
                color: $red;
                font-weight: $bold;
            }

            p {
                font-size: 13px;
            }

            h3 {
                margin-bottom: 10px;
            }

            h4 {
                margin-top: 20px;
            }

            &.-grey {
                padding: 0;

                .contact-padding-fix {
                    padding: 15px;
                }

                p {
                    margin-bottom: 10px;
                }
            }

            &.-large-text {
                p {
                    font-size: 15px;
                }
            }

            .hide {
                display: none;
            }
        }

        .contact-number,
        .contact-email  {
            color: $red;
            font-weight: $bold;
            background-size: contain;
            background-repeat: no-repeat;
            padding-left: 35px;
            margin: {
                top: 8px;
                bottom: 8px;
            }
        }

        .contact-number {
            background-image: url('../img/svg/svg--icon-helplines.svg');
            background-size: 20px auto;
        }

        .contact-email {
            background-image: url('../img/svg/svg--icon-email.svg');
            background-size: 21px 22px;
            word-wrap: break-word;
            font-size: 12px;
            background-position: left center;
        }

        .grey-section {
            background-color: #E5E5E5;
            padding: 15px;
        }

        .form {
          .submit {
            margin-top: 15px;
          }
        }
}

.contact-map {
    width: 70%;
    height: 450px;
}

.contact-map-text {
    width: 30%;
    padding-left: 20px;
    box-sizing: border-box;

    p {
        margin-bottom: 30px;
    }

    @media screen and (max-width: 930px) {
        padding-left: 0;

        p {
            margin-top: 15px;
            margin-bottom: 15px;
        }
    }
}

.contact-map,
.contact-map-text {
    float: left;

    @media screen and (max-width: 930px) {
        width: 100%;
        float: none;
    }
}

.contact-footer {
    p {
        font-size: 13px;
        line-height: 1.8;
    }
}
