.pathway-full {
  .pathways {
    .pathway {
      float: none;
      width: 100% !important;
      margin-left: 0 !important;
      margin-right: 0 !important
    }
  }
}

.pathways {
    @include clearfix;

    .pathway {
        float: left;
        border: 1px solid #ccc;
        // border-radius: 15px;
        background-color: #fff;
        padding: {
            top: 53px;
            right: 20px;
            bottom: 13px;
            left: 20px;
        }
        box-sizing: border-box;
        position: relative;
        margin: {
            top: 80px;
            right: 82px;
        }
        z-index: 3;
        color: $off-black;
        font-weight: $bold;
        font-size: 20px;
        display: block;
        text-decoration: none;

        p {
            color: $off-black;
            font-weight: $bold;
            font-size: 20px;
        }

        .icon {
            top: -15px;
            left: 13px;
        }

        &:before {
            width: 100%;
            height: 40px;
            content: '';
            display: block;
            background-color: $red;
            position: absolute;
            top: -1px;
            left: -1px;
            // border-top-left-radius: 15px;
            // border-top-right-radius: 15px;
            border: 1px solid $red;
            z-index: 2;
        }

        // &:after {
        //     width: 70px;
        //     height: 70px;
        //     content: '';
        //     display: block;
        //     background-color: $red;
        //     position: absolute;
        //     top: -29px;
        //     left: -1px;
        //     // border-radius: 70px;
        //     z-index: 1;
        // }

        &.-dark-red {

            &:before {
                background-color: $red-dark;
                border: 1px solid $red-dark;
            }

            &:after {
                background-color: $red-dark;
            }
        }

        &.-off-black {

            &:before {
                background-color: $off-black;
                border: 1px solid $off-black;
            }

            &:after {
                background-color: $off-black;
            }
        }

        &.-mid-red {

            &:before {
                background-color: $mid-red;
                border: 1px solid $mid-red;
            }

            &:after {
                background-color: $mid-red;
            }
        }

        &.-new-red {

            &:before {
                background-color: $new-red;
                border: 1px solid $new-red;
            }

            &:after {
                background-color: $new-red;
            }
        }

        &.-right-side {
            &:before {
                right: -1px;
                left: auto;
            }

            &:after {
                right: -1px;
                left: auto;
            }
        }

        &-anchor {
            display: block;
            font-size: 14px;
            text-align: center;
            text-decoration: none;
            color: $red;
            margin: {
                top: 40px;
                bottom: 5px;
            }

            span {
                width: 100%;
                height: 25px;
                background-image: url('../img/svg/svg--arrow-side-red.svg');
                background-size: 21px 12px;
                background-position: center bottom;
                background-repeat: no-repeat;
                display: block;
            }

            &.-dark-red {
                color: $red-dark;

                span {
                    background-image: url('../img/svg/svg--arrow-side-dark-red.svg');
                }
            }

            &.-mid-red {
                color: $mid-red;

                span {
                    background-image: url('../img/svg/svg--arrow-side-mid-red.svg');
                }
            }

            &--products {
                margin-top: 20px;
                margin-bottom: 0;
                font-weight: $bold;
            }
        }

        .icon {
            display: block;
            position: absolute;
            z-index: 4;
            background-size: cover;
        }

        .arrow {
            top: 59%;
            right: 15px;
        }

        .title {
            color: #fff;
            position: absolute;
            top: 9px;
            left: 68px;
            z-index: 2;
            font-size: 17px;
            font-weight: $bold;
        }

        &.-brokers-and-intermediaries {
            .icon {
                width: 48px;
                height: 30px;
                background-image: url('../img/svg/svg--icon-brokers.svg');
                top: -7px;
                left: 10px;
            }
        }

        &.-church-and-manse {
            .icon {
                // width: 32px;
                // height: 48px;
                // background-image: url('../img/svg/svg--icon-church-manse.svg');
                top: -15px;
                left: 12px;

                &.--icon-fix {
                  width: 32px;
                  height: 48px;
                  background-image: url('../img/svg/svg--icon-church-manse.svg');
                }

            }
        }

        &.-cm-single {
            .icon {
                // width: 32px;
                width: 18px;
                // background-color: pink;
                // height: 48px;
                height: 28px;
                background-image: url('../img/svg/svg--icon-church-manse.svg');
                top: 5px;
                left: 18px;
            }
        }

        &.-make-claim {
            .icon {
                width: 35px;
                height: 42px;
                background-image: url('../img/svg/svg--icon-claim.svg');
                top: -13px;
                left: 18px;
            }
        }

        &.-elt {
            .icon {
                width: 43px;
                height: 39px;
                background-image: url('../img/svg/svg--icon-elt.svg');
                top: -12px;
                left: 14px;
            }
        }

        &.-supporting-churches {
            .icon {
                width: 50px;
                height: 36px;
                background-image: url('../img/svg/svg--icon-supporting-church.svg');
                top: -10px;
                left: 10px;
            }
        }

        &.-charitable-trust {
            .icon {
                width: 52px;
                height: 34px;
                background-image: url('../img/svg/svg--icon-charitable-trust.svg');
                top: -9px;
                left: 8px;
            }
        }

        &.-policyholders {
            .icon {
                width: 48px;
                height: 29px;
                background-image: url('../img/svg/svg--icon-policyholders.svg');
                background-size: 46px auto;
                background-repeat: no-repeat;
                top: -6px;
                left: 10px;
            }
        }

        &.-manse {
            .icon {
                // width: 40px;
                // height: 44px;
                // background-image: url('../img/svg/svg--icon-manse.svg');
                top: -15px;
                left: 12px;

                &.--icon-fix {
                  width: 40px;
                  height: 44px;
                  background-image: url('../img/svg/svg--icon-manse.svg');
                }

            }
        }

        &.-m-single {
            .icon {
                // width: 40px;
                // height: 44px;
                height: 28px;
                width: 24px;
                background-image: url('../img/svg/svg--icon-manse.svg');
                top: 4px;
                left: 12px;
            }
        }

        &.-liability {
            .icon {
                width: 31px;
                height: 46px;
                background-image: url('../img/svg/svg--liability.svg');
                top: -15px;
                left: 18px;
            }
        }

        &.-reporting {
            .icon {
                width: 19px;
                height: 36px;
                background-image: url('../img/svg/svg--1.svg');
                top: -13px;
                left: 20px;
            }
        }

        &.-what_will {
            .icon {
                // width: 49px;
                // height: 32px;
                height: 26px;
                width: 38px;
                background-image: url('../img/svg/svg--icon-what-will.svg');
                top: 6px;
                left: 10px;
            }
        }

        &.-response {
            .icon {
                // width: 43px;
                // height: 39px;
                height: 27px;
                width: 30px;
                background-image: url('../img/svg/svg--icon-response.svg');
                top: 6px;
                // right: 10px;
                left: 10px;
            }

        }

        &.-business {
            .icon {
                // width: 43px;
                // height: 38px;
                height: 28px;
                width: 33px;
                background-image: url('../img/svg/svg--icon-business-white.svg');
                top: 4px;
                left: 13px;
            }

        }

        /* &.-liability {
            .icon {
                width: 27px;
                height: 43px;
                background-image: url('../img/svg/svg--icon-question-mark.svg');
                top: -13px;
                left: 20px;
            }
        } */

        &.-double-height {
            padding: {
                top: 67px;
                right: 20px;
                bottom: 26px;
                left: 20px;
            }
        }

        &.-right-side {
            &:before {
                right: -1px;
                left: auto;
            }

            &:after {
                right: -1px;
                left: auto;
            }

            &.-brokers-and-intermediaries {
                .icon {
                    right: 10px;
                    left: auto;
                }
            }

            &.-church-and-manse {
                .icon {
                    right: 18px;
                    left: auto;
                }
            }

            &.-make-claim {
                .icon {
                    right: 13px;
                    left: auto;
                }
            }

            &.-elt {
                .icon {
                    right: 14px;
                    left: auto;
                }
            }

            &.-supporting-churches {
                .icon {
                    right: 10px;
                    left: auto;
                }
            }

            &.-charitable-trust {
                .icon {
                    right: 8px;
                    left: auto;
                }
            }

            &.-policyholders {
                .icon {
                    right: 10px;
                    left: auto;
                }
            }

            &.-manse {
                .icon {
                    left: auto;
                    right: 13px;
                }
            }

            &.-next {
                .icon {
                    width: 25px;
                    height: 36px;
                    background-image: url('../img/svg/svg--2.svg');
                    top: -15px;
                    left: auto;
                    right: 20px;
                }
            }

            .title {
                left: 16px;
            }
        }
    }
}
