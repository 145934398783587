.pathways {

    &.-supporting {
        .pathway {
            width: 30.6%;
            margin: {
                top: 50px;
                right: 4%;
            }
            padding: {
                top: 70px;
                left: 30px;
                right: 30px;
            }

            &:last-child {
                margin-right: 0;
            }

            p {
                font-size: 14px;
            }

            &.-manse {
                margin: {
                    right: 0;
                    left: 4%;
                }
            }

            @media screen and (max-width: 898px) {
                width: 100%;
                margin: {
                    right: 0;
                    left: 0;
                }

                &.-manse {
                    margin: {
                        right: 0;
                        left: 0;
                    }
                }
            }

            .title {
                left: 15px;
                margin-right: 20px;
            }

            &.-spow {
                background-color: $red;
                border: 1px solid $red;

                .icon {
                    width: 22px;
                    height: 34px;
                    top: -30px;
                    left: 25px;
                    background-image: url('../img/svg/svg--icon-church-manse.svg');
                    background-size: 22px auto;
                    background-repeat: no-repeat;
                }
            }

            &.-lens {
                background-color: $red-dark;
                border: 1px solid $red-dark;

                .icon {
                    background-image: url('../img/svg/svg--icon-camera.svg');
                    width: 34px;
                    height: 26px;
                    top: -23px;
                    left: 22px;
                }
            }

            &.-sponsoring {
                background-color: $off-black;
                border: 1px solid $off-black;

                .icon {
                    background-image: url('../img/svg/svg--icon-family.svg');
                    width: 40px;
                    height: 28px;
                    top: -23px;
                    left: 18px;
                }
            }

            &:before {
                width: 100%;
                height: 40px;
                content: '';
                display: block;
                position: absolute;
                top: -1px;
                left: -1px;
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
                z-index: 2;
            }

            &:after {
                width: 80px;
                height: 80px;
                top: -35px;
            }

            .arrow {
                display: block;
                background-image: url('../img/svg/svg--arrow-white-down.svg');
                width: 21px;
                height: 12px;
                background-position: left center;
                margin-top: 15px;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }

        }
    }
}

.supporting-churches {
    margin-top: 40px;

    .supporting-church {

        p {
            margin: {
                top: 10px;
                bottom: 10px;
            }

            a {
                color: $red;
                font-weight: $bold;
            }
        }

    }

    .benefit-split-line {
        margin: {
            top: 30px;
            bottom: 30px;
        }
    }
}

.supporting_content {

    .content_block {
        padding: 58px 0 0 0;

        h2 {
          font-size: 22px;
          font-weight: $bold;
          display: inline-block;
          margin-left: 15px;
          margin-bottom: 15px;
        }

        a {
          color: $red;
          text-decoration: underline;
          font-weight: $bold;
        }
        p {
          margin-bottom: 15px;
        }

        &.spow {

            .icon {
              background-image: url('../img/svg/svg--icon-church-manse-red.svg');
              display: inline-block;
              width: 40px;
              height: 60px;
              background-size: 40px 60px;
            }


        }

        &.ftal {

          .icon {
            background-image: url('../img/svg/svg--icon-camera-red.svg');
            width: 47px;
            height: 37px;
            background-size: 47px 37px;
            display: inline-block;
          }

        }

        &.sponsoring {

          .icon {
            background-image: url('../img/svg/svg--icon-supporting-church-black.svg');
            width: 48px;
            height: 34px;
            background-size: 48px 34px;
            display: inline-block;
          }

        }

    }

}

.awards-ctas {
    a {
        width: 326px;
        float: left;
        display: block;
        text-decoration: none;
        color: $off-black;
        font-weight: $bold;
        font-size: 17px;
        border: 1px solid $off-black;
        border-radius: 15px;
        position: relative;
        box-sizing: border-box;
        padding: {
            top: 20px;
            right: 40px;
            bottom: 20px;
            left: 125px;            
        }

        @media screen and (max-width: 1050px) {
            width: 100%;
        }

        &.urc {
            background-image: url('../img/urc.png'); 
            background-size: 100px auto;
            background-repeat: no-repeat;
            background-position: 13px center;
            margin-right: 20px;
            height: 134px;
            padding: {
                top: 42px;
            }

            @media screen and (max-width: 1050px) {
                height: auto;
                padding: {
                    top: 40px;
                    bottom: 40px;
                }
            }
        }

        &.fed {
            background-image: url('../img/congregational-federation.png'); 
            background-size: 80px auto;
            background-repeat: no-repeat;
            background-position: 23px center;
            margin-right: 20px;

            @media screen and (max-width: 1050px) {
                margin-top: 20px;
                padding: {
                    top: 25px;
                    bottom: 25px;
                }
            }
        }
    }

    .arrow {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
    }
}

