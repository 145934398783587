.tabs {
    margin-top: 30px;
}

/* Tabs container -- whole content */
.r-tabs {
    position: relative;
}

/* Tab element -- the link container */
.r-tabs .r-tabs-nav .r-tabs-tab {
    position: relative;
    margin: 0;
    padding: 0;
    margin: {
        left: -5px;
        bottom: -5px;
    }
}

/* Tab anchor -- actual link itself */
.r-tabs .r-tabs-nav .r-tabs-anchor {
    display: inline-block;
    float: left;
    padding: 10px 15px;
    text-decoration: none;
    font-size: 16px;
    font-weight: $bold;
    color: $text-black;
    border-right: 1px solid $red;
    border-top: 1px solid $red;
    border-left: 1px solid $red;
    // border-top-right-radius: 15px;
    // border-top-left-radius: 15px;

    p {
      font-size: 16px;
      font-weight: $bold;
      color: $text-black;
    }

}

/* Active state tab anchor */
.r-tabs .r-tabs-nav .r-tabs-state-active .r-tabs-anchor {
    color: #fff;
    text-shadow: none;
    background-color: $red;

    p {
      color: #fff;
    }

}

/* Tab panel -- tab content */
.r-tabs .r-tabs-panel .border {
    border-top: 1px solid $red;
    border-right: 1px solid $red;
    border-left: 1px solid $red;
    border-bottom: 1px solid $red;
    padding: {
        top: 25px;
        right: 30px;
        bottom: 15px;
        left: 30px;
    }
}

/* Accordion anchor */
.r-tabs .r-tabs-accordion-title .r-tabs-anchor {
    display: block;
    padding: 10px;
    color: $text-black;
    font-weight: bold;
    text-decoration: none;
}

/* Active accordion anchor */
.r-tabs .r-tabs-accordion-title.r-tabs-state-active .r-tabs-anchor {
    background-color: $red;
    color: #fff;
    text-shadow: none;
}

.r-tabs .r-tabs-nav {
    margin: 0;
    padding: 0;
    padding-left: 6px;
}

.r-tabs .r-tabs-tab {
    display: inline-block;
    margin: 0;
    list-style: none;
}

.r-tabs .r-tabs-panel {
    // padding: {
    //     top: 25px;
    //     right: 30px;
    //     bottom: 15px;
    //     left: 30px;
    // }
    display: none;
}

.r-tabs .r-tabs-accordion-title {
    display: none;
}

.r-tabs .r-tabs-panel.r-tabs-state-active {
    display: block;
}

.-helplines {
    /* Accordion responsive breakpoint */
    @media only screen and (max-width: 1018px) {
        .r-tabs {
            border-top: 1px solid $red;
            border-right: 1px solid $red;
            border-left: 1px solid $red;
            border-bottom: 1px solid $red;
            // border-radius: 15px;
        }

        .r-tabs .r-tabs-panel {
            border: 0;
        }

        .r-tabs .r-tabs-nav {
            display: none;
        }

        .r-tabs .r-tabs-accordion-title {
            display: block;
            border-top: $red solid 1px;

            &.r-tabs-state-active {
                border-top: 0;
            }
        }

        .r-tabs .r-tabs-accordion-title:nth-of-type(1) {
            border-top: 0;
        }
    }
}

.-policyholders {
    /* Accordion responsive breakpoint */
    @media only screen and (max-width: 512px) {
        .r-tabs {
            border-top: 1px solid $red;
            border-right: 1px solid $red;
            border-left: 1px solid $red;
            border-bottom: 1px solid $red;
            // border-radius: 15px;
        }

        .r-tabs .r-tabs-panel {
            border: 0;
        }

        .r-tabs .r-tabs-nav {
            display: none;
        }

        .r-tabs .r-tabs-accordion-title {
            display: block;
            border-top: $red solid 1px;

            &.r-tabs-state-active {
                border-top: 0;
            }
        }

        .r-tabs .r-tabs-accordion-title:nth-of-type(1) {
            border-top: 0;
        }
    }
}

.-complaints {
    /* Accordion responsive breakpoint */
    @media only screen and (max-width: 720px) {
        &.r-tabs {
            border-top: 1px solid $red;
            border-right: 1px solid $red;
            border-left: 1px solid $red;
            border-bottom: 1px solid $red;
            // border-radius: 15px;
        }

        &.r-tabs .r-tabs-panel {
            border: 0;
        }

        &.r-tabs .r-tabs-nav {
            display: none;
        }

        &.r-tabs .r-tabs-accordion-title {
            display: block;
            border-top: $red solid 1px;

            &.r-tabs-state-active {
                border-top: 0;
            }
        }

        &.r-tabs .r-tabs-accordion-title:nth-of-type(1) {
            border-top: 0;
        }
    }
}

.r-tabs-accordion-title {
  .r-tabs-anchor {
    p {
      font-size: 16px;
      color: black;
      font-weight: 700;
    }
  }

  &.r-tabs-state-active {
    .r-tabs-anchor {
      p {
        color: white;
      }
    }
  }

}
