.controls {
    @include clearfix;
    margin-top: 20px;
    background-color: $grey;
    padding: {
        top: 10px;
        right: 10px;
        bottom: 1px;
        left: 10px;
    }
}

.button  {
    display: block;
    position: relative;
    cursor: pointer;
    margin: {
        right: 34px;
        bottom: 10px;
    }
    float: left;
    border: none;
    background: none;
    outline: none;

    &:before {
        width: 12px;
        height: 12px;
        content: '';
        display: block;
        border: 1px solid #000;
        float: left;
        margin: {
            right: 5px;
            top: 4px;
        }
        outline: none;
    }

    &.is-checked {
        &:after {
            width: 8px;
            height: 8px;
            content: '';
            display: block;
            background-color: #000;
            float: left;
            margin: {
                left: -16px;
                right: 5px;
                top: 7px;
            }
            outline: none;
        }
    }
}

.back-top {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: $red;
    padding: 10px;
    color: #fff;
    text-decoration: none;
    font-weight: $bold;
}

.-question {
    a {
        color: $red;
        font-weight: bold;
        text-decoration: none;
    }
}