.award--accordions {
  padding: 60px 0;

  @media screen and (max-width: 720px) {
    padding: 40px 0;
  }

  .award--years {

    .accordion {
      margin-top: 0;
      margin-bottom: 20px;

      &:last-child { margin-bottom: 0 }

      .accord-title {
        border-radius: 0;
        position: relative;
        background-color: $light-blue;
        z-index: 1000;

        &.open {
          background-color: $light-blue;
          .x {
            transform: translateY(-50%) rotate(90deg);

            #Line_62 {
              display: none;
            }
          }
        }

        .icon {
          display: inline-block;
          @include valign;
          position: absolute;
          left: 10px;
          width: 30px;
          height: 30px;

          img {
            display: block;
            width: auto;
            height: 100%;
            float: none;
            padding: 0;
            margin: 0;
          }

        }

        h2 {
          @include font(22px, black, $bold);
          margin: 0;
        }

        .x {
          @include valign;
          position: absolute;
          right: 15px;
          width: 18px;
          height: 18px;
          @include animate;
          transform-origin: 9px 9px;
        }

      }

      .accord-content {
        padding: 0;
        border: 1px solid $blue;
        border-radius: 0;
        margin-top: -1px;

        .padding {
          padding: 30px 30px 0 30px;
        }

        p {
          padding: 0;
          @include font(14px, black, $regular);
          padding-bottom: 20px;

          a {
            color: $red;
            text-decoration: underline;
          }

          strong { font-weight: $bold }
          &:last-child { padding-bottom: 0 }

        }

        .award--flex {
          @include flex;
          flex-wrap: wrap;

          .col {
            display: inline-block;
            width: calc(50% - 40px);
            margin-bottom: 40px;

            @media screen and (max-width: 700px) {
              width: 100%;
              margin-right: 0 !important;
            }

            &:nth-child(odd) {
              margin-right: 40px;
            }

            h3 {
              @include font(28px, black, $semibold);
              margin: 0 0 20px 0;
            }

            .img--video {
              background-image: url('../img/index/col.png');
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;
              width: 100%;
              min-height: 200px;
              position: relative;

              @media screen and (max-width: 500px) {
                min-height: 150px;
              }

              a {
                display: block;
                width: 100%;
                height: 100%;
                min-height: 100%;
                min-width: 100%;
                position: absolute;
                background-image: url('../img/global/icon--play-white.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: 75px 75px;
              }

            }

            .copy {
              padding-top: 20px;

              p {
                @include font(14px, black, $regular);
                padding-bottom: 20px;

                &:last-child { padding-bottom: 0 }
                strong { font-weight: $bold }

                a {
                  color: $red;
                  text-decoration: underline;
                }

              }

              .btn {
                color: $red;
                background-color: rgba(0,0,0,0);
                padding-left: 0;
                background-image: url('../img/global/arrow--red.svg');
              }

            }

          }

        }

      }

    }

  }

}

.award--widgets {
  background-color: $light-blue;
  padding: 60px 0 40px 0;

  @media screen and (max-width: 720px) {
    padding: 40px 0 20px 0;
  }

  .flex {
    @include flex;
    flex-wrap: wrap;
    align-items: flex-start;

    .aside-custom {
      display: inline-block;
      width: calc(100% / 3 - 14px);
      background-color: white;
      border: 0;
      margin-right: 20px;
      border-radius: 0;

      @media screen and (max-width: 700px) {
        width: calc(50% - 14px);

        &:nth-child(even) { margin-right: 0 !important }

      }

      @media screen and (max-width: 500px) {
        width: 100%;
        margin-right: 0 !important;
      }

      &:nth-child(3) {
        margin-right: 0;
      }

      a {
        text-decoration: none;
        @include font(18px, black, $regular);
      }

      .aside-header {
        padding-left: 70px;
        position: relative;
        img {
          display: block;
          position: absolute;
          top: 20px;
          left: 20px;
          padding-right: 15px;
        }
      }

    }

  }

}

.awards {
    .award-year {
        margin-top: 20px;

        h3 {
            color: $red;
            font-size: 34px;
        }

        p {
            margin: {
                top: 10px;
                bottom: 15px;
            }

            a {
                color: $red;
                font-weight: $bold;
            }
        }
    }

    .videos {
        @include clearfix;

        .video {
            @include clearfix;

            margin-bottom: 10px;
            float: left;
            margin-right: 25px;

            &:nth-child(2n+2) {
                margin-right: 0;
            }

            .screenshot {
                float: left;
                width: 191px;
                position: relative;
                margin-right: 20px;

                @media screen and (max-width: 1058px) {
                    width: 20%;
                    margin-left: 0;

                    img {
                        width: 100%;
                    }
                }

                @media screen and (max-width: 730px) {
                    width: 100%;
                    max-width: 191px;
                    left: 50%;
                    transform: translateX(-50%);
                }

                span {
                    width: 75px;
                    height: 75px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    background-image: url('../img/svg/svg--icon-play.svg');
                    transform: translate(-50%, -50%);
                }
            }

            .video-text {
                float: left;
                width: 286px;
                padding-right: 15px;
                box-sizing: border-box;

                @media screen and (max-width: 1058px) {
                    width: 77%;
                    margin-left: 0;
                }

                @media screen and (max-width: 730px) {
                    width: 100%;
                    margin-left: 0;
                }

                .title {
                    font-size: 16px;
                    font-weight: $bold;
                }
            }
        }

        &.-current-year {
            .video {
                float: none;
                margin-right: 0;

                .screenshot {
                    width: 363px;

                    @media screen and (max-width: 1058px) {
                        width: 363px;
                    }

                    @media screen and (max-width: 765px) {
                        width: 50%;

                        img {
                            width: 100%;
                        }
                    }

                    @media screen and (max-width: 730px) {
                        width: 100%;
                        max-width: 100%;
                        left: auto;
                        transform: translateX(0%);
                    }
                }

                .video-text {
                    width: 300px;
                    margin-left: 40px;
                    padding-right: 0;

                    @media screen and (max-width: 765px) {
                        width: 45%;
                        margin-left: 2%;
                    }

                    @media screen and (max-width: 765px) {
                        width: 100%;
                        margin-left: 0;
                    }
                }

            }
        }
    }

    .benefit-split-line {
        margin: {
            top: 30px;
            bottom: 30px;
        }
        padding: 0;
    }
}

// lightbox

.lity {
  z-index: 9990;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  background: #0b0b0b;
  background: rgba(0, 0, 0, 0.9);
  outline: none !important;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
       -o-transition: opacity 0.3s ease;
          transition: opacity 0.3s ease;
}
.lity.lity-opened {
  opacity: 1;
}
.lity.lity-closed {
  opacity: 0;
}
.lity * {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
.lity-wrap {
  z-index: 9990;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  outline: none !important;
}
.lity-wrap:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.25em;
}
.lity-loader {
  z-index: 9991;
  color: #fff;
  position: absolute;
  top: 50%;
  margin-top: -0.8em;
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
       -o-transition: opacity 0.3s ease;
          transition: opacity 0.3s ease;
}
.lity-loading .lity-loader {
  opacity: 1;
}
.lity-container {
  z-index: 9992;
  position: relative;
  text-align: left;
  vertical-align: middle;
  display: inline-block;
  white-space: normal;
  max-width: 100%;
  max-height: 100%;
  outline: none !important;
}
.lity-content {
  z-index: 9993;
  width: 100%;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
       -o-transform: scale(1);
          transform: scale(1);
  -webkit-transition: -webkit-transform 0.3s ease;
       -o-transition: -o-transform 0.3s ease;
          transition: transform 0.3s ease;
}
.lity-loading .lity-content,
.lity-closed .lity-content {
  -webkit-transform: scale(0.8);
      -ms-transform: scale(0.8);
       -o-transform: scale(0.8);
          transform: scale(0.8);
}
.lity-content:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}
.lity-close {
  z-index: 9994;
  width: 35px;
  height: 35px;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-appearance: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  padding: 0;
  color: #fff;
  font-style: normal;
  font-size: 35px;
  font-family: Arial, Baskerville, monospace;
  line-height: 35px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  border: 0;
  background: none;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.lity-close::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.lity-close:hover,
.lity-close:focus,
.lity-close:active,
.lity-close:visited {
  text-decoration: none;
  text-align: center;
  padding: 0;
  color: #fff;
  font-style: normal;
  font-size: 35px;
  font-family: Arial, Baskerville, monospace;
  line-height: 35px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  border: 0;
  background: none;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.lity-close:active {
  top: 1px;
}
/* Image */
.lity-image img {
  max-width: 100%;
  display: block;
  line-height: 0;
  border: 0;
}
/* iFrame */
.lity-iframe .lity-container {
  width: 100%;
  max-width: 964px;
}
.lity-iframe-container {
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  overflow: auto;
  pointer-events: auto;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-overflow-scrolling: touch;
}
.lity-iframe-container iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}
.lity-hide {
  display: none;
}
