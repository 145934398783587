.document-list {
	margin-top: 20px;
	font-size: 14px;

	li {
		@include clearfix;

		padding: 8px 10px;

		&:not(:first-child) {
			background-color: #eee;
			margin-bottom: 10px;
		}
	}

	&--header {
		font-family: $opensans;
		font-weight: $bold;
	}

	.document-name {
		padding-top: 2px;
		float: left;

		@media screen and (max-width: 1045px) {
			max-width: 80%;
		}

		span {
			color: $red;
			font-weight: $bold;
			margin-right: 10px;
			text-decoration: underline;
			cursor: pointer;
		}
	}

	.document-amount {
		width: 65px;
		float: right;
		text-align: center;

		.amount {
			text-align: center;
			width: 30px;
			height: 25px;
			outline: none;
			border: 0;
		}
	}
}

.document-list-sent {
	li {
		padding: 5px 0;

		&:first-child {
			margin-top: 20px;
			margin-bottom: 5px;
		}

		&:not(:first-child) {
			background-color: #fff;
			margin-bottom: 5px;
		}
	}

	.document-name {
		a {
			color: #000;
			font-weight: $bold;
			margin-right: 10px;
			text-decoration: underline;
			cursor: default;

			span {
				color: #000;
				font-weight: $bold;
				margin-right: 10px;
				text-decoration: none;
				cursor: default;
			}

		}
	}

	.document-amount {
		width: 65px;
		float: right;
		text-align: center;

		.amount {
			text-align: center;
			width: 30px;
			height: 25px;
			outline: none;
			border: 0;
		}
	}
}

.postal-address {
	@include clearfix;

	margin-top: 20px;

	&-columns {
		@include clearfix;
	}

	&--column {
		margin-top: 20px;
		float: left;

		&:nth-of-type(1) {
			margin-right: 117px;

			@media screen and (max-width: 1059px) {
				margin-right: 40px;
			}

			@media screen and (max-width: 982px) {
				margin-right: 0px;
			}
		}

		@media screen and (max-width: 982px) {
			float: none;
		}
	}

	&--field {
		margin-bottom: 10px;

		.label {
			width: 125px;
			display: inline-block;
			font-size: 13px;
			font-family: $opensans;

			@media screen and (max-width: 982px) {
				width: 100%;
			}
		}

		.input {
			width: 320px;
			height: 30px;
			border: 1px solid #000;
			padding: {
				left: 5px;
				right: 5px;
			}
			font-size: 13px;
			outline: none;
			box-sizing: border-box;

			@media screen and (max-width: 982px) {
				width: 100%;
			}

			// &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			// 	color: #fff;
			// }
			//
			// &::-moz-placeholder { /* Firefox 19+ */
			// 	color: #fff;
			// }
			//
			// &:-ms-input-placeholder { /* IE 10+ */
			// 	color: #fff;
			// }
			//
			// &:-moz-placeholder { /* Firefox 18- */
			// 	color: #fff;
			// }
		}
	}

	.submit {
	    background-color: $red;
	    background-image: url('../img/svg/svg--arrow-white.svg');
	    background-repeat: no-repeat;
	    background-size: 10px 15px;
	    background-position: 90% center;
	    border: none;
	    padding: {
	        top: 10px;
	        right: 40px;
	        bottom: 10px;
	        left: 30px;
	    }
	    color: #fff !important;
	    font-weight: $bold;
	    // border-radius: 15px;
	    margin-top: 40px !important;
	    outline: none;
	    // position: relative;
	    // left: 50%;
	    // transform: translateX(-50%);
	}
}

.postal-address-sent {
	h3 {
		font-size: 14px;
		margin-bottom: 20px;
	}

	.label {
		display: none;
	}

	.submit {
		display: none;
	}

	.postal-address--column {
		margin-top: 0px;
		float: none;
	}

	.input {
		width: 100%;
		height: auto;
		border: 0;
		padding: {
			left: 5px;
			right: 5px;
		}

		&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			color: #A9ACB5;
		}

		&::-moz-placeholder { /* Firefox 19+ */
			color: #A9ACB5;
		}

		&:-ms-input-placeholder { /* IE 10+ */
			color: #A9ACB5;
		}

		&:-moz-placeholder { /* Firefox 18- */
			color: #A9ACB5;
		}
	}
}

.profile {

	.form {
		clear: both;
	}

	p {
		font-size: 13px;

		&.spacing-fix {
			padding-top: 20px;
			margin-bottom: 20px;
		}

		&.last {
			margin-left: 20px;
			margin-top: 20px;
			a {
				color: #000;
			}
		}
	}

	.postal-address-columns {
		padding: 20px 20px 0;

		&.-pink {
			background-color: #fef0f0;
			// border-radius: 15px;
			margin-top: 20px;
		}
	}

	.postal-address--column {
		margin-top: 20px;
		float: left;

		&:nth-of-type(1) {
			margin-right: 31px;

			@media screen and (max-width: 1059px) {
				margin-right: 0px;
			}
		}

		@media screen and (max-width: 1059px) {
			float: none;
		}
	}

	.postal-address--field {

		.label {
			width: 170px;

			&.long-fix {
				transform: translateY(-4px);

				@media screen and (max-width: 1059px) {
					transform: translateY(0px);
				}
			}

			@media screen and (max-width: 1059px) {
				width: 100%;
			}
		}

		.input {
			vertical-align: top;
			width: 300px;

			&.long-fix {
				margin-top: -7px;

				@media screen and (max-width: 1059px) {
					margin-top: 0;
				}
			}

			&.disabled {
				background-color: #e9e9e9;
			}

			@media screen and (max-width: 1059px) {
				width: 100%;
			}
		}

		span {
			@media screen and (max-width: 1059px) {
				display: none;
			}
		}
	}

	.submit {
		position: static;
		transform: translateX(0);
		margin-top: 10px;
		margin-left: 20px;
	}
}

.post-send {
	display: none;

	h3 {
		margin-top: 30px;
	}
}
