.big--form.claims--form {

  .form-halves {

     .form-half {

       .input {
         @media screen and (max-width: 1000px) {
           width: 100%;
         }
       }

     }

  }

  .claims--form-intro {

    h2 {
      font-size: 26px;
      font-weight: 700;
      margin-bottom: 15px;
    }

    .buttons {
      margin: 20px 0 30px 0;

      a {
        // @include flex;
        display: inline-block;
        align-items: center;
        text-align: left;
        width: auto;
        font-size: 14px;
        padding: 10px 10px 10px 40px;
        flex: 0 1 auto;
        position: relative;
        color: white;
        font-size: 14px;
        font-weight: 700;
        text-decoration: none;
        border-radius: 4px;

        @media screen and (max-width: 380px) {
          width: 100% !important;
          max-width: 100% !important;
          flex: auto;
          margin: 0 0 15px 0 !important;

          &:last-child { margin-bottom: 0 }

        }

        &:nth-child(1) {
          margin-right: 15px;
        }

        span {
          width: 100%;
          max-width: 20px;
          margin-right: 10px;
          @include valign;
          position: absolute;
          left: 10px;
          display: inline-block;

          img {
            display: block;
            width: 100%;
            height: auto;
          }

        }

        &.blue { background-color: $blue }
        &.red { background-color: $red }
        &.white {
          background-color: white;
          border: 1px solid #A2A2A2;
          color: $new-grey;
        }

      }

    }

  }

  .claims--form-prior {
    padding-bottom: 15px;

    h3 {
      margin-bottom: 15px;
    }

    p {
      padding-bottom: 15px;
    }

    ul, ol {
      padding-left: 20px;
    }

    ul {
      list-style: disc;
      padding-top: 15px;
    }

    li {
      padding-bottom: 12px;
    }

  }

  .checkbox-left {
    @include flex;

    @media screen and (max-width: 991px) {
      flex-wrap: wrap;
    }

    input, textarea {
      float: none;
    }

    .input {
      float: none;
      width: 89%;

      @media screen and (max-width: 991px) {
        width: 100%;
      }

    }

    .cb {
      display: inline-block;
      width: 11%;

      @media screen and (max-width: 991px) {
        width: 100%;
        margin-bottom: 10px;
      }

    }

  }

  .form-full {

    h3 {
      font-size: 18px;
      margin-bottom: 15px;
    }

    p {
      padding: 0;
      font-size: 14px;
    }

    ul {
      list-style: disc;
      padding-left: 20px;
      margin-top: 15px;

      li {
        padding-bottom: 5px;
        font-size: 14px;
      }

    }

    &.loss--damages {

      .form-item {

        .item-text {
          max-width: 240px;
          width: 100%;
        }

        .input {
          width: 69.76744186046511%;

          @media screen and (max-width: 991px) {
            width: 100%;
          }

        }

      }

    }

    &.add--info {

      .form-item {

        .item-text {
          width: 45%;
          padding-right: 20px;

          @media screen and (max-width: 991px) {
            width: 100%;
            padding-right: 0;
            padding-bottom: 10px;
          }

        }

        .input {
          width: 50%;

          @media screen and (max-width: 991px) {
            width: 100%;
          }

        }

      }

    }

    table {

      th,td {
        font-size: 13px;
        font-weight: normal;
        border:1px solid black;

        input {
          border:none;
          width: 100%;
        }

      }

      th {
        padding:8px;
        background-color: $grey;

        &:nth-child(1) {
          width: 25%;
        }

        &:nth-child(2) {
          width: 8%;
        }

        &:nth-child(3) {
          width: 18%;
        }

        &:nth-child(4) {
          width: 18%;
        }

        &:nth-child(5) {
          width: 18%;
        }

        &:nth-child(6) {
          width: 13%;
        }

      }

    }

  }

}
