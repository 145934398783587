.header--nav {
  margin-right: 40px;

  @media screen and (max-width: 1350px) {
    margin-right: 20px;
  }

  @media screen and (max-width: 1030px) {
    margin-right: 0;
  }

  .nav {
    width: 100%;
    margin: 0 auto;

    @media screen and (max-width: 1030px) {
      display: none;
      background-color: white;
    }

    ul {
      @include flex;
      position: relative;

      @media screen and (max-width: 1030px) {
        display: block;
        z-index: 2000;
      }

      li {
        display: inline-block;
        border-right: 1px solid #D5D5D5;
        padding: 0 32px 0 15px;
        max-width: 130px;
        cursor: pointer;

        &:last-child, &.contact { border-right: 0 }

        &.about, &.broker {
          a {
            position: static;
            top: 0;
            transform: translateY(0);
          }
        }

        &.news-mob {
          display: none;
          background-color: $light-blue;
          color: $blue;

          @media screen and (max-width: 1030px) {
            display: block;
          }

        }

        &.spow-mob {
          display: none;
          background-color: $spow;
          color: white;

          a {
            color: white;
          }

          @media screen and (max-width: 1030px) {
            display: block;
          }

        }

        @media screen and (max-width: 1030px) {
          display: block;
          width: 100%;
          padding: 0;
          max-width: 100%;
          text-align: center;
          border-bottom: 1px solid $new-grey;
          position: relative;
        }

        a {
          display: block;
          text-decoration: none;
          @include font(14px, black, $semibold);
          @include valign;
          padding: 10px 0;

          &:after {
            display: inline-block;
            content: '';
            background-image: url('../img/global/nav--arrow.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 10px 5px;
            width: 10px;
            height: 5px;
            @include valign;
            position: absolute;
            right: -19px;

            @media screen and (max-width: 1030px) {
              display: none;
            }

          }

          @media screen and (max-width: 1030px) {
            padding: 14px 0;
            position: static;
            transform: translateY(0);
          }

        }

        @media screen and (min-width: 1031px) {
          &:hover {
            background-color: $light-blue;

            ul {
              display: block;
            }

          }
        }

        input[type=checkbox] {
          /* hide native checkbox */
          position: absolute;
          opacity: 0;
          display: none;

          @media screen and (max-width: 1030px) {
            display: block;
          }

        }

        input[type=checkbox]:checked + label + ul,
      	input[type=checkbox]:checked + label:nth-of-type(n) + ul {
      		display: block;
      	}

        label {
          display: none;
          width: 49px;
          height: 49px;
          position: absolute;
          right: 0;
          top: 0;
          padding: 0;
          margin: 0;
          background-color: rgba(0,0,0,0.15);
          z-index: 1000;

          &:after {
            display: block;
            content: '';
            background-image: url('../img/global/arrow--black.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 12px 18px;
            width: 12px;
            height: 18px;
            position: absolute;
            @include center;
            top: 50%;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            @include animate;
            cursor: pointer;
          }

          @media screen and (max-width: 1030px) {
            display: inline-block;
          }

          &.active {
            &:after {
              transform: translateY(-50%) rotate(90deg);
              -webkit-transform: translateY(-50%) rotate(90deg);
              -ms-transform: translateY(-50%) rotate(90deg);
            }
          }

        }

        ul {
          display: none;
          background-color: $light-blue;
          position: absolute;
          top: 58px;
          left: 0;
          width: calc(100% - 60px);
          padding: 30px 20px 15px 20px;
          z-index: 2000;

          @media screen and (max-width: 1030px) {
            position: static;
            width: 100%;
            padding: 0;
          }

          li {
            display: inline-block;
            border: none;
            max-width: 100%;
            flex: none;
            padding: 0 10px;
            margin-bottom: 15px;
            position: relative;

            @media screen and (max-width: 1030px) {
              margin-bottom: 0;
              padding: 0;
              border-bottom: 1px solid $blue;

              &:last-child { border: none }

            }

            .icon {
              display: inline-block;
              width: 100%;
              max-width: 25px;
              @include valign;
              position: absolute;
              left: 10px;
              margin-right: 30px;

              @media screen and (max-width: 1030px) {
                margin-right: 0;
              }

              img {
                display: block;
                width: 100%;
                height: auto;
              }

            }

            a {
              font-weight: $semibold;
              font-size: 16px;
              top: 0;
              transform: translateY(0);
              padding: 0;

              @media screen and (max-width: 1030px) {
                padding: 14px 0 !important;
                font-size: 14px;
              }

              &:after { display: none }

            }

          }

          &.products--nav {
            padding: 30px 30px 0 30px;
            width: 120%;
            left: -10%;

            @media screen and (max-width: 1235px) {
              left: -24%;
            }

            @media screen and (max-width: 1030px) {
              width: 100%;
              padding: 0;
              left: 0;
            }

            .products--nav-flex {
              @include flex;
              flex-wrap: wrap;

              .col {
                display: inline-block;
                width: calc(100% / 3 - 28px);
                margin-right: 20px;
                border-right: 1px solid #8CD3F0;
                padding-right: 20px;
                margin-bottom: 30px;

                &:nth-child(3) {
                  margin-right: 0;
                  border-right: 0;
                  padding-right: 0;
                }

                @media screen and (max-width: 1030px) {
                  width: 100%;
                  margin: 0 !important;
                  border-right: 0 !important;
                  border-bottom: 1px solid #8CD3F0;
                  padding: 20px 0 !important;
                  position: relative;
                }

                .main {
                  margin-bottom: 0 !important;
                }

                h4, .main {
                  @include font(16px, black, $semibold);
                  margin: 0 0 15px 0;
                  position: relative;
                  padding-left: 35px;

                  @media screen and (max-width: 1030px) {
                    padding-left: 0 !important;
                  }

                  a {
                    padding: 0;
                    display: inline;
                    font-size: 16px;
                    font-weight: $semibold;
                  }

                  span {
                    display: inline-block;
                    @include valign;
                    top: calc(50% - 10px);
                    position: absolute;
                    left: 0;
                    width: 100%;
                    max-width: 25px;

                    @media screen and (max-width: 1030px) {
                      position: static;
                      transform: translateY(0);
                      padding-right: 5px;
                    }

                    img {
                      display: block;
                      width: 100%;
                      height: auto;
                    }

                  }



                }

                ul {
                  position: static;
                  padding: 0;
                  width: 100%;
                  background: none;

                  @media screen and (max-width: 1030px) {
                    display: block;
                  }

                  li {
                    display: block;
                    padding: 0 0 20px 0;
                    margin: 0;

                    @media screen and (max-width: 1030px) {
                      padding-bottom: 0;
                      border-bottom: 0;
                    }

                    &.download {
                      a {
                        position: relative;
                        &:after {
                          display: inline-block !important;
                          content: '';
                          background-image: url('../img/global/icon--download.svg');
                          background-repeat: no-repeat;
                          background-position: center;
                          background-size: 6px 10px;
                          width: 6px;
                          height: 10px;
                          right: auto;
                          margin-left: 8px;
                        }
                      }
                    }

                    &.disabled {
                      padding-right: 20px;
                      @media screen and (max-width: 1030px)  {
                        padding: 0;
                      }
                      a {
                        color: $new-grey;
                        @media screen and (max-width: 1030px) {
                          position: relative;
                        }
                        &:after {
                          display: inline-block !important;
                          content: '';
                          background-image: url('../img/global/nav--lock.svg');
                          background-repeat: no-repeat;
                          background-position: center;
                          background-size: 11px 14px;
                          width: 11px;
                          height: 14px;
                          right: 0;
                          margin-left: 8px;
                          top: 33%;

                          @media screen and (max-width: 1030px) {
                            top: 50%;
                            right: -20px;
                            margin-left: 0;
                          }

                        }
                      }
                    }

                  }

                }

                a {
                  position: static;
                  transform: translateY(0);
                  font-size: 14px;
                  font-weight: $regular;

                  &:after {
                    display: none !important;
                  }

                }

                .nav--button {
                  display: inline-block;
                  background-color: white;
                  font-size: 14px;
                  color: black;
                  text-decoration: none;
                  text-align: center;
                  padding: 10px 25px 10px 15px !important;
                  background-image: url('../img/global/arrow--red.svg');
                  background-repeat: no-repeat;
                  background-size: 8px 12px;
                  background-position: right 10px center;
                  border-radius: 4px;
                  margin-top: 5px;
                  width: auto;
                }

                &.-others {

                  h4 {
                    &:before {
                      display: inline-block;
                      content: '';
                      background-repeat: no-repeat;
                      background-position: center;
                      @include valign;
                      position: absolute;
                      left: 0;

                      @media screen and (max-width: 1030px) {
                        left: auto !important;
                      }

                    }
                    a {
                      @media screen and (max-width: 1030px) {
                        padding: 14px 0;
                        font-size: 14px;
                        display: block;
                      }
                    }
                  }

                  .broker {
                    &:before {
                      background-image: url('../img/global/icon--find-broker.svg');
                      background-size: 20px 19px;
                      width: 20px;
                      height: 19px;
                      @media screen and (max-width: 1030px) {
                        margin-left: -75px;
                      }
                    }
                  }
                  .understand {
                    margin: 0;
                    &:before {
                      background-image: url('../img/global/icon--understanding.svg');
                      background-size: 15px 24px;
                      width: 15px;
                      height: 24px;
                      left: 3px;
                      @media screen and (max-width: 1030px) {
                        margin-left: -137px;
                      }
                    }
                  }

                  @media screen and (max-width: 1030px) {
                    padding: 0 !important;

                    h4 {
                      margin: 0 !important;
                    }

                  }

                }

              }

            }

            &.-main {
              .products--nav-flex {
                .col {
                  ul {
                    display: none;
                  }
                  h4 {
                    span {
                      top: 30%;
                    }
                  }
                }
              }
            }

          }

        }

        &.contact {
          padding-right: 15px;
          a {
            &:after {
              display: none;
            }
          }
        }

      }

    }

  }


}

@media all and (-ms-high-contrast:none) {
  .header--nav .nav ul li ul.products--nav .products--nav-flex .col h4 span {
    left:-35px;
  } /* IE10 */
  *::-ms-backdrop, .header--nav .nav ul li ul.products--nav .products--nav-flex .col h4 span {
    left:-35px;
  } /* IE11 */
}
