.accordions {
    height: auto !important;
    overflow: hidden;
}

    .accordion {
        margin-top: 20px;
        position: static !important;
        cursor: pointer;

        .accord-title {
            background-color: $grey;
            border: 1px solid $grey;
            padding: {
                top: 15px;
                right: 15px;
                bottom: 15px;
                left: 15px;
            }
            // border-radius: 15px;
            transition: all .4s ease;
            position: relative;

            img {
              float: left;
              padding-right: 12px;
              margin-top: -10px;
            }

            span {
                content: '';
                display: block;
                background-image: url('../img/svg/svg--arrow.svg');
                background-repeat: no-repeat;
                width: 30px;
                height: 20px;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                transition: all .4s ease;
            }

            &.open {
                background-color: $red;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;

                h3 {
                  color: #FFFFFF;
                }

                span {
                    transform: translate(-13px, 1px) rotate(90deg);
                    background-image: url('../img/svg/svg--arrow-white.svg');

                    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        transform: translate(-6px, -8px) rotate(90deg);
                    }
                }
            }
        }

        &.completed,
        &.completed.open {
            .accord-title {
                background-color: $red-dark;
                color: #fff;

                span {
                    background-image: url('../img/svg/svg--icon-tick-white.svg');
                    width: 28px;
                    height: 25px;
                    background-size: contain;
                    right: 16px;
                     transform: translate(5px, -10px) rotate(0deg);
                }
            }
        }

        .accord-content {
            max-height: 0;
            overflow: hidden;
            opacity: 0;
            border: 1px solid $grey;
            border-top: 0;
            padding-top: 8px;
            margin-top: -8px;
            // border-bottom-right-radius: 15px;
            // border-bottom-left-radius: 15px;

            transition: max-height .4s ease-in-out, opacity .4s ease-in-out;

            p {
                padding: {
                    top: 15px;
                    right: 15px;
                    bottom: 5px;
                    left: 15px;
                }

                &:last-child {
                    padding-bottom: 15px;
                }

                em {
                    color: $red;
                }
            }

            a {
                color: $text-black;
                text-decoration: none;
                font-weight: $bold;
            }

            &.open {
                max-height: 9999px;
                opacity: 1;

                &.-claim {
                    max-height: none;
                }
            }
        }
    }

.-glossary {
    .accord-title {
        h3 {
            font-size: 14px;
        }
    }
}

.cant-find {
    margin: {
        top: 30px;
        bottom: 30px;
    }
    padding: {
        top: 15px;
        right: 25px;
        bottom: 8px;
        left: 25px;
    }
    // border-radius: 15px;
    background-color: $light-blue;

    p {
        font-size: 18px;
        font-weight: $bold;
        margin-bottom: 10px;
    }

        a {
            color: $red;
        }
}

.glossary-filter {
    margin-top: 10px;

    a {
        width: 31px;
        margin-bottom: 5px;
        padding: {
            top: 5px;
            bottom: 5px;
        }
        text-align: center;
        text-decoration: none;
        color: $text-black;
        display: inline-block;
        font-size: 17px;
        font-weight: $bold;
        border: 2px solid $grey;
        // border-radius: 8px;
        background-color: $grey;

        &.disabled {
            background-color: $grey;
            border: 2px solid $grey;
            color: #ccc;
            cursor: default;
        }
    }

    &.-years {
        a {
            width: 60px;
        }
    }
}

.accordions {

  &.-claims {
      .accord-title {
        background-repeat: no-repeat;
        // padding-left: 70px;

        // &.-flood {
        //     background-image: url('../img/svg/svg--icon-flood.svg');
        //     background-size: 38px 35px;
        //     background-position: 10px center;
        // }
        //
        // &.-damage {
        //   background-image: url('../img/svg/svg--icon-hammer.svg');
        //   background-size: 47px 34px;
        //   background-position: 10px center;
        // }
        //
        // &.-pipe {
        //   background-image: url('../img/svg/svg--icon-pipe.svg');
        //   background-size: 49px 33px;
        //   background-position: 10px center;
        // }
        //
        // &.-water {
        //   background-image: url('../img/svg/svg--icon-water.svg');
        //   background-size: 33px 38px;
        //   background-position: 20px center;
        // }
        //
        // &.-fire {
        //   background-image: url('../img/svg/svg--icon-fire.svg');
        //   background-size: 42px 37px;
        //   background-position: 15px center;
        // }
        //
        // &.-flood {
        //   background-image: url('../img/svg/svg--icon-flood.svg');
        //   background-size: 38px 35px;
        //   background-position: 18px center;
        // }
        //
        // &.-crash {
        //   background-image: url('../img/svg/svg--icon-crash.svg');
        //   background-size: 33px 39px;
        //   background-position: 18px center;
        // }
        //
        // &.-rain {
        //   background-image: url('../img/svg/svg--icon-rain.svg');
        //   background-size: 35px 33px;
        //   background-position: 18px center;
        // }
        //
        // &.-theft {
        //   background-image: url('../img/svg/svg--icon-theft.svg');
        //   background-size: 28px 35px;
        //   background-position: 20px center;
        // }
        //
        // &.-legal {
        //   background-image: url('../img/svg/svg--icon-legal.svg');
        //   background-size: 42px 37px;
        //   background-position: 15px center;
        // }

        h3 {
            padding-right: 40px;

            @media screen and (max-width: 586px) {
                font-size: 17px;
            }
        }
      }

        .accord-content {

            ul {
                list-style: disc;
                margin-left: 20px;
                padding: {
                top: 15px;
                right: 15px;
                bottom: 5px;
                left: 15px;
            }

            li {
                font-family: $opensans;
                font-size: 13px;
                padding-bottom: 10px;
            }
        }
    }

}
}

.accordion-short {
    position: static !important;
    cursor: pointer;

    .accord-title {
        transition: all .4s ease;
        position: relative;
        padding-right: 20px;

        p {
            font-weight: $bold;
        }

        span {
            content: '';
            display: block;
            background-image: url('../img/svg/svg--arrow.svg');
            background-repeat: no-repeat;
            width: 10px;
            height: 10px;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            transition: all .4s ease;
        }

        &.open {

            span {
                transform: translate(-5px, -1px) rotate(90deg);

                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    transform: translate(-6px, -8px) rotate(90deg);
                }
            }
        }
    }

    .accord-content {
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        border-top: 0;
        padding-top: 8px;


        transition: max-height .4s ease-in-out, opacity .4s ease-in-out;

        p {
            padding: {
                bottom: 15px;
            }

            em {
                color: $red;
            }
        }

        a {
            color: $text-black;
            text-decoration: none;
            font-weight: $bold;
        }

        &.open {
            max-height: 860px;
            opacity: 1;
            border-bottom: 1px solid #000;
            margin-bottom: 15px;
        }
    }
}
