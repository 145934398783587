.header {
  border-bottom: 1px solid #D5D5D5;

  .blue--nav {
    @include clearfix;
    height: 52px;

    @media screen and (max-width: 1030px) {
      display: none;
    }

    .right--fix {
      background-color: $light-blue;
      padding: 0;
      // width: calc(50vw - 36px);
      min-width: 684px;
      width: 50%;
      @include flex;
      // text-align: right;
      // align-items: center;
      position: absolute;
      right: 0;

      .nav--spow {
        background-color: $spow;
        padding: 0;
        margin: 0;

        a {
          display: flex;
          align-items: center;
          text-decoration: none;
          @include font(14px, white, $semibold);
          height: 100%;
          padding: 0 18px;
          margin: 0;
          background-color: $spow;
          @include animate;

          &:hover {
            background-color: #0f8780;
          }

        }

      }

      .blue--nav__links {
        padding: 0;
        margin-left: 15px;
        display: inline-flex;
        align-items: center;

        a {
          text-decoration: none;
          @include font(14px, $dark-blue, $semibold);
          margin: 0;
        }

      }

      .blue--nav__social {
        margin: 0 40px;
        padding: 12px 0;

        a {
          display: inline-block;
          width: 24px;
          height: 24px;
          background-repeat: no-repeat;
          background-size: 24px 24px;
          background-position: center;

          &.tw {
            background-image: url('../img/global/social--tw.svg');
          }

          &.fb {
            background-image: url('../img/global/social--fb.svg');
          }

          &.yt {
            background-image: url('../img/global/social--yt.svg');
          }

        }

      }

      .blue--nav__search {
        padding: 12px 0;
        display: inline-flex;
        align-items: center;

        .search {

          input[type="search"] {
            display: inline-block;
            background-color: rgba(0,0,0,0);
            border: none;
            font-family: $opensans;
            font-weight: $semibold;
            font-size: 14px;
            color: $dark-blue;
            width: 100%;
            max-width: 120px;

            &:focus { outline:none }

          }

          input[type="submit"] {
            display: inline-block;
            background-image: url('../img/global/icon--search.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 20px 19px;
            width: 20px;
            height: 19px;
            background-color: rgba(0,0,0,0);
            border:0;
            appearance: none;
          }

        }

      }

    }

  }

  .header--flex {
    @include flex;
    padding: 30px 20px;
    margin: 0 auto;
    box-sizing: border-box;
    width: calc(100% - 40px);
    max-width: 1440px;
    align-items: flex-end;
    justify-content: center;

    @media screen and (max-width: 1030px) {
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
    }

    .header--logo {
      width: 100%;
      max-width: 284px;
      height: 73px;
      position: relative;

      @media screen and (max-width: 1315px) {
        max-width: 220px;
      }

      @media screen and (max-width: 1235px) {
        margin-right: 20px;
      }

      @media screen and (max-width: 1030px) {
        width: 100%;
        flex: 1;
        height: auto;
      }

      .logo {
        display: block;
        background-image: url('../img/svg/svg--logo.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 244px 73px;
        width: 244px;
        height: 73px;
        position: absolute;
        top: -16px;
        left: -5px;

        @media screen and (max-width: 1315px) {
          width: 200px;
          height: 62px;
          background-size: 200px 62px;
          top: -7px;
        }

        @media screen and (max-width: 1030px) {
          position: static;
        }

        @media screen and (max-width: 375px) {
          width: 159px;
          height: 48px;
          background-size: 159px 48px;
        }

      }

    }

    .header--right-mob {
      position: relative;
      float: right;

      .mob--search {
        display: none;

        @media screen and (max-width: 1030px) {
          display: inline-block;
          background-image: url('../img/global/icon--search.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 18px 17px;
          width: 35px;
          height: 35px;
          background-color: $light-blue;
          border: 4px solid $light-blue;
          z-index: 100;
          margin-right: 10px;
        }

      }

      .burger {
        display: none;

        @media screen and (max-width: 1030px) {
          display: inline-block;
          cursor: pointer;
          width: 43px;
          height: 43px;
        }

        .burger-icon {
          display: none;

          @media screen and (max-width: 1030px) {
            display: block;
            position: relative;
            max-width: 35px;
            max-height: 35px;
            width: calc(35px - 8px);
            height: calc(35px - 8px);
            @include center;
            padding: 8px;
            background-color: $red;

            path {
              fill: white;
              transition: all 250ms ease-in-out;
              transform: rotate(0deg);
              -webkit-transform: rotate(0deg);
              -ms-transform: rotate(0deg);
              transform-origin: 50% 50%;
              -webkit-transform-origin: 50% 50%;
              -ms-transform-origin: 50% 50%;
              will-change: transform, opacity;

              &:nth-child(3) {
                transform-origin: 13% 71%;
                -webkit-transform-origin: 13% 71%;
                -ms-transform-origin: 13% 71%;
              }

            }

            &.is-active {
              path {
                &:nth-child(1), &:nth-child(4) {
                  opacity: 0;
                }
                &:nth-child(2) {
                  transform: rotate(45deg);
                  -webkit-transform: rotate(45deg);
                  -ms-transform: rotate(45deg);
                }
                &:nth-child(3) {
                  transform: rotate(-45deg);
                  -webkit-transform: rotate(-45deg);
                  -ms-transform: rotate(-45deg);
                }
              }
            }
          }

        }

      }

    }

    .header--nav {
      // SEE NAV.SCSS

      @media screen and (max-width: 1030px) {
        position: absolute;
        @include center;
        top: 103px;
        z-index: 2000;
      }

      .nav--search {
        display: none;

        @media screen and (max-width: 1030px) {
          display: none;
          position: relative;
          @include center;
          z-index: 1000;
          background-color: $red;
          padding: 10px 20px;

          &.show {
            display: block;
          }

          input[type="text"], input[type="search"] {
            width: calc(100% - 42px) !important;
            display: inline-block;
            background-color: white;
            padding: 8px 30px 8px 12px;
            @include font(15px, black, $semibold);
            position: relative;
            border-radius: 0 !important;
            outline: none;
            box-shadow: none;
            @include animate;
            vertical-align: middle !important;
            -webkit-appearance: textfield;
            border-radius: 0 !important;
            border:none;
          }

          input[type="submit"] {
            display: inline-block;
            background-image: url('../img/global/icon--search.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 15px 14px;
            width: 15px;
            height: 15px;
            outline: none;
            background-color: rgba(0,0,0,0) !important;
            margin: 0 !important;
            padding: 0 !important;
            position: absolute;
            top: 19px;
            right: 30px;
            z-index: 1000;
            border:none;
            appearance: none !important;
            box-shadow: none !important;
          }

        }

      }

    }

    .header--info {
      text-align: right;

      @media screen and (max-width: 1235px) {
        display: none;
      }

      h3 {
        @include font(20px, black, $semibold);
        margin: 0;

        a {
          color: $red;
          text-decoration: none;
        }

      }

      p {
        @include font(12px, black, $regular);
      }

      .email {
        display: block;
        @include font(16px, black, $semibold);
        text-decoration: none;
        position: relative;
        padding-right: 20px;
        margin-top: 5px;

        &:after {
          display: inline-block;
          content: '';
          background-image: url('../img/global/icon--email.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 14px 10px;
          width: 14px;
          height: 10px;
          @include valign;
          position: absolute;
          right: 0;
        }

      }

    }

  }

}
