.product--cols {
  padding: 60px 0 20px 0;

  @media screen and (max-width: 720px) {
    padding: 40px 0 20px 0;
  }

  h2 {
    @include font(36px, black, $regular);
    margin: 0;
    text-align: center;
  }

  .flex {
    @include flex;
    flex-wrap: wrap;
    margin-top: 60px;

    @media screen and (max-width: 720px) {
      margin-top: 40px;
    }

    .col {
      display: inline-block;
      // flex-direction: column;
      // background-color: pink;
      width: calc(100% / 3 - 14px);
      margin: 0 20px 60px 0;
      position: relative;
      background-color: white;

      &:first-child:nth-last-child(n+2),
      &:first-child:nth-last-child(n+2) ~ .col {
        width: calc(50% - 10px);
        &:nth-child(2) {
          margin-right: 0;
        }
      }

      &:first-child:nth-last-child(n+3),
      &:first-child:nth-last-child(n+3) ~ .col {
        width: calc(100% / 3 - 14px);
        margin-right: 20px;
        &:nth-child(2) { margin-right: 20px }
        &:nth-child(3) {
          margin-right: 0;
        }
      }

      &:first-child:nth-last-child(n+4),
      &:first-child:nth-last-child(n+4) ~ .col {
        width: calc(50% - 10px);
        margin-right: 20px;
        &:nth-child(3) { margin-right: 20px }
        &:nth-child(even) {
          margin-right: 0;
        }
      }

      &:first-child:nth-last-child(n+5),
      &:first-child:nth-last-child(n+5) ~ .col {
        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
          width: calc(100% / 3 - 14px);
        }
        &:nth-child(2), &:nth-child(4) { margin-right: 20px }
        &:nth-child(3), &:nth-child(5) { margin-right: 0 }
        &:nth-child(4), &:nth-child(5) {
          width: calc(50% - 10px);
        }
      }

      &:first-child:nth-last-child(n+6),
      &:first-child:nth-last-child(n+6) ~ .col {
        &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6) {
          width: calc(100% / 3 - 14px);
        }
        &:nth-child(5) { margin-right: 20px }
      }

      &:first-child:nth-last-child(n+7),
      &:first-child:nth-last-child(n+7) ~ .col {
        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
          width: calc(100% / 3 - 14px);
        }
        &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7) {
          width: calc(50% - 10px);
        }
        &:nth-child(4), &:nth-child(6) { margin-right: 20px }
        &:nth-child(5), &:nth-child(7) { margin-right: 0 }

      }

      @media screen and (max-width: 760px) {
        width: calc(50% - 10px) !important;
        &:nth-child(even) {
          margin-right: 0 !important;
        }
        &:nth-child(odd) {
          margin-right: 20px !important;
        }
        .img {
          min-height: 180px !important;
        }
      }

      @media screen and (max-width: 550px) {
        width: 100% !important;
        margin-right: 0 !important;
        &:nth-child(odd), &:nth-child(even) { margin-right: 0 !important }
      }

      .img {
        display: block;
        width: 100%;
        min-height: 180px;
        background-image: url('../img/index/col.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }

      .main--copy {
        padding: 20px 20px 20px 20px;

        h3 {
          @include font(22px, black, $regular);
          margin: 0 0 15px 0;
          padding-left: 35px;
          padding-right: 20px;
          position: relative;

          span {
            display: inline-block;
            @include valign;
            position: absolute;
            left: 0;
            width: 25px;

            img {
              display: block;
              width: 100%;
              height: auto;
            }

          }

          &:after {
            display: inline-block;
            content: '';
            background-image: url('../img/global/arrow--red.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 8px 12px;
            width: 8px;
            height: 12px;
            @include valign;
            position: absolute;
            right: 0;
          }

          a {
            display: block;
            width: 100%;
            @include font(22px, black, $regular);
            text-decoration: none;
            @include animate;

            &:hover {
              color: $red;
            }
          }

        }

        p {
          @include font(14px, black, $regular);
        }

      }

      .product--links {
        padding: 0;
        position: absolute;
        bottom: 0%;
        width: 100%;

        @media screen and (max-width: 550px) {
          position: static;
        }

        a {
          @include font(16px, black, $regular);
          text-decoration: none;
          display: block;
          width: calc(100% - 50px);
          padding: 20px 30px 20px 20px;
          background-color: #F7F7F7;
          background-image: url('../img/global/arrow--black.svg');
          background-repeat: no-repeat;
          background-position: right 15px center;
          background-size: 8px 12px;
          @include animate;

          &.red {
            background-color: $red;
            color: white;
            background-image: url('../img/global/arrow--white.svg');
          }

          &:hover {
            &.red {
              background-color: $red-dark;
            }
            background-color: $grey;
          }

        }

      }

      padding-bottom: 12%;

      @media screen and (max-width: 1040px) {
        padding-bottom: 15%;
      }

      @media screen and (max-width: 920px) {
        padding-bottom: 20%;
      }

      @media screen and (max-width: 800px) {
        padding-bottom: 24%;
      }

      @media screen and (max-width: 760px) {
        padding-bottom: 20%;
      }

      @media screen and (max-width: 670px) {
        padding-bottom: 32%;
      }

      @media screen and (max-width: 550px) {
        padding-bottom: 0;
      }

    }

  }

  &.brokers {
    padding-bottom: 0;
    .flex {
      .col {
        padding-bottom: 8%;
        margin-bottom: 40px;
      }
    }
  }

}

.product--benefits {
  padding: 60px 0;
  background-color: $light-blue;

  @media screen and (max-width: 720px) {
    padding: 40px 0;
  }

  h2 {
    @include font(36px, black, $semibold);
    margin: 0 0 25px 0;
  }

  .flex {
    @include flex;

    @media screen and (max-width: 550px) {
      flex-wrap: wrap;
    }

    .col {
      display: inline-block;
      width: 50%;

      @media screen and (max-width: 550px) {
        width: 100%;
        margin-right: 0 !important;
      }

      &:nth-child(1) {
        margin-right: 70px;
      }

      p, li {
        @include font(16px, black, $regular);
        padding-bottom: 20px;

        &:last-child { padding-bottom: 0 }

        a {
          color: black;
          text-decoration: underline;
        }

        strong { font-weight: $bold }

      }

      ul {

        li {
          padding-bottom: 10px;
          margin-left: 1em;

          &:before {
            display: inline-block;
            content: '\2713';
            color: $blue;
            width: 1em;
            margin-left: -1em;
          }

        }

      }

    }

  }

}
