.home--banner {
   .home--banner-content {
     .banner--footnote {
       margin-top: 20px;

       p {
         font-size: 14px;
       }

     }
   }
}

.claim--accordions-main {
  padding: 60px 0;

  h2 {
    @include font(22px, black, $regular);
    margin: 0 0 40px 0;
  }

  p, li {
    @include font(14px, black, $regular);
    padding-bottom: 20px;

    &:last-child { padding-bottom: 0 }

    a {
      color: $red;
      text-decoration: underline;
      font-weight: $bold;
    }

    strong {
      font-weight: $bold;
    }

  }

  ul {
    list-style: disc;
    padding-left: 20px;

    li {
      padding-bottom: 10px;

      &:last-child { padding-bottom: 20px }

    }

  }

  .claim--accordions {
    margin: 30px 0;

    .accordion {
      margin-top: 0;
      margin-bottom: 20px;

      &:last-child { margin-bottom: 0 }

      .accord-title {
        border-radius: 0;
        position: relative;
        background-color: #F7F7F7;
        z-index: 1000;

        &.open {
          background-color: #F7F7F7;
          .icon {
            transform: translateY(-50%) rotate(90deg);

            #Line_62 {
              display: none;
            }
            #Line_63 {
              stroke: $red;
            }
          }
        }

        h3 {
          @include font(22px, black, $bold);
          margin: 0;
          padding-left: 55px;
        }

        .main-icon {
          @include valign;
          position: absolute;
          left: 15px;
          float: none;
          padding: 0;
          margin: 0;
        }

        .icon {
          @include valign;
          position: absolute;
          right: 15px;
          width: 18px;
          height: 18px;
          @include animate;
          transform-origin: 9px 9px;
        }

      }

      .accord-content {
        padding: 0;
        border: 1px solid $red;
        border-radius: 0;
        margin-top: -1px;

        .padding {
          padding: 30px;
        }

        ul {
          list-style: disc;
          padding-left: 20px;
        }

        p, li {
          @include font(14px, black, $regular);
          padding: 0 0 20px 0;

          &:last-child { padding-bottom: 0 }

          a {
            color: $red;
            text-decoration: underline;
          }

          strong { font-weight: $bold }

        }

      }

    }

  }

}
