@mixin clearfix {
	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin bp($point) {
    @media screen and (min-width: $point) {
    	@content;
    }
}

@mixin flex {
	display: flex;
	display: -webkit-flex;
}

@mixin placeholder {
    &.placeholder { @content }
    &:-moz-placeholder { @content }
    &::-webkit-input-placeholder { @content }
}

@mixin font($size: false, $colour: false, $weight: false) {
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    @if $size { font-size: $size; }
    @if $colour { color: $colour; }
    @if $weight { font-weight: $weight; }
}

@mixin valign {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

@mixin center {
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
}

@mixin animate {
	transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
}
