.tooltipster-base {
    /* this ensures that a constrained height set by functionPosition,
    if greater that the natural height of the tooltip, will be enforced
    in browsers that support display:flex */
    display: flex;
    pointer-events: none;
    /* this may be overriden in JS for fixed position origins */
    position: absolute;
    transform: translateY(10px);
}

.tooltipster-ruler {
    /* these let us test the size of the tooltip without overflowing the window */
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
    width: 450px;
}

.tooltipster-fade {
    opacity: 0;
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    -o-transition-property: opacity;
    -ms-transition-property: opacity;
    transition-property: opacity;

    &.tooltipster-show {
        opacity: 1;
    }
}

@keyframes tooltipster-fading {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.tooltipster-update-fade {
    animation: tooltipster-fading 400ms;
}

.tooltipster-sidetip {

    .tooltipster-box {
        background-color: $off-black;
        font-size: 15px;
        line-height: 1.3;
        color: #fff;
        border-radius: 10px;
        text-align: center;
        margin: {
            left: 10px;
            right: 10px;
            bottom: 8px;
        }
        padding: {
            top: 10px;
            right: 10px;
            bottom: 0px;
            left: 10px;
        }
        flex: 1 1 auto;

        p {
            color: #fff;
        }
    }

    .tooltipster-content {
        color: #fff;
        box-sizing: border-box;
        /* these make sure we'll be able to detect any overflow */
        max-height: 100%;
        max-width: 100%;
        overflow: auto;
    }

    .tooltipster-arrow {
        overflow: hidden;
        position: absolute;
        bottom: 0;
        height: 10px;
        margin-left: -10px;
        width: 20px;
    }

    .tooltipster-arrow-background,
    .tooltipster-arrow-border {
        height: 0;
        position: absolute;
        width: 0;
        border: 10px solid transparent;
        border-top-color: $off-black;
        left: 0px;
        top: 0px;
    }
}
