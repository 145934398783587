.form {
    margin-top: 40px;

    &.-contact,
    &.-login {
        margin-top: 0;
    }

    &.-logout {
        margin-top: 20px;
        margin-bottom: 10px;
        text-align: center;
    }

    .form-full {
        @include clearfix;

        padding: 30px;

        &.hide {
            display: none;
        }

        &.remove-bottom {
            padding-bottom: 0;
        }

        &.remove-top {
            padding-top: 0;
        }

        .form-item {

            .input {
                width: 86.7%;

                @media screen and (max-width: 1050px) {
                    width: 100%;
                }
            }

            .item-text {
                width: 13.3%;

                @media screen and (max-width: 1050px) {
                    width: 100%;
                }
            }
        }

        .form-item {
            .full {
                width: 470px;

                @media screen and (max-width: 1050px) {
                    width: 100%;
                }

                &.alarm {
                    width: 280px;

                    @media screen and (max-width: 1050px) {
                        width: 100%;
                    }
                }
            }
        }

        &.terms {
            p {
                font-size: 13px;
                padding: 0;
            }
        }
    }

    .form-halves {
        @include clearfix;

        .form-half {
            width: 44.15%;
            float: left;
            margin-right: 11.701%;

            &.-full-width {
              width: 100%;
              margin-right: 0;
              float: none;

              .item-text {
                width: 100% !important;
              }

            }

            &:nth-child(2n+2) {
                margin-right: 0;
            }

            @media screen and (max-width: 810px) {
                width: 100%;
            }

            &.-claim {
                @media screen and (max-width: 1050px) {
                    width: 100%;
                }
            }
        }

        &.-wider {

          .form-half {
            width: 48%;
            margin-right: 4%;

            .item-text {
              width: 100%;
              max-width: 285px;
            }

            &:nth-child(2) {
              margin-right: 0;
            }

          }

        }

    }

    .form-item {
        @include clearfix;
        margin-bottom: 20px;

        .tooltip {
            width: 13px;
            height: 13px;
            display: block;
            float: right;
            background-image: url('../img/svg/svg--icon-tooltip.svg');
            background-size: cover;
            margin: {
                top: -27px;
                right: 8px;
            }
            z-index: 10;
            position: relative;
            cursor: pointer;

            &.-radio {
                margin-top: -16px;
            }
        }

        .pound-ie {
            position: relative;
        }

        .pound {
            position: absolute;

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                position: absolute;
            }

            @supports (-ms-accelerator:true) {
                position: absolute;
            }

            @media all and (max-width: 1050px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                left: 0;
                top: 29px;
            }

            &:before {
                width: 100%;
                content: '£';
                position: absolute;
                top: 9px;
                left: 10px;
                font-size: 15px;

                // @media screen and (max-width: 450px) {
                //     top: 2px;
                // }
            }

            &.-claim {

                &:before {
                    @media screen and (max-width: 1050px) {
                        top: 12px;
                    }
                }
            }
        }

        &.hide {
            display: none;
        }

        &.-login {
            margin-bottom: 10px;
        }

        .form--table {
          margin-top: 10px;

          table {
            // width: calc(100% - );

            tr {
              &:first-child {
                background-color: $red;
                th {
                  color: white;
                }
              }
            }

            td, th {
              font-size: 13px;
              color: black;
              font-weight: $regular;
              text-align: left;
              padding: 5px;

              input[type="text"] {
                padding: 3px;
                width: calc(100% - 10px);
                max-width: 200px;

                &:focus {
                  outline: none;
                }

              }

            }

            td {
              &:nth-child(2) {
                text-align: center;
              }
            }

          }

        }

        .radios {
          &.flex {
            display: flex;
            display: -webkit-flex;

            @media screen and (max-width: 400px) {
              flex-wrap: wrap;
            }

            .col {
              display: inline-block;
              width: calc(50% - 15px);

              @media screen and (max-width: 400px) {
                padding: 0 !important;
                width: 100%;
              }

              &:nth-child(1) {
                padding-right: 15px;
              }
              &:nth-child(2) {
                padding-left: 15px;
              }

            }

          }
        }

        &.single-line {
          .item-text {
            width: 100%;
            padding-right: 0;
            padding-bottom: 10px;
            margin-top: 0;
          }
          input, textarea {
            width: 100%;
          }
        }

    }

    .input {
        width: 69.76744186046511%;
        float: left;
        border: 1px solid #000;
        padding: 10px;
        outline: none;
        font-size: 13px;
        box-sizing: border-box;
        resize: none;

        &.how-hear {
          height: auto;
          min-height: 80px;
          width: 40%;
        }

        &::-ms-clear {
            display: none;
        }

        &.-pound {
            padding-left: 20px;
        }

        @media screen and (max-width: 450px) {
            float: none;
            width: 100%;
            margin-top: 4px;
        }

        &.-claim {
            @media screen and (max-width: 1050px) {
                width: 100%;
                float: none;
                margin-top: 4px;
            }
        }

        &.-login {
            width: 100%;

            @include placeholder {
                color: #000;
                font-weight: $regular;
            }
        }
    }

    textarea {
        height: 130px;
    }



    .item-text {
        width: 30.23255813953488%;
        float: left;
        font-size: 13px;
        margin-top: 9px;
        padding-right: 10px;
        box-sizing: border-box;

        @media screen and (max-width: 1050px) {
            margin-top: 0;
            width: 100%;
        }

        &.no-margin {
            margin-top: 0;
        }

        &.bullet-margin {
            margin-top: 2px;
        }

        &.delegated {
            width: 290px;

            @media screen and (max-width: 943px) {
                float: none;
            }

            @media screen and (max-width: 810px) {
                float: left;
                width: 315px;
            }

            @media screen and (max-width: 450px) {
                float: none;
                width: 100%;
            }
        }

        &.subagents {
            width: 290px;
            margin-right: 30px;

            @media screen and (max-width: 1020px) {
                float: none;
            }

            @media screen and (max-width: 810px) {
                float: left;
                width: 400px;
            }

            @media screen and (max-width: 580px) {
                float: none;
                width: 100%;
            }
        }

        &.permission {
            width: 320px;

            @media screen and (max-width: 1020px) {
                float: none;
            }

            @media screen and (max-width: 810px) {
                float: left;
            }

            @media screen and (max-width: 450px) {
                width: 100%;
            }
        }

        &.accident-cover {
            width: 240px;
        }

        &.liability-limit {
            width: 150px;
        }

        &.both-claims-five {
            width: 310px;
        }

        &.current {
            width: 210px;
        }

        &.-select {
            width: 100%;
        }

        @media screen and (max-width: 450px) {
            float: none;
            width: 100%;
        }

        &.-claim {
            @media screen and (max-width: 1050px) {
                width: 100%;
            }
        }
    }

    .submit {
        background-color: $red;
        background-image: url('../img/svg/svg--arrow-white.svg');
        background-repeat: no-repeat;
        background-size: 10px 15px;
        background-position: 90% center;
        border: none;
        padding: {
            top: 10px;
            right: 40px;
            bottom: 10px;
            left: 30px;
        }
        color: #fff !important;
        font-weight: $bold;
        // border-radius: 15px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 20px;
        outline: none;

        &.remove-top {
            margin-top: 0;
        }

        &.-logout {
            position: static;
        }

        &.-login {
            position: static;
            transform: translateX(0);
        }
    }

    .radios {
        &.-subagents,
        &.-permission {
            @media screen and (max-width: 1020px) {
                margin-left: 9px;
            }

            @media screen and (max-width: 810px) {
                margin-left: 0;
            }

            @media screen and (max-width: 450px) {
                margin-left: 9px;
            }
        }

        &.-subagents {
            @media screen and (max-width: 580px) {
                margin-left: 9px;
            }
        }

        &.-delegated {
            @media screen and (max-width: 943px) {
                margin-left: 9px;
            }

            @media screen and (max-width: 810px) {
                margin-left: 0;
            }

            @media screen and (max-width: 450px) {
                margin-left: 9px;
            }
        }

        &.-edi {
            transform: translateX(9px);
        }

        &.-claim {
            margin-left: 1px;
        }
    }

    .radio {
       opacity: 0;
       position: absolute;
    }

    label {
        position: relative;
        font-size: 13px;
        margin-right: 10px;
        display: inline-block;
    }

    label:before {
        width: 12px;
        height: 12px;
        display: inline-block;
        content: '';
        border: 1px solid #000;
        border-radius: 12px;
        margin-right: 5px;
        position: relative;
        top: 2px;
    }

    .radio:checked + label:after,
    /* .radio.asdasd + label:after */ {
        width: 8px;
        height: 8px;
        border-radius: 8px;
        display: inline-block;
        content: '';
        background-color: $red;
        position: absolute;
        left: 3px;
        top: 5px;
    }

    select {
        margin-top: 15px;
        border: 1px solid #000;
        padding: 10px;
        outline: none;
        appearance: none;
        background-color: #fff;
        border-radius: 0;
        width: 100%;
        background-image: url('../img/svg/svg--arrow-side-red.svg');
        background-size: 19px 10px;
        background-repeat: no-repeat;
        background-position: 95% center;

        &::-ms-expand {
            display: none;
        }
    }
}

.form-regarding {
    margin-bottom: 10px;
}

.form-thanks {
    display: none;
    margin-top: 15px;

    p {
        font-size: 16px;
        color: $red;
        font-weight: $bold;
    }
}

.form-complaints,
.form-question,
.form-church,
.form-terms {
    display: none;
    margin-bottom: 10px;
}

.pink-box {
    background-color: $red-light;
    padding: 20px 25px;
    // border-radius: 15px;
    margin-bottom: 15px;
}

.form-item {
  &.property-damage-hide {
    .input {
      float: none;
      width: 100%;
    }
    .item-text {
      margin-top: 0;
    }
  }
}
