/* Fonts */

$opensans: 'Open Sans', sans-serif;
$regular: 400;
$semibold: 600;
$bold: 700;

/* Colours */

$red: #ed3d41;
$red-dark: #a32c2f;
$red-light: #fef0f0;
$new-red: #ba102b;
$mid-red: #BF112E;
$off-black: #363636;
$text-black: #000;
$text-grey: #999;
$grey: #eee;
$light-blue: #E5F7FF;
$blue: #04A0E0;
$dark-blue: #0063A0;
$new-grey: #797979;
$gold: #BCA96F;
$spow: #00a79e;
