.pathways {

    &.-church-and-manse {

        .pathway {
            width: 46%;
            margin: {
                top: 50px;
                right: 4%;
            }
            padding: {
                top: 70px;
                left: 30px;
                right: 30px;
            }

            p {
                font-size: 14px;
            }

            &.-manse {
                margin: {
                    right: 0;
                    left: 4%;
                }
            }

            &.-next {
                margin-right: 0px;
                margin-left: 4%;

                @media screen and (max-width: 600px) {
                    margin-left: 0;
                }
            }

            @media screen and (max-width: 600px) {
                width: 100%;
                margin: {
                    right: 0;
                    left: 0;
                }

                &.-manse {
                    margin: {
                        right: 0;
                        left: 0;
                    }
                }
            }
        }

        &.-dynamic {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

            .pathway {
                margin: 0 20px 40px 0;
                flex-grow: 1;
                flex-shrink: 1;
                padding: {
                    top: 70px;
                    left: 30px;
                    right: 30px;
                }

                p {
                    font-size: 14px;
                }

                &:first-child {
                  margin-left: 0px;
                }

                // IF 2 PATHWAYS
                &:first-child:nth-last-child(2), &:first-child:nth-last-child(2) ~ div {
                  width: calc(50% - 20px);

                  &:last-child {
                    margin-right: 0px;
                  }

                }
                //
                // IF 3 PATHWAYS
                &:first-child:nth-last-child(3), &:first-child:nth-last-child(3) ~ div {
                  width: calc(100% / 3 - 20px);
                }
                //
                // IF 4 PATHWAYS
                &:first-child:nth-last-child(4), &:first-child:nth-last-child(4) ~ div {
                  width: calc(50% - 20px);
                }
                //
                // IF 5 PATHWAYS
                &:first-child:nth-last-child(5), &:first-child:nth-last-child(5) ~ div {
                  width: calc(100% / 3 - 20px);

                  &:nth-child(3), &:nth-child(5) {
                    margin-right: 0px;
                  }

                }
                //
                // IF 6 PATHWAYS
                &:first-child:nth-last-child(6), &:first-child:nth-last-child(6) ~ div {
                  width: calc(100% / 3 - 40px);

                  &:nth-child(4) {
                    margin-left: 0px;
                  }

                }

                @media screen and (max-width: 890px) {
                  width: calc(50% - 20px) !important;

                  &:nth-child(odd) {
                    margin-left: 0px;
                    margin-right: 0px;
                  }

                  &:nth-child(even) {
                    margin-left: 20px;
                    margin-right: 0px;
                  }

                }

                @media screen and (max-width: 675px) {
                  width: 100% !important;
                  margin-left: 0px !important;
                  margin-right: 0px !important;
                }

            }
        }

    }
}

.policy-benefits {
    @include clearfix;
    margin: {
        top: 70px;
    }

    &-title {
        margin-bottom: 20px;

        p {
            font-size: 15px;
            font-weight: $semibold;
        }
    }

    .church-single {
      width: 50px;
      height: 50px;
      display: block;
      background-image: url('../img/svg/svg--icon-church-manse-red.svg');
      background-size: 33px auto;
      background-repeat: no-repeat;
      float: left;
      margin-right: 10px;
    }

    .church,
    .manse,
    .one,
    .two,
    .business {
        width: 50px;
        height: 50px;
        display: block;
        // background-image: url('../img/svg/svg--icon-church-manse-red.svg');
        // background-size: 33px auto;
        // background-repeat: no-repeat;
        float: left;
        margin-right: 10px;
    }

    .manse-single {
        width: 48px;
        height: 42px;
        background-image: url('../img/svg/svg--icon-manse-red.svg');
        background-size: 33px auto;
        background-repeat: no-repeat;
        display: block;
        float: left;
        margin-right: 10px;
    }

    .business {
        width: 43px;
        height: 49px;
        background-repeat: no-repeat;
        background-image: url('../img/svg/svg--icon-business.svg');
    }

    .one {
        width: 19px;
        height: 36px;
        background-image: url('../img/svg/svg--1-red.svg');
        background-size: 19px auto;
    }

    .two {
        width: 25px;
        height: 36px;
        background-image: url('../img/svg/svg--2-red.svg');
        background-position: center center;
        background-size: 25px auto;
    }

    .policy-benefit-list {
        margin-bottom: 30px;
        columns: 2;

        @media screen and (max-width: 590px) {
            columns: 1;
        }

        li {
            @include clearfix;

            background-image: url('../img/svg/svg--icon-tick.svg');
            background-repeat: no-repeat;
            background-position: 2px 5px;
            padding-left: 20px;
            margin: {
                top: 10px;
                bottom: 10px;
            }
            display: inline-block;
            position: relative;

            @media screen and (max-width: 590px) {
                display: block;
                margin: {
                    top: 15px;
                    bottom: 15px;
                }
            }

            p {
                float: left;
                font-size: 15px;
                font-weight: $semibold;
                margin-right: 5px;
            }

            span {
                width: 13px;
                height: 13px;
                display: block;
                float: left;
                background-image: url('../img/svg/svg--icon-tooltip.svg');
                background-size: cover;
                margin: {
                    top: 5px;
                }
                cursor: pointer;
            }

            .subtext {
                clear: both;

                p {
                    float: none;
                    font-size: 12px;
                    font-weight: $regular;
                    margin-right: 0;
                }
            }
        }
    }
}

.content.product-benefits {
    width: 100%;
    float: none;

    .policy-benefit-list {
        li {
            width: 96%;
            display: inline-block;

            ul {

                li {
                    font-size: 12px;
                    background-image: none;
                    padding-left: 0;
                    margin-left: 0px;
                    margin-top: 5px;
                    margin-bottom: 1px;

                    &:before {
                        content: '•';
                        padding-right: 5px;
                    }
                }
            }
        }
    }
}

.-point-3-fix {
    .point-3 {
        position: absolute;
        top: 19px;
        left: 142px;

        @media screen and (max-width: 979px) {
            left: 183px;
        }

        @media screen and (max-width: 858px) {
            left: 227px;
        }

        @media screen and (max-width: 830px) {
            left: 75px;
        }

        @media screen and (max-width: 819px) {
            left: 102px;
        }

        @media screen and (max-width: 763px) {
            left: 142px;
        }

        @media screen and (max-width: 684px) {
            left: 183px;
        }

        @media screen and (max-width: 601px) {
            left: 227px;
        }

        @media screen and (max-width: 590px) {
            left: auto;
            top: auto;
            position: relative;
        }

        @media screen and (max-width: 475px) {
            top: 19px;
            left: 75px;
            position: absolute;
        }

        @media screen and (max-width: 421px) {
            left: 102px;
        }
    }
}
