.article-quick {
    @include clearfix;

    margin-top: 40px;

    li {
        width: 505px;
        border: 1px solid #ccc;
        border-radius: 15px;
        box-sizing: border-box;
        padding: {
            top: 20px;
            right: 20px;
            bottom: 20px;
            left: 25px;
        }
        outline: none;
    }

    .title {
        font-size: 20px;
        font-weight: $bold;
    }

    .content {
        @include clearfix;

        margin-top: 15px;
    }

        .snippet {
            width: 155px;

            @media screen and (max-width: 1079px) {
                width: 45%;
            }

            @media screen and (max-width: 455px) {
                width: 100%;
            }

            float: left;

            span {
                color: $text-grey;
                font-size: 12px;
            }

            a {
                display: block;
                padding-left: 18px;
                background-image: url('../img/svg/svg--arrow-red.svg');
                background-size: 12px 14px;
                background-position: center left;
                background-repeat: no-repeat;
                font-size: 13px;
                font-weight: $semibold;
                margin-top: 10px;
                text-decoration: none;
                color: $text-black;
            }
        }

        .image {
            width: 290px;
            float: right;

            @media screen and (max-width: 1079px) {
                width: 50%;
            }

            @media screen and (max-width: 455px) {
                width: 100%;
                margin-top: 15px;
            }

            img {
                display: block;
                width: 100%;
            }
        }
}

.-news-archive {
    li {
        float: left;
        margin: {
            right: 10px;
            bottom: 20px;
        }

        &:first-child {
            display: none;
        }

        &:nth-child(2n+3) {
            margin-right: 0;
        }

        @media screen and (max-width: 1059px) {
            width: 100%;
        }
    }
}

.news-feature {

    h3 {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .feature-text {
        float: left;
        width: 45%;
        margin-right: 5%;

        p {
            font-size: 17px;
        }

        span {
            color: $text-grey;
            font-size: 12px;
            display: block;
        }

        a {
            display: block;
            padding-left: 18px;
            background-image: url('../img/svg/svg--arrow-red.svg');
            background-size: 12px 14px;
            background-position: center left;
            background-repeat: no-repeat;
            font-size: 13px;
            font-weight: $semibold;
            margin-top: 10px;
            text-decoration: none;
            color: $text-black;
        }

        @media screen and (max-width: 500px) {
            width: 100%;
            float: none;
        }
    }

    .feature-image {
        float: right;
        width: 50%;
        margin-left: 20px;

        &.-no-wrap-margin {
            margin-left: 0;
        }

        @media screen and (max-width: 500px) {
            width: 100%;
            margin-bottom: 15px;
            float: none;
            margin-left: 0;
        }

        img {
            width: 100%;
        }
    }
}

.-news-article {

    h1 {
        color: $text-black;
        font-weight: $bold;
        font-size: 34px;
    }

    span {
        color: $text-grey;
        font-size: 12px;
        margin-bottom: 10px;
        display: block;
    }
}
.-news-article-copy {
    @include clearfix;

    p {
        font-size: 17px;
        margin-top: 15px;
    }

    ul {
      list-style: disc;
      padding-left: 20px;
      li {
        padding-bottom: 20px;
      }
    }

    a {
        color: $red;
        text-decoration: none;
        font-weight: $bold;
    }

    .feature-text {
        margin-top: 20px;
    }
}
