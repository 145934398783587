#cookiescript_injected {
  // width: calc(70% - 20px) !important;
  // padding: 0 10px !important;


  // @media screen and (max-width: 900px) {
  //   width: calc(100% - 20px) !important;
  // }

  #cookiescript_description {
    width: 100%;
    max-width: 70%;
    @media screen and (max-width: 767px) {
      max-width: 95%;
    }
    span, a {
      font-size: 16px !important;
      line-height: 1.4 !important;
    }
  }
  //
  // #cookiescript_buttons {
  //   margin: 15px auto 20px auto !important;
  // }
  //
  // #cookiescript_accept {
  //   margin: 0 !important
  // }
  //
  // #cookiescript_reject {
  //   margin: 0 0 0 10px !important;
  // }
  //
  // #cookiescript_readmore {
  //   margin: 10px 0 0 10px !important;
  // }

}
.hide {
  display: none !important;
}

.container {
    width: 100%;
    max-width: 1060px;
    padding: 0 20px;
    margin: 0 auto;
    box-sizing: border-box;
    font-family: $opensans;
    font-weight: $regular;
    color: $text-black;

    strong {
        font-weight: $bold;
    }
}

.logo {
    background-image: url('../img/svg/svg--logo.svg');
    background-repeat: no-repeat;
    background-size: cover;
    display: block;

    &.-header {
        width: 217px;
        height: 65px;
        margin: {
            top: 25px;
            bottom: 10px;
        }
    }

    &.-footer {
        width: 188px;
        height: 56px;
        float: right;

        @media screen and (max-width: 900px) {
            margin-top: 20px;
            margin-right: auto;
            margin-left: auto;
            float: none;
        }
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { // selects IE10 & IE11
        &.-header {
            background-image: url('../img/logo-header.jpg');
        }

        &.-footer {
            background-image: url('../img/logo-footer.jpg');
        }
    }

    &.-iip {
        width: 170px;
        height: 46px;
        background-image: url('../img/svg/svg--logo-iip.svg');
        background-size: contain;
        margin-top: 30px;

        @media screen and (max-width: 900px) {
            margin-right: auto;
            margin-left: auto;
        }
    }
}

.arrow {
    width: 9px;
    height: 19px;
    display: block;
    position: absolute;
    z-index: 4;
    background-size: cover;
    background-image: url('../img/svg/svg--arrow.svg');
}

.main-content {
    margin: {
        top: 50px;
        bottom: 50px;
    }
    @include clearfix;
    .container {
      @include clearfix;
    }
}

    .main-content-split {
        @include clearfix;

        .content {
            /* width: 700px; */
            width: 68.62745098039215%;
            float: left;

            &.full-width {
                width: 100%;
            }

            p {
                a {
                    color: $red;
                }
            }

            @media screen and (max-width: 830px) {
                width: 100%;
                float: none;
            }

            &.-broker-finder {
                p {
                    margin-bottom: 15px;
                }

                img {
                    width: 140px;
                    float: right;
                    margin: {
                        top: 40px;
                        left: 40px;
                    }
                }
            }
        }

            h1 {
                color: $text-black;
                font-weight: $bold;
                font-size: 34px;
            }

            p {
                font-size: 13px;
            }

        .aside {
            /* width: 286px; */
            width: 28.03921568627451%;
            float: left;
            /* margin-left: 34px; */
            margin-left: 3.3333333333333335%;
            position: relative;

            @media screen and (max-width: 830px) {
                width: 100%;
                margin: {
                    top: 20px;
                    left: 0;
                }
            }

            &.-broker-finder {
                @media screen and (max-width: 830px) {
                    display: none;
                }
            }

            &-section {
                display: block;
                text-decoration: none;
                color: $off-black;
                font-weight: $bold;
                font-size: 17px;
                border: 1px solid $off-black;
                // border-radius: 15px;
                margin-bottom: 15px;
                background-color: #fff;

                .aside-header {
                    position: relative;
                    padding: {
                        top: 20px;
                        right: 40px;
                        bottom: 20px;
                        left: 75px;
                    }
                }

                &.-church-manse {
                    .aside-header {
                        background-image: url('../img/svg/svg--icon-church-manse-red.svg');
                        background-size: 30px 44px;
                        background-repeat: no-repeat;
                        background-position: 25px 19px;

                        @media screen and (max-width: 1000px) {
                            padding: {
                                top: 65px;
                                left: 25px;
                            }
                            background-position: center 10px;
                        }

                        @media screen and (max-width: 830px) {
                            padding: {
                                top: 20px;
                                left: 75px;
                            }
                            background-position: 25px center;
                        }
                    }
                }

                &.-brokers-and-intermediaries {
                    .aside-header {
                        background-image: url('../img/svg/svg--icon-brokers-red.svg');
                        background-size: 44px auto;
                        background-repeat: no-repeat;
                        background-position: 20px 27px;

                        @media screen and (max-width: 1000px) {
                            padding: {
                                top: 50px;
                                left: 25px;
                            }
                            background-position: center 10px;
                        }

                        @media screen and (max-width: 830px) {
                            padding: {
                                top: 20px;
                                left: 75px;
                            }
                            background-position: 25px center;
                        }

                        &.new-broker-enquiry {
                            // border-radius: 15px;
                            background-color: $red;
                            background-image: url('../img/svg/svg--icon-brokers.svg');
                        }
                    }
                }

                &.-understanding-church {
                    .aside-header {
                        background-image: url('../img/svg/svg--icon-understanding.svg');
                        background-size: 37px 37px;
                        background-repeat: no-repeat;
                        background-position: 22px 24px;

                        @media screen and (max-width: 1000px) {
                            background-position: center 10px;
                            padding: {
                                top: 55px;
                                left: 25px;
                            }
                        }

                        @media screen and (max-width: 830px) {
                            padding: {
                                top: 20px;
                                left: 75px;
                            }
                            background-position: 22px center;
                        }
                    }
                }

                &.-question {
                    width: 100%;

                    .question-type {
                        cursor: pointer;
                    }

                    .aside-header {
                        background-image: url('../img/svg/svg--icon-question.svg');
                        background-size: 41px 41px;
                        background-repeat: no-repeat;
                        background-position: 20px 10px;

                        @media screen and (max-width: 1000px) {
                            background-position: center 10px;
                            padding: {
                                top: 57px;
                                left: 25px;
                            }
                        }

                        @media screen and (max-width: 830px) {
                            padding: {
                                top: 20px;
                                left: 75px;
                            }
                            background-position: 20px center;
                        }
                    }
                }

                &.-documents {
                    .aside-header {
                        background-image: url('../img/svg/svg--icon-documents.svg');
                        background-size: 34px 34px;
                        background-repeat: no-repeat;
                        background-position: 25px 22px;

                        @media screen and (max-width: 1000px) {
                            background-position: center 10px;
                            padding: {
                                top: 57px;
                                left: 25px;
                            }
                        }

                        @media screen and (max-width: 830px) {
                            padding: {
                                top: 20px;
                                left: 75px;
                            }
                            background-position: 20px center;
                        }
                    }
                }

                &.-policyholder {
                    .aside-header {
                        background-image: url('../img/svg/svg--icon-church-manse-red.svg');
                        background-size: 30px 44px;
                        background-repeat: no-repeat;
                        background-position: 25px 8px;

                        @media screen and (max-width: 1000px) {
                            padding: {
                                top: 65px;
                                left: 25px;
                            }
                            background-position: center 10px;
                        }

                        @media screen and (max-width: 830px) {
                            padding: {
                                top: 20px;
                                left: 75px;
                            }
                            background-position: 25px center;
                        }
                    }
                }

                &.-supporting-churches {
                    .aside-header {
                        background-image: url('../img/svg/svg--icon-supporting-church-red.svg');
                        background-size: 46px 34px;
                        background-repeat: no-repeat;
                        background-position: 5px 25px;
                        margin-left: 20px;

                        @media screen and (max-width: 1000px) {
                            padding: {
                                top: 65px;
                                left: 25px;
                            }
                            background-position: center 10px;
                            margin-left: 0;
                        }

                        @media screen and (max-width: 830px) {
                            padding: {
                                top: 20px;
                                left: 85px;
                            }
                            background-position: 20px center;
                        }
                    }
                }

                &.-urc-awards {
                    .aside-header {
                        background-image: url('../img/urc.png');
                        background-size: 66px 45px;
                        background-repeat: no-repeat;
                        background-position: 0px 18px;
                        margin-left: 20px;

                        @media screen and (max-width: 1000px) {
                            padding: {
                                top: 65px;
                                left: 25px;
                            }
                            background-position: center 10px;
                            margin-left: 0;
                        }

                        @media screen and (max-width: 830px) {
                            padding: {
                                top: 20px;
                                left: 100px;
                            }
                            background-position: 20px center;
                        }
                    }
                }

                &.-federation-awards {
                    .aside-header {
                        background-image: url('../img/congregational-federation.png');
                        background-size: 55px 45px;
                        background-repeat: no-repeat;
                        background-position: 0px 30px;
                        margin-left: 20px;

                        @media screen and (max-width: 1000px) {
                            padding: {
                                top: 65px;
                                left: 25px;
                            }
                            background-position: center 10px;
                            margin-left: 0;
                        }

                        @media screen and (max-width: 830px) {
                            padding: {
                                top: 20px;
                                left: 100px;
                            }
                            background-position: 20px center;
                        }
                    }
                }

                &.-helplines {
                    .aside-header {
                        background-image: url('../img/svg/svg--icon-helplines.svg');
                        background-size: 30px auto;
                        background-repeat: no-repeat;
                        background-position: 25px 17px;

                        @media screen and (max-width: 1000px) {
                            padding: {
                                top: 65px;
                                left: 25px;
                            }
                            background-position: center 10px;
                        }

                        @media screen and (max-width: 830px) {
                            padding: {
                                top: 20px;
                                left: 75px;
                            }
                            background-position: 25px center;
                        }
                    }

                    h2 {
                        font-size: 24px;
                        font-weight: $bold;
                        margin-top: -6px;
                        color: $red;
                    }

                    .aside-content {

                        .mail {
                            background-image: url('../img/svg/svg--icon-email.svg');
                            background-repeat: no-repeat;
                            background-size: cover;
                            background-position: left center;
                            display: block;
                            width: 31px;
                            height: 23px;
                            float: left;
                            margin-right: 10px;

                            @media screen and (max-width: 1040px) {
                                float: none;
                                margin: 0 auto;
                            }

                            @media screen and (max-width: 830px) {
                                float: left;
                                margin-right: 10px;
                            }
                        }

                        a {
                            color: $red;
                            font-weight: $bold;
                            font-size: 13px;
                            text-decoration: none;
                        }
                    }
                }

                &.-helplines-black {
                    .aside-header {
                        background-image: url('../img/svg/svg--icon-helplines-black.svg');
                        background-size: 30px auto;
                        background-repeat: no-repeat;
                        background-position: 25px 17px;

                        @media screen and (max-width: 1000px) {
                            padding: {
                                top: 65px;
                                left: 25px;
                            }
                            background-position: center 10px;
                        }

                        @media screen and (max-width: 830px) {
                            padding: {
                                top: 20px;
                                left: 75px;
                            }
                            background-position: 25px center;
                        }
                    }

                    h2 {
                      font-size: 24px;
                      font-weight: $bold;
                      margin-top: -6px;
                      color: $text-black;
                    }

                    h3 {
                      color: $text-black;
                      font-weight: $bold;
                      font-size: 13.3px;
                      margin-top: 15px;
                    }
                }

                &.-news-media {
                    .aside-header {
                        background-image: url('../img/svg/svg--icon-news.svg');
                        background-size: 45px 36px;
                        background-repeat: no-repeat;
                        background-position: 18px 14px;

                        @media screen and (max-width: 1000px) {
                            padding: {
                                top: 65px;
                                left: 25px;
                            }
                            background-position: center 10px;
                        }

                        @media screen and (max-width: 830px) {
                            padding: {
                                top: 20px;
                                left: 75px;
                            }
                            background-position: 17px center;
                        }
                    }

                    h2 {
                      font-size: 24px;
                      font-weight: $bold;
                      margin-top: -6px;
                      color: $text-black;
                    }

                    h3 {
                      color: $text-black;
                      font-weight: $bold;
                      font-size: 13.3px;
                      margin-top: 15px;
                    }
                }

                &.-request {
                    .aside-header {
                        background-image: url('../img/svg/svg--icon-request.svg');
                        background-size: 35px 44px;
                        background-repeat: no-repeat;
                        background-position: 25px 22px;

                        @media screen and (max-width: 1000px) {
                            padding: {
                                top: 65px;
                                left: 25px;
                            }
                            background-position: center 10px;
                        }

                        @media screen and (max-width: 830px) {
                            padding: {
                                top: 20px;
                                left: 75px;
                            }
                            background-position: 25px center;
                        }
                    }
                }

                &.-response {
                    .aside-header {
                        background-image: url('../img/svg/svg--icon-response-black.svg');
                        background-size: 43px auto;
                        background-repeat: no-repeat;
                        background-position: 21px 24px;

                        @media screen and (max-width: 1000px) {
                            padding: {
                                top: 65px;
                                left: 25px;
                            }
                            background-position: center 10px;
                        }

                        @media screen and (max-width: 830px) {
                            padding: {
                                top: 20px;
                                left: 75px;
                            }
                            background-position: 25px center;
                        }
                    }
                }

                &.-news-icon {
                    .aside-header {
                        background-image: url('../img/svg/svg--icon-understanding.svg');
                        background-size: 37px auto;
                        background-repeat: no-repeat;
                        background-position: 21px 13px;

                        @media screen and (max-width: 1000px) {
                            padding: {
                                top: 65px;
                                left: 25px;
                            }
                            background-position: center 10px;
                        }

                        @media screen and (max-width: 830px) {
                            padding: {
                                top: 20px;
                                left: 75px;
                            }
                            background-position: 20px center;
                        }
                    }
                }

                &.-my-information {
                    .aside-header {
                        background-image: url('../img/svg/svg--icon-check-info.svg');
                        background-size: 43px 43px;
                        background-repeat: no-repeat;
                        background-position: 20px 22px;

                        @media screen and (max-width: 1000px) {
                            padding: {
                                top: 65px;
                                left: 25px;
                            }
                            background-position: center 10px;
                        }

                        @media screen and (max-width: 830px) {
                            padding: {
                                top: 20px;
                                left: 75px;
                            }
                            background-position: 22px center;
                        }
                    }
                }

                &.-products {
                    .aside-header {
                        background-image: url('../img/svg/svg--icon-products.svg');
                        background-size: 43px 43px;
                        background-repeat: no-repeat;
                        background-position: 20px 15px;

                        @media screen and (max-width: 1000px) {
                            padding: {
                                top: 65px;
                                left: 25px;
                            }
                            background-position: center 10px;
                        }

                        @media screen and (max-width: 830px) {
                            padding: {
                                top: 20px;
                                left: 75px;
                            }
                            background-position: 20px center;
                        }
                    }
                }

                &.-order {
                    .aside-header {
                        background-image: url('../img/svg/svg--icon-email-documents.svg');
                        background-size: 35px 44px;
                        background-repeat: no-repeat;
                        background-position: 25px 10px;

                        @media screen and (max-width: 1000px) {
                            padding: {
                                top: 65px;
                                left: 25px;
                            }
                            background-position: center 10px;
                        }

                        @media screen and (max-width: 830px) {
                            padding: {
                                top: 20px;
                                left: 75px;
                            }
                            background-position: 25px center;
                        }
                    }
                }

                &.-login {
                    .aside-header {
                        background-image: url('../img/svg/svg--icon-elto.svg');
                        background-repeat: no-repeat;
                        background-size: 39px 35px;
                        background-position: 20px center;

                        @media screen and (max-width: 1000px) {
                            padding: {
                                top: 65px;
                                left: 25px;
                            }
                            background-position: center 10px;
                        }

                        @media screen and (max-width: 830px) {
                            padding: {
                                top: 20px;
                                left: 75px;
                            }
                            background-position: 25px center;
                        }
                    }

                    .aside-mini {
                        margin-top: 10px;

                        h2 {
                            font-weight: $bold;
                            font-size: 28px;
                            padding-left: 35px;
                            background-image: url('../img/svg/svg--icon-helplines.svg');
                            background-size: 22px auto;
                            background-repeat: no-repeat;
                            background-position: 0 9px;
                        }

                        p {
                            padding-left: 35px;
                            margin-top: -3px;
                            margin-bottom: 10px;
                            font-size: 14px;
                        }

                        a {
                            font-size: 14px;
                            padding-left: 35px;
                            background-image: url('../img/svg/svg--icon-email.svg');
                            background-size: 25px auto;
                            background-repeat: no-repeat;
                        }
                    }
                }

                &.-spow {
                    border: 1px solid #00A69D;

                    .aside-header {
                        background-image: url('../img/svg/svg--logo-spow.svg');
                        background-size: 58px auto;
                        background-repeat: no-repeat;
                        background-position: 20px 22px;
                        font-size: 15px;
                        padding: {
                            left: 90px;
                        }

                        @media screen and (max-width: 830px) {
                            min-height: 80px;
                        }
                    }

                    .aside-content {
                        position: relative;
                        color: #00A69D;
                        font-size: 14px;
                    }

                    &.-logged-in {
                        .aside-header {
                            background-position: 20px 20px;
                            color: #00A69D;
                            padding-top: 35px;
                            margin-bottom: 15px;

                            .arrow {
                                transform: translateY(0px);
                            }
                        }

                        .aside-content {
                            p {
                                margin-top: 10px;
                            }
                        }
                    }
                }

                &.-accessibility {
                    .aside-header {
                        background-image: url('../img/svg/svg--icon-accessibility.svg');
                        background-size: 40px 40px;
                        background-repeat: no-repeat;
                        background-position: 22px 13px;

                        @media screen and (max-width: 1000px) {
                            background-position: center 10px;
                            padding: {
                                top: 55px;
                                left: 25px;
                            }
                        }

                        @media screen and (max-width: 830px) {
                            padding: {
                                top: 20px;
                                left: 75px;
                            }
                            background-position: 22px center;
                        }
                    }
                }

                &.-sitemap {
                    .aside-header {
                        background-image: url('../img/svg/svg--icon-sitemap.svg');
                        background-size: 50px 50px;
                        background-repeat: no-repeat;
                        background-position: 16px 6px;

                        @media screen and (max-width: 1000px) {
                            background-position: center 10px;
                            padding: {
                                top: 55px;
                                left: 25px;
                            }
                        }

                        @media screen and (max-width: 830px) {
                            padding: {
                                top: 20px;
                                left: 75px;
                            }
                            background-position: 18px center;
                        }
                    }
                }

                &.-cookie {
                    .aside-header {
                        background-image: url('../img/svg/svg--icon-cookies.svg');
                        background-size: 50px 50px;
                        background-repeat: no-repeat;
                        background-position: 16px 6px;

                        @media screen and (max-width: 1000px) {
                            background-position: center 10px;
                            padding: {
                                top: 55px;
                                left: 25px;
                            }
                        }

                        @media screen and (max-width: 830px) {
                            padding: {
                                top: 20px;
                                left: 75px;
                            }
                            background-position: 18px center;
                        }
                    }
                }

                &.-privacy {
                    .aside-header {
                        background-image: url('../img/svg/svg--icon-privacy.svg');
                        background-size: 50px 60px;
                        background-repeat: no-repeat;
                        background-position: 14px 1px;

                        @media screen and (max-width: 1000px) {
                            background-position: center 0px;
                            padding: {
                                top: 55px;
                                left: 25px;
                            }
                        }

                        @media screen and (max-width: 830px) {
                            padding: {
                                top: 20px;
                                left: 75px;
                            }
                            background-position: 18px center;
                        }
                    }
                }

                &.-legal {
                    .aside-header {
                        background-image: url('../img/svg/svg--icon-legal2.svg');
                        background-size: 50px 50px;
                        background-repeat: no-repeat;
                        background-position: 16px 8px;

                        @media screen and (max-width: 1000px) {
                            background-position: center 10px;
                            padding: {
                                top: 55px;
                                left: 25px;
                            }
                        }

                        @media screen and (max-width: 830px) {
                            padding: {
                                top: 20px;
                                left: 75px;
                            }
                            background-position: 18px center;
                        }
                    }
                }

                &.-finder {
                    .aside-header {
                        background-image: url('../img/svg/svg--icon-search.svg');
                        background-size: 35px 35px;
                        background-repeat: no-repeat;
                        background-position: 24px 12px;

                        @media screen and (max-width: 1000px) {
                            background-position: center 10px;
                            padding: {
                                top: 55px;
                                left: 25px;
                            }
                        }

                        @media screen and (max-width: 830px) {
                            padding: {
                                top: 20px;
                                left: 75px;
                            }
                            background-position: 18px center;
                        }
                    }
                }

                &.-charitable {
                    background-image: url('../img/svg/svg--icon-charitable.svg');
                    background-repeat: no-repeat;
                    background-position: 14px 15px;
                    background-size: 52px 34px;

                    &.-sponsor {
                        background-position: 14px 25px;

                        @media screen and (max-width: 830px) {
                            background-position: 14px 15px;
                        }
                    }
                }

                &.-finder {
                    .aside-header {
                        background-image: url('../img/svg/svg--icon-search.svg');
                        background-size: 35px 35px;
                        background-repeat: no-repeat;
                        background-position: 24px 12px;

                        @media screen and (max-width: 1000px) {
                            background-position: center 10px;
                            padding: {
                                top: 55px;
                                left: 25px;
                            }
                        }

                        @media screen and (max-width: 830px) {
                            padding: {
                                top: 20px;
                                left: 75px;
                            }
                            background-position: 18px center;
                        }
                    }
                }

                &.-download {
                    background-image: url('../img/svg/svg--icon-download.svg');
                    background-repeat: no-repeat;
                    background-position: 20px center;
                    background-size: 30px 33px;

                    @media screen and (max-width: 1000px) {
                        background-position: center 10px;
                        padding: {
                            top: 55px;
                            left: 25px;
                        }
                    }

                    @media screen and (max-width: 830px) {
                        padding: {
                            top: 20px;
                            left: 75px;
                        }
                        background-position: 18px center;
                    }
                }

                &.-claim {
                  background-image: url('../img/svg/svg--icon-request-red.svg');
                  background-repeat: no-repeat;
                  background-size: 36px 41px;
                  background-position: 20px center;
                }

                &.-elto {
                  background-image: url('../img/svg/svg--icon-elto.svg');
                  background-repeat: no-repeat;
                  background-size: 39px 35px;
                  background-position: 20px center;
                }

                &.-eltr {
                  background-image: url('../img/svg/svg--icon-elt-black.svg');
                  background-repeat: no-repeat;
                  background-size: 35px 33px;
                  background-position: 20px center;
                }

                &.-feedback {
                  background-image: url('../img/svg/svg--icon-feedback-form.svg');
                  background-size: 45px 34px;
                  background-repeat: no-repeat;
                  background-position: 19px 13px;
                }

                .aside-content {
                    padding: {
                        top: 0px;
                        right: 24px;
                        bottom: 20px;
                        left: 24px;
                    }

                    p {
                        font-size: 13px;

                        a {
                            font-weight: $bold;
                        }
                    }

                    a {
                        color: $text-black;
                        /* font-weight: $bold; */
                        text-decoration: none;
                    }

                    ul {

                        li {
                            padding-left: 20px;
                            background-image: url('../img/svg/svg--arrow-red.svg');
                            background-repeat: no-repeat;
                            background-size: 8px 12px;
                            background-position: left 4px;
                            padding-left: 15px;
                            margin: {
                                top: 10px;
                            }
                            color: $off-black;
                            font-size: 15px;
                            font-weight: $regular;

                            ul {
                                display: none;

                                li {
                                    background-image: none;
                                    padding-left: 0;
                                }
                            }

                            &.show {
                                ul {
                                    display: block;
                                }
                            }
                        }
                    }
                }

                &.-news {
                    font-size: 15px;
                    padding-top: 20px;
                    font-weight: $regular;

                    strong,
                    a {
                        color: $red;
                        text-decoration: none;
                    }
                }

                .arrow {
                    position: absolute;
                    top: 50%;
                    right: 20px;
                    transform: translateY(-50%);
                }

                &.spow {
                  border-color: #00A69D !important;

                  .aside-header {
                    color: #00A69D;
                    @include flex;
                    align-items: center;
                    padding-top: 10px;
                    padding-bottom: 10px;

                    .img {
                      max-width: 70px !important;
                      float: none !important;
                      margin: 0 !important;
                    }

                    .arrow {
                      background-image: url('../img/global/arrow--spow.svg');
                    }

                  }

                }

                &.-red {
                    border: 1px solid $red;

                    .arrow {
                        background-image: url('../img/svg/svg--arrow-red.svg');
                        background-position: -2px;
                    }
                }

                &.-red-bg {
                    background-color: #FFEFF0;
                    padding: 20px;

                    h3 {
                        margin-bottom: 10px;
                    }

                    a {
                        margin-top: 15px;
                        display: block;
                        color: #fff;
                        text-decoration: none;

                        .arrow {
                            background-image: url('../img/svg/svg--arrow-white.svg');
                        }
                    }
                }

                &.-spow {

                    .arrow {
                        background-image: url('../img/svg/svg--arrow-spow.svg');
                        background-position: -2px;
                        transform: translateY(-18px);
                    }
                }
            }
        }

        .bullet-box {
            margin: {
                top: 30px;
                bottom: 30px;
            }
            padding: {
                top: 15px;
                right: 25px;
                bottom: 5px;
                left: 25px;
            }
            // border-radius: 15px;

            &--outline {
                @include clearfix;

                border: 1px solid #ccc;
            }

            h2 {
                font-weight: $bold;
                font-size: 20px;
            }

            ul {
                margin: {
                    top: 10px;
                    left: 20px;
                }

                li {
                    position: relative;
                    margin: {
                        top: 15px;
                        bottom: 15px;
                    }

                    &:before {
                        width: 14px;
                        height: 10px;
                        background-image: url('../img/svg/svg--logo-icon.svg');
                        background-size: cover;
                        transform: translate(-18px, 5px);
                        content: '';
                        /* content: '•';
                        font-size: 13px;
                        transform: translateX(-10px); */
                        position: absolute;
                        left: 0;
                    }
                    span {
                      &.link {
                          font-weight: $bold;
                          color: $red;
                          text-decoration: underline;
                        &.-phone {
                          text-decoration: none;
                        }
                      }
                    }

                    a {
                        color: $text-black;
                        text-decoration: none;
                        font-weight: $bold;
                    }
                }
            }

            &.-pink {
                background-color: $red-light;
            }

            &.-no-pad {
              padding: 0px !important;
              margin-top: 15px;
            }

            &.-final-p-smaller {
                p:not(:last-child) {
                    font-size: 11px;
                }
            }
        }

        &.-full {
          .content {
            width: 100%;
          }
        }

        .aside {
          .aside-section {

            &.aside-custom {
              a {
                text-decoration: none;
                color: $text-black;
              }

              .aside-header {
                padding-left: 20px;
                @include clearfix;
                position: relative;

                &.icon-fix {
                  padding-left: 60px;
                }

                .img {
                  // float: left;
                  // position: relative;
                  // margin-top: -7px;
                  // padding-right: 20px;
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 100%;
                  display: inline-block;
                  max-width: 35px;
                  left: 10px;

                  img {
                    display: block;
                    width: 100%;
                    height: auto;
                  }

                }

              }
            }

          }
        }

    }

    .text-cta {
        clear: both;

        &.-centered {
            p {
                text-align: center;
                font-size: 16px;
            }
        }

        p {
            font-size: 14px;
        }

        a {
            color: $red;
            font-weight: $bold;
        }

        &.-text-clear {
          padding: 0 0 55px 0;
        }

    }

    .benefit-split-line {
        margin: {
            top: 70px;
            left: 5%;
            right: 5%;
        }

        background-color: $off-black;
        height: 1px;

        &.-sponsor {
            margin: {
                top: 40px;
                bottom: 40px;
            }
        }
    }

    .link {
      color: $red;
      font-weight: $bold;
    }

// Cookies popup styles

.cc_container {
    font-size: 13px !important;
}

.cc_container a,
.cc_container a:visited {
    color: $red !important;
}

.cc_container .cc_btn,
.cc_container .cc_btn:hover,
.cc_container .cc_btn:active {
    background-color: $red !important;
    color: #fff !important;
}

.spow-full {
    border: 1px solid #00A69D;
    // border-radius: 15px;
    margin-top: 40px;

    a {
        background-image: url('../img/svg/svg--logo-spow.svg');
        background-size: 58px auto;
        background-repeat: no-repeat;
        background-position: 20px center;
        font-size: 15px;
        text-decoration: none;
        color: #00A69D;
        font-weight: $bold;
        display: block;
        padding: {
            top: 50px;
            right: 50px;
            bottom: 50px;
            left: 90px;
        }
        position: relative;

        .arrow {
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            margin-right: 20px;
        }

        @media screen and (max-width: 390px) {
            background-position: center 10px;

            padding: {
            top: 100px;
            right: 50px;
            bottom: 30px;
            left: 20px;
        }
        }
    }
}

.caveat {
    p {
        font-size: 10px;
    }
}

.search {
    li {
        list-style: none !important;
        margin-left: 0 !important;
        margin-bottom: 25px !important;
    }
}

.keyword {
    color: $red;
    font-weight: $bold;
}

.blackout {
  background: rgba(0, 0, 0, 0.7);
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 800;

  &.hide {
      display: none;
  }

    &.active {
        display: block;
    }

  .important-message {
      width: 80%;
      max-width: 500px;
      background-color: $red;
      position: fixed;
      top: 20%;
      left: 50%;
      transform: translateX(-50%);
      padding: 20px;
      color: #fff;
      z-index: 999;
      display: none;

      &.hide {
          display: none;
      }

      &.active {
        display: block;
    }

      p {
          margin-top: 15px;
          color: #fff;
      }
      a {
          margin-top: 15px;
          color: #fff;
          text-decoration: underline;
          font-weight: bold;
      }

      .accept {
          margin-top: 20px;
          padding: 10px;
          // border-radius: 5px;
          background-color: #fff;
          color: $red;
          display: inline-block;
          cursor: pointer;

          &.cross {
            background-image: url('../img/svg/svg--icon-message-close.svg');
            background-size: 20px 20px !important;
            display: block;
            width: 20px;
            height: 20px;
            background-color: rgba(255, 255, 255, 0);
            background-size: contain;
            padding: 0px !important;
            position: absolute;
            right: 20px;
            top: 0px;
          }
      }
  }
}

.fourohfour {
  a {
    color: $red;
    font-weight: $bold;
  }
}

.btn {
  display: inline-block;
  background-color: $red;
  @include font(14px, white, $semibold);
  text-decoration: none;
  text-align: center;
  padding: 10px 30px 10px 15px;
  background-image: url('../img/global/arrow--white.svg');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 7px 11px;
  // border-radius: 4px;
  border: 0;
  outline: none;
  @include animate;

  &:hover {
    background-color: $grey !important;

    &.red {
      background-color: $red-dark !important;
    }

    &.blue {
      background-color: $dark-blue !important;
    }

  }

}

.blue--box {
  display: block;
  width: 100%;
  padding: 30px;
  background-color: $light-blue;
}


.grecaptcha-badge {
  display: none !important;
  opacity: 0;
}
