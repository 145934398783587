.home--banner {

  &.-page-split {
    .home--banner-content {
      h1 {
        font-size: 36px;
      }
      p {
        font-size: 14px;
      }

      .link--list {

        ul {
          list-style: none;

          li {
            background-color: #F7F7F7;
            background-image: url('../img/global/arrow--black.svg');
            background-repeat: no-repeat;
            background-position: right 15px center;
            background-size: 8px 12px;
            border-bottom: 1px solid #DBDBDB;

            &:last-child { border-bottom: 0 }

            a {
              display: block;
              @include font(16px, black, $bold);
              text-decoration: none;
              padding: 15px 30px 15px 15px;
            }

          }

        }

      }

    }
  }

  &.-news {
    border: none;
    .home--banner-content {
      .date {
        padding-top: 20px;
        color: #797979;
      }
      .buttons {
        a {
          color: $red;
          padding: 10px 35px 10px 12px;
          width: auto;
          background-image: url('../img/global/arrow--red.svg');
          background-repeat: no-repeat;
          background-position: right 15px center;
          background-size: 8px 12px;
          font-weight: $regular;
          background-color: rgba(0,0,0,0);
          padding-left: 0;
          @include animate;

          &:hover {
            color: black;
          }

        }
      }
    }
  }

  &.-helplines {

    .home--banner-content {
      .buttons {
        display: inline-block;

        a {
          display: block;
          background-color: $blue;
          width: auto;
          text-align: left;
          margin-bottom: 15px;
          position: relative;
          padding-right: 40px;
          @include animate;

          &:hover {
            background-color: $dark-blue;
          }

          @media screen and (max-width: 380px) {
            width: calc(100% - 30px) !important;
          }

          &:after {
            display: inline-block;
            content: '';
            background-image: url('../img/global/arrow--white.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 8px 12px;
            width: 8px;
            height: 12px;
            @include valign;
            transform: translateY(-50%) rotate(90deg);
            position: absolute;
            right: 15px;
          }

        }

      }
    }

  }

  &.-claims {
    .home--banner-content {
      width: 45%;
      @media screen and (max-width: 720px) {
        width: 100%;
        padding: 20px 0 30px 0;
      }
      .buttons {

        a {
          // @include flex;
          display: inline-block;
          align-items: center;
          text-align: left;
          width: auto;
          font-size: 14px;
          padding: 10px 10px 10px 40px;
          flex: 0 1 auto;
          position: relative;

          @media screen and (max-width: 380px) {
            width: 100% !important;
            max-width: 100% !important;
            flex: auto;
            margin: 0 0 15px 0 !important;

            &:last-child { margin-bottom: 0 }

          }

          &:nth-child(1) {
            margin-right: 15px;
          }

          span {
            width: 100%;
            max-width: 20px;
            margin-right: 10px;
            @include valign;
            position: absolute;
            left: 10px;
            display: inline-block;

            img {
              display: block;
              width: 100%;
              height: auto;
            }

          }

          &.blue { background-color: $blue }
          &.red { background-color: $red }
          &.white {
            background-color: white;
            border: 1px solid #A2A2A2;
            color: $new-grey;
          }

        }

      }
    }
  }

}
