.pathways {

    &.-landing {
        margin: 0 auto;

        @media screen and (max-width: 1058px) {
            width: 652px;
        }

        @media screen and (max-width: 692px) {
            width: 285px;
        }

        @media screen and (max-width: 325px) {
            width: 100%;
        }
        
        .pathway {
            width: 285px;

            &:nth-child(3n+3) {
                margin-right: 0;

                @media screen and (max-width: 1058px) {
                    margin-right: 82px;
                }
            }

            &:nth-child(2n+2) {
                @media screen and (max-width: 1058px) {
                    margin-right: 0;
                }
            }

            @media screen and (max-width: 692px) {
                margin-right: 0;

                &:nth-child(3n+3) {
                    margin-right: 0;
                }
            }

            @media screen and (max-width: 325px) {
                width: 100%;
            }
        }
    }
}