.policy--benefits {
  background-color: $light-blue;
  padding: 60px 0;

  @media screen and (max-width: 720px) {
    padding: 40px 0;
  }

  .flex {
    @include flex;

    @media screen and (max-width: 720px) {
      flex-wrap: wrap;
    }

    .col {
      display: inline-block;

      @media screen and (max-width: 720px) {
        width: 100% !important;
        flex: auto !important;
      }

      &.copy {
        width: calc(100% - 20px);
        padding-right: 20px;
        // flex: 2;

        @media screen and (max-width: 720px) {
          padding-right: 0;
          margin-bottom: 20px;
        }

        h2 {
          @include font(26px, black, $regular);
          margin: 0 0 25px 0;

          strong { font-weight: $bold }

        }

        .benefit--list {
          margin-bottom: 20px;

          ul {
            columns: 2;

            @media screen and (max-width: 500px) {
              columns: 1;
            }

            li {
              margin-left: 1em;
              margin-bottom: 20px;
              position: relative;
              display: inline-block;

              &:before {
                display: inline-block;
                content: '\2713';
                color: $blue;
                width: 1em;
                margin-left: -1em;
                position: absolute;
                top: 0;
                left: 0;
              }

              p {
                @include font(14px, black, $bold);
                float: left;
                margin-right: 5px;
              }

              .subtext {
                clear: both;
                p {
                  font-weight: $regular;
                  float: none;
                  margin: 0;
                }
              }

              span {
                width: 13px;
                height: 13px;
                display: block;
                float: left;
                background-image: url('../img/svg/svg--icon-tooltip.svg');
                background-size: cover;
                margin-top: 5px;
                cursor: pointer;
              }

            }

          }

        }

        p {
          @include font(14px, black, $regular);

          a {
            color: $red;
            text-decoration: underline;
          }

        }

      }

      &.sidebar {
        width: 100%;
        max-width: 345px;
        // flex: 1.5;

        @media screen and (max-width: 720px) {
          max-width: 100%;
        }

        .insurance--documents {
          padding: 30px;
          background-color: white;
          border-radius: 0;

          h3 {
            @include font(18px, black, $regular);
            margin: 0 0 20px 0;
            position: relative;
            padding-left: 38px;

            span {
              display: inline-block;
              @include valign;
              position: absolute;
              left: 0;
              width: 100%;
              max-width: 25px;

              img {
                display: block;
                width: 100%;
                height: auto;
              }

            }

          }

          ul {
            list-style: none;

            li {

              a {
                @include font(14px, black, $bold);
                text-decoration: none;
                background-image: url('../img/global/arrow--red.svg');
                background-repeat: no-repeat;
                background-position: left center;
                background-size: 8px 12px;
                padding-left: 13px;
                margin: 15px 0;
                display: block;
              }

            }

          }

          .btn {
            background-image: url('../img/global/button--arrow.svg');
            background-size: 20px 10px;
            background-position: left 10px center;
            padding: 10px 10px 10px 30px;
            display: block;
            width: calc(100% - 40px);
            margin-top: 20px;
          }

        }

      }

    }

  }

}


.product--links {
  background-color: white;
  padding: 60px 0;

  @media screen and (max-width: 720px) {
    padding: 40px 0;
  }

  .flex {
    @include flex;

    @media screen and (max-width: 720px) {
      flex-wrap: wrap;
    }

    .col {
      display: inline-block;
      width: calc(100% / 3 - 20px);
      margin-left: 0;
      float: none;
      margin-right: 30px;
      margin-top: 0 !important;

      &:nth-child(3) { margin-right: 0 }

      @media screen and (max-width: 720px) {
        width: calc(50% - 20px);

        &:nth-child(odd) {
          margin-right: 30px;
        }

        &:nth-child(even) {
          margin-right: 0;
        }

      }

      @media screen and (max-width: 500px) {
        width: 100%;
        margin-right: 0 !important;
      }

    }

  }

}
