.policyholders--changes {
  background-color: $light-blue;
  padding: 60px 0 35px 0;

  @media screen and (max-width: 720px) {
    padding: 40px 0 15px 0;
  }

  h2 {
    @include font(32px, black, $regular);
    margin: 0 0 40px 0;
    text-align: center;
  }

  p, li {
    @include font(14px, black, $regular);
    padding-bottom: 20px;

    strong { font-weight: $bold }

    a {
      color: $red;
      text-decoration: underline;
    }

  }

  .flex {
    @include flex;
    margin-top: 10px;

    @media screen and (max-width: 720px) {
      flex-wrap: wrap;
    }

    .col {
      display: inline-block;
      width: calc(50% - 15px);

      @media screen and (max-width: 720px) {
        width: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }

      &:nth-child(1) { padding-right: 20px }
      &:nth-child(2) { padding-left: 20px }

      .copy {
        margin-bottom: 25px;

        h3 {
          @include font(24px, black, $regular);
          margin: 0 0 15px 0;
          position: relative;
          padding-left: 42px;

          span {
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            max-width: 30px;

            img {
              display: block;
              width: 100%;
              height: auto;
            }

          }

        }

        p, li {
          @include font(14px, black, $regular);
          padding-bottom: 20px;

          // &:last-child { padding-bottom: 0 }
          strong { font-weight: $bold }

          a {
            color: $red;
            text-decoration: underline;
          }

        }

        ul {
          list-style: disc;
          padding-left: 20px;

          li {
            padding-bottom: 10px;
          }

        }

        .btn {
          background-color: white;
          color: black;
          display: block;
          width: calc(100% - 55px);
          background-image: url('../img/global/arrow--black.svg');
          text-align: left;
          border-radius: 0;
          padding: 20px 35px 20px 20px;
          background-position: right 20px center;
        }

      }

    }

  }

}

.section--split {
  &.-maintenance {
    background-color: #F7F7F7;
    padding: 40px 0;

    .flex {
      .copy {
        h2 {
          position: relative;
          @include font(28px, black, $semibold);
          padding-left: 45px;

          &:before {
            display: inline-block;
            content: '';
            background-repeat: no-repeat;
            background-position: center;
            @include valign;
            position: absolute;
            left: 0;
            background-image: url('../img/policyholders/icon--maintenance.svg');
            background-size: 34px 30px;
            width: 34px;
            height: 30px;
          }

        }

        .btn {
          background-color: white;
          color: black;
          display: block;
          width: calc(100% - 40px);
          background-image: url('../img/global/arrow--black.svg');
          text-align: left;
          border-radius: 0;
          padding: 20px;
          background-position: right 20px center;
          text-decoration: none;
          @include animate;

          &:hover {
            background-color: $grey;
          }

        }

      }
    }

  }
}

.main-content {
  &.policyholders--docs {
    margin: 0;
    padding: 60px 0;

    .main-content-split {
      .content {

        h2 {
          @include font(28px, black, $bold);
          margin: 0 0 30px 0;
        }

        ul {
          list-style: none;

          li {
            display: block;
            width: 100%;
            border-bottom: 1px solid black;
            padding: 15px 0;

            &:last-child { border-bottom: 0 }

            a {
              display: flex;
              align-items: center;
              @include font(18px, black, $regular);
              text-decoration: none;
              background-image: url('../img/policyholders/icon--document.svg');
              background-repeat: no-repeat;
              background-position: right 10px center;
              background-size: 22px 18px;

              .img {
                display: inline-block;
                width: 100%;
                max-width: 60px;

                img {
                  display: block;
                  width: 100%;
                  height: auto;
                }

              }

              p {
                display: inline-block;
                @include font(18px, black, $regular);
                padding-left: 20px;
                padding-right: 30px;
              }

            }

          }

        }

      }
    }

  }
}

.-policyholders {
    p {
        margin-bottom: 10px;
    }

    a,
    strong {
        color: $red;
        font-weight: $bold;
        text-decoration: none;
    }
}

.policyholders {

    &-title {
        font-size: 23px;
        color: $text-black;
        font-weight: $bold;
        padding: {
            left: 35px;
            top: 7px;
            bottom: 5px;
        }
        background-image: url('../img/svg/svg--icon-church-manse-red.svg');
        background-size: 22px 33px;
        background-repeat: no-repeat;

        &.-maintenance {
            background-image: url('../img/svg/svg--icon-spanner.svg');
            background-size: 40px 43px;
            padding-left: 40px;
        }
    }

    h3 {
        font-size: 15px;
        font-weight: $semibold;
        margin-bottom: 15px;
    }

    p {
        margin-bottom: 10px;
    }

    ul {
        margin: {
            top: 10px;
            bottom: 10px;
        }

        li {
            padding-left: 10px;
            position: relative;

            &:before {
                width: 14px;
                height: 10px;
                content: '•';
                font-size: 13px;

                position: absolute;
                left: 0;
            }

            p {
                margin-bottom: 0;
            }
        }
    }
}

    .insurance-documents {
        border: 1px solid $grey;
        border-radius: 15px;
        margin-top: 30px;
        padding: {
            top: 25px;
            right: 25px;
            bottom: 25px;
            left: 25px;
        }
        text-align: center;

        &-title {
            text-align: left;
            color: $text-black;
            font-size: 25px;
            font-weight: $semibold;
            padding-left: 50px;
            padding-bottom: 20px;
            box-sizing: border-box;
            background-image: url('../img/svg/svg--icon-documents.svg');
            background-size: 35px 40px;
            background-repeat: no-repeat;
        }

        a {
            width: 160px;
            text-align: center;
            display: inline-block;
            vertical-align: top;
            text-decoration: none;
            color: $text-black;
            font-size: 17px;
            font-weight: $bold;
            margin: {
                top: 30px;
                right: 26px;
                bottom: 30px;
                left: 26px;
            }

            img {
                &.red-border {
                    border: 1px solid $red;
                }

                &.black-border {
                    border: 1px solid $text-black;
                }
            }
        }
    }
