/* Infowindow Roboto font override */
.gm-style div,
.gm-style span,
.gm-style label,
.gm-style a {
	font-family: Arial, Helvetica, sans-serif;
}

/* InfoBubble font size */
.bh-sl-window {
	font-size: 13px;
}

.bh-sl-error {
	clear: both;
	color: #ae2118;
	float: left;
	font-weight: bold;
	padding: 10px 0;
	width: 100%;
}


/* Avoid image issues with Google Maps and CSS resets */
.bh-sl-map-container {
	width: 100%;
	margin-top: 27px;

	@media (min-width: 1024px) {
		margin-bottom: 60px;
	}

	a {
		color: #005293;
		text-decoration: none;

		&:active,
		&:focus,
		&:hover {
			text-decoration: underline;
		}
	}

	img {
		border-radius: 0 !important;
		box-shadow: none !important;
		max-height: none !important;
		max-width: none !important;
	}
}


.bh-sl-container {
	@include clearfix;

	box-sizing: border-box;
	color: #555;
	font: normal 14px/1.4 Arial, Helvetica, sans-serif;
	width: 100%;
	/* Avoid issues with Google Maps and CSS frameworks */

	& > * {
		box-sizing: content-box !important;
	}

	button {
	    border: none;
	    color: #fff;
	    cursor: pointer;
	    position: absolute;
	    right: 0;
	    top: 0;
	    font: bold 14px/1.4 Arial, Helvetica, sans-serif;
	    border: 1px solid #000;
	    border-left: 0;
	    height: 33px;
	    padding: 0 16px;
	    white-space: nowrap;
	    outline: none;
	    background-color: $red;
	    background-image: url('../img/svg/svg--icon-search-white.svg');
	    background-position: center center;
	    background-size: 20px 20px;
	    background-repeat: no-repeat;
	}

		.bh-sl-form-container {
			@include clearfix;

			width: 300px;
			position: relative;
		}

		.form-input {
			width: 100%;

			input {
				width: 300px;
				box-sizing: border-box;
				border: 1px solid #000;
				border-right: 0;
				font: normal 14px/1.4 Arial, Helvetica, sans-serif;
				padding: 6px 12px;
				outline: none;
			}
		}
}

.bh-sl-container .bh-sl-loc-list {
	width: 100%;
    max-width: 370px;
    border: 1px solid $text-black;
	// border-radius: 15px;
	box-sizing: border-box;
    font-size: 13px;
    /* height: 530px; */
    overflow-x: auto;
    float: left;

    @media screen and (max-width: 1059px) {
    	max-width: 100%;
    }
}

.bh-sl-container .bh-sl-loc-list ul {
	display: block;
	clear: left;
	float: left;
	width: 100%;
	list-style: none;
	margin: 0;
	padding: 0;
 }

.bh-sl-container .bh-sl-loc-list ul li {
	border: 1px solid #fff;
	/* Adding this to prevent moving li elements when adding the list-focus class*/
	box-sizing: border-box;
	cursor: pointer;
	display: block;
	float: left;
	width: 100%;

	&:nth-child(n+4) {
    	display: none;
    }

	@media screen and (max-width: 1059px) {
    	float: left;
    	width: 33%;

    	&:nth-child(3n+1) {
    		clear: left;
    	}
    }

    @media screen and (max-width: 740px) {
    	width: 50%;

    	&:nth-child(3n+1) {
    		clear: none;
    	}

    	&:nth-child(2n+1) {
    		clear: left;
    	}
    }

    @media screen and (max-width: 620px) {
    	width: 100%;
    	float: none;

    	&:nth-child(3n+1) {
    		clear: none;
    	}

    	&:nth-child(2n+1) {
    		clear: none;
    	}
    }
}

.bh-sl-container .bh-sl-loc-list .list-label {
	background: #00192d;
	// border-radius: 15px;
	color: #fff;
	display: block;
	float: left;
	font-weight: bold;
	margin: 10px 0 0 15px;
	padding: 4px 7px;
	text-align: center;
	width: auto;
	min-width: 13px;
 }

.bh-sl-container .bh-sl-loc-list .list-details {
	margin: 15px;
 }

.bh-sl-container .bh-sl-loc-list .list-details .list-content {
	padding: 10px;
}

.bh-sl-container .bh-sl-loc-list .list-details .loc-dist {
	color: #8e8e8e;
	font-weight: bold;
	font-style: italic;
}

/* .bh-sl-container .bh-sl-loc-list .list-focus {
	border: 1px solid rgba(0, 82, 147, 0.4);
	transition: border 0.2s linear 0s, box-shadow 0.2s linear 0s;
 } */

.bh-sl-container .bh-sl-loc-list .bh-sl-noresults-title {
	font-weight: bold;
	margin: 15px;
 }

.bh-sl-container .bh-sl-loc-list .bh-sl-noresults-desc {
	margin: 0 15px;
 }

.bh-sl-container .loc-name {
    /* Picked up by both list and infowindows */
    font-family: $opensans;
    font-size: 16px;
    font-weight: $bold;
    color: #000;
    padding-left: 25px;
}

.bh-sl-container .loc-addr {
    /* Picked up by both list and infowindows */
    font-family: $opensans;
    font-size: 13px;
    font-weight: $regular;
    color: #000;
    padding-left: 25px;
    background-repeat: no-repeat;
    background-position: left center;

    a {
    	color: #000;
    	text-decoration: underline;
    }
}

.loc-phone {
	background-image: url('../img/svg/svg--icon-helplines.svg');
	background-size: 18px auto;
	padding-top: 5px;
	padding-bottom: 2px;
}

.loc-email {
	background-image: url('../img/svg/svg--icon-email.svg');
	background-size: 17px 18px;
	padding-top: 2px;
	padding-bottom: 2px;
}

.loc-web {
	background-image: url('../img/svg/svg--icon-web.svg');
	background-size: 18px 18px;
	padding-top: 2px;
	padding-bottom: 5px;
}

.bh-sl-container .bh-sl-map {
	width: 100%;
	max-width: 626px;
	height: 804px;
	float: left;
	margin-left: 24px;
	// border-radius: 15px;
	overflow: hidden;
	transform: translate3d(0px, 0px, 0px);

	@media screen and (max-width: 1059px) {
		max-width: 100%;
		margin-left: 0;
		height: 424px;
		margin-top: 20px;
	}
}

.bh-sl-container .bh-sl-pagination-container {
    // clear: both;
    width: 100%;
}

.bh-sl-container .bh-sl-pagination-container ol {
	list-style-type: none;
	margin: 0;
	padding: 10px 0;
	text-align: center;
 }

.bh-sl-container .bh-sl-pagination-container ol li {
	text-align: center;
	color: #fff;
	// border-radius: 5px;
	cursor: pointer;
	display: inline-block;
	font-family: $opensans;
	font-size: 14px;
	padding: 10px;
	background-color: $red;
	background-image: url('../img/svg/svg--arrow-white.svg');
	background-repeat: no-repeat;
	background-size: 10px 15px;
	background-position: 90% center;
	border: none;
	padding-top: 10px;
	padding-right: 40px;
	padding-bottom: 10px;
	padding-left: 30px;
	color: #fff !important;
	font-weight: 700;
	// border-radius: 15px;
	margin: {
		top: 15px;
		left: 5px;
		right: 5px;
	}

	&.bh-sl-prev {
		background-position: 10% center;
		background-image: url('../img/svg/svg--arrow-white-back.svg');
		padding-right: 30px;
		padding-left: 40px;
	}
}

.bh-sl-container .bh-sl-pagination-container ol .bh-sl-current {
	color: $red;
	cursor: auto;
	text-decoration: none;
}
