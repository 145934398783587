.hero {

    &.-about {
        margin: {
            top: 10px;
            bottom: 40px;
        }
        background-color: #FEF0F0;
        border-radius: 15px;
        height: 365px;
        position: relative;
        padding-top: 20px;
        box-sizing: border-box;

        @media screen and (max-width: 930px) {
            height: 370px;
        }
        @media screen and (max-width: 850px) {
            height: 325px;
        }

        @media screen and (max-width: 700px) {
            height: 300px;
        }

        @media screen and (max-width: 545px) {
            height: 350px;
        }

        @media screen and (max-width: 440px) {
            height: 400px;
        }

        .hero-text {
            width: 50%;
            position: absolute;
            top: 50%;
            padding: {
                left: 50px;
            }
            box-sizing: border-box;
            transform: translateY(-50%);

            h1 {
                font-size: 25px;

                @media screen and (max-width: 850px) {
                    font-size: 20px;
                }

                @media screen and (max-width: 700px) {
                    font-size: 18px;
                }
            }

            p {
                font-size: 16px;
                padding-right: 10px;

                @media screen and (max-width: 850px) {
                    font-size: 14px;
                }

                @media screen and (max-width: 700px) {
                    width: 50%;
                }

                @media screen and (max-width: 650px) {
                    width: 65%;
                }

                @media screen and (max-width: 590px) {
                    width: 100%;
                }

                @media screen and (max-width: 490px) {
                    width: 100%;
                }
            }

            @media screen and (max-width: 1018px) {
                width: 55%;
            }

            @media screen and (max-width: 700px) {
                width: 100%;
                padding-right: 50px;

                height: auto;
                position: static;
                transform: translateY(0%);
            }

            @media screen and (max-width: 660px) {
                padding-right: 50px;
                padding-top: 0;
            }

            @media screen and (max-width: 650px) {
                padding-top: 0px;
                padding-right: 30px;
                padding-left: 30px;
            }

            @media screen and (max-width: 590px) {
                padding-top: 0;
            }
        }

        span {
            width: 453px;
            height: 319px;
            display: block;
            background-image: url('../img/about.png');
            position: absolute;
            right: 25px;
            top: -40px;

            @media screen and (max-width: 1018px) {
                width: 400px;
                height: 282px;
                background-size: cover;
            }

            @media screen and (max-width: 980px) {
                width: 350px;
                height: 246px;
                background-size: cover;
                top: 20px;
            }

            @media screen and (max-width: 850px) {
                width: 300px;
                height: 211px;
                background-size: cover;
            }

            @media screen and (max-width: 765px) {
                width: 250px;
                height: 176px;
                background-size: cover;
            }

            @media screen and (max-width: 700px) {
                bottom: 20px;
                top: auto;
            }

            @media screen and (max-width: 650px) {
                width: 180px;
                height: 127px;
                background-size: cover;
            }

            @media screen and (max-width: 590px) {
                width: 100px;
                height: 70px;
                background-size: cover;
            }
        }
    }
}

.-about {
    p {
        margin-bottom: 15px;
    }

    a {
        color: $red;
        font-weight: $bold;
    }
}

.customers-needs {

    .title {
        padding-left: 50px;
        background-image: url('../img/svg/svg--icon-needs.svg');
        /* background-size: contain; */
        background-size: 40px auto;
        background-repeat: no-repeat;
        padding: {
            top: 5px;
            bottom: 5px;
        }
    }

    p {
        margin-top: 15px;
    }
}

.-values {
    font-size: 25px;
    font-weight: $bold;
    padding-bottom: 20px;

    .values {
        @include clearfix;

        margin-top: 15px;

        .value {
            width: 28%;
            float: left;
            box-sizing: border-box;
            margin-right: 8%;

            &:nth-child(3) {
                margin-right: 0;
            }

            .title {
                padding-left: 35px;
                font-size: 18px;
                margin-bottom: 10px;
                background-size: contain;
                background-repeat: no-repeat;

                &.-integrity {
                    background-image: url('../img/svg/svg--icon-integrity.svg');
                }

                &.-teamwork {
                    background-image: url('../img/svg/svg--icon-people.svg');
                }

                &.-innovation {
                    background-image: url('../img/svg/svg--icon-innovation.svg');
                }
            }

            @media screen and (max-width: 750px) {
                margin-right: 0;
                width: 100%;
                float: none;
                padding-top: 10px;
            }
        }
    }
}
