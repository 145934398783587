.section--split {
  &.-helplines {

    h2 {
      @include font(32px, black, $bold);
      margin: 0 0 20px 0;

      a {
        color: $red;
        text-decoration: none;
      }

    }

    .flex {
      align-items: flex-start;
      flex-direction: row;

      .copy {

        @media screen and (max-width: 720px) {
          &:nth-child(2) {
            h3 {
              display: none;
            }
          }
        }

        h3 {
          @include font(28px, black, $regular);
          margin: 0 0 15px 0;
          padding-left: 45px;
          position: relative;

          &:before {
            display: inline-block;
            content: '';
            background-repeat: no-repeat;
            background-position: center;
            @include valign;
            position: absolute;
            left: 0;
          }

          &.legal {
            &:before {
              background-image: url('../img/helplines/icon--legal.svg');
              background-size: 30px 31px;
              width: 30px;
              height: 31px;
            }
          }

          &.emerg {
            &:before {
              background-image: url('../img/helplines/icon--emergency.svg');
              background-size: 30px 32px;
              width: 30px;
              height: 32px;
            }
          }

          &.tax {
            &:before {
              background-image: url('../img/helplines/icon--tax.svg');
              background-size: 15px 31px;
              width: 15px;
              height: 31px;
            }
          }

        }

        h4 {
          @include font(18px, black, $bold);
          margin: 0 0 10px 0;

          a {
            font-weight: $bold;
            text-decoration: none;
          }

        }

      }

    }

    &.-note {
      background-color: #F7F7F7;
      padding: 40px 0;

      .flex {
        .copy {

          &.left {
            padding-left: 0;
          }

          @media screen and (max-width: 720px) {
            padding: 0 !important;
          }

          h3 {
            position: relative;
            padding-left: 25px;

            &:before {
              display: inline-block;
              content: '';
              background-repeat: no-repeat;
              background-position: center;
              @include valign;
              position: absolute;
              left: 0;
              background-image: url('../img/helplines/icon--note.svg');
              background-size: 6px 30px;
              width: 6px;
              height: 30px;
            }

          }
          &:nth-child(2) {
            h3 {
              &:before{
                display: none;
              }
            }
          }

        }
      }

      &.-custom {

        .flex {
          .copy {
            h3 {
              padding-left: 0;

              &:before {
                background-image: none !important;
              }

            }
          }
        }

      }

    }

  }
}

.section--split {
  &.-counselling {

    .flex {

      .img {
        width: calc(50% - 40px);

        @media screen and (max-width: 650px) {
          width: 100%;
        }

      }

      .copy {
        width: 50%;

        @media screen and (max-width: 650px) {
          width: 100%;
        }

        h2 {
          position: relative;
          padding-left: 55px;
          font-size: 28px;

          &:before {
            display: inline-block;
            content: '';
            background-repeat: no-repeat;
            background-position: center;
            @include valign;
            position: absolute;
            left: 0;
            background-image: url('../img/helplines/icon--counselling.svg');
            background-size: 43px 29px;
            width: 43px;
            height: 29px;
          }

        }

        h4 {
          @include font(18px, black, $bold);
          margin: 0 0 10px 0;

          a {
            font-weight: $bold;
            text-decoration: none;
          }

        }

      }

    }

  }
}

.section--split {
  &.-glass {

    .flex {
      flex-direction: row-reverse;

      .copy {
        padding-left: 0;
        padding-right: 40px;
        width: 50%;

        @media screen and (max-width: 650px) {
          width: 100%;
          padding-right: 0;
        }

        h2 {
          position: relative;
          padding-left: 40px;
          &:before {
            display: inline-block;
            content: '';
            background-repeat: no-repeat;
            background-position: center;
            @include valign;
            position: absolute;
            left: 0;
            background-image: url('../img/helplines/icon--glass.svg');
            background-size: 30px 30px;
            width: 30px;
            height: 30px;
          }
        }

        h4 {
          @include font(18px, black, $bold);
          margin: 0 0 10px 0;

          a {
            font-weight: $bold;
            text-decoration: none;
          }

        }

      }

      .img {
        width: calc(50% - 40px);

        @media screen and (max-width: 650px) {
          width: 100%;
        }

      }

    }

  }
}

.-helplines {
    // strong {
    //     color: $red;
    // }
}

.helpline {

    &-number {
        color: $red;
        font-weight: $bold;
        font-size: 26px;
        padding-left: 40px;
        margin-bottom: 10px;
        background-image: url('../img/svg/svg--icon-helplines.svg');
        background-size: 30px 44px;
        background-repeat: no-repeat;
        background-position: 2px -5px;
    }

    h3 {
        font-size: 15px;
        margin-bottom: 5px;
    }

    p {
        margin-bottom: 10px;
    }
}

.please-note {
    margin: {
        top: 30px;
        bottom: 30px;
    }
    padding: {
        top: 15px;
        right: 25px;
        bottom: 8px;
        left: 25px;
    }
    // border-radius: 15px;
    background-color: $light-blue;

    p {
        margin-bottom: 10px;
        font-size: 13px;
    }

    a {
        font-weight: $bold;
        color: $red;
    }
}
