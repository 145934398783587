.broker--login-banner {

  .flex {
    @include flex;

    @media screen and (max-width: 750px) {
      flex-wrap: wrap;
    }

    .banner--content {
      padding: 30px 30px 0 0;
      display: inline-block;
      width: calc(100% - 30px);

      @media screen and (max-width: 750px) {
        width: 100%;
        padding-right: 0;
        margin-bottom: 30px;
      }

      h1 {
        @include font(38px, black, $bold);
        margin: 0 0 20px 0;
        line-height: 48px;
      }

      .img {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 100%;
        min-height: 230px;
        background-image: url('../img/index/col.png');
      }

      .copy {
        // padding-top: 20px;

        p {
          @include font(14px, black, $regular);
          padding-bottom: 20px;

          &:last-child { padding-bottom: 0 }

          a {
            color: black;
            text-decoration: underline;
          }

        }

        .broker--why {
          margin-bottom: 30px;

          h2 {
            @include font(22px, black, $bold);
            margin-bottom: 15px;
          }

          .flex {
            @include flex;
            align-items: flex-start;

            .col {
              display: inline-block;
              width: calc(50% - 15px);

              @media screen and (max-width: 600px) {
                width: 100%;
              }

              &:nth-child(1) {
                margin-right: 30px;

                @media screen and (max-width: 600px) {
                  margin-right: 0;
                  margin-bottom: 10px;
                }

              }

              p, li {
                @include font(14px, black, $regular);
                padding-bottom: 20px;

                &:last-child { padding-bottom: 0 }

                strong {
                  font-weight: $semibold;
                }

                a {
                  color: black;
                  text-decoration: underline;
                }

              }

              ul {
                list-style: none;

                li {
                  padding-bottom: 10px;
                  margin-left: 1em;
                  font-size: 16px;

                  &:before {
                    display: inline-block;
                    content: '\2713';
                    color: $red;
                    width: 1em;
                    margin-left: -1em;
                  }

                  &:last-child { padding-bottom: 0 }

                }

              }

            }

          }

        }

        .buttons {

          a {
            // @include flex;
            display: inline-block;
            align-items: center;
            text-align: left;
            width: auto;
            font-size: 14px;
            padding: 10px 10px 10px 40px;
            flex: 0 1 auto;
            position: relative;
            margin-bottom: 15px;

            @media screen and (max-width: 600px) {
              width: calc(100% - 57px) !important;
              max-width: 100% !important;
              flex: auto;
              margin: 0 0 15px 0 !important;

              &:last-child { margin-bottom: 0 }

            }

            &:nth-child(1) {
              margin-right: 15px;
            }

            span {
              width: 100%;
              max-width: 20px;
              margin-right: 10px;
              @include valign;
              position: absolute;
              left: 10px;
              display: inline-block;

              img {
                display: block;
                width: 100%;
                height: auto;
              }

            }

            &.blue { background-color: $blue }
            &.red { background-color: $red }
            &.white {
              background-color: white;
              border: 1px solid #A2A2A2;
              color: $new-grey;
            }

          }

        }

      }

    }

    .banner--login {
      display: inline-block;
      width: 100%;
      max-width: 350px;

      @media screen and (max-width: 750px) {
        max-width: 100%;
      }

      .padding {
        padding: 30px 20px 20px 20px;
        background-color: $light-blue;
        @include clearfix;

        @media screen and (max-width: 750px) {
          padding-top: 20px;
        }

        .btn {
          float: right;
        }

      }

      h2 {
        @include font(38px, black, $bold);
        margin: 0 0 20px 0;
      }

      p {
        @include font(14px, black, $regular);
        padding-bottom: 20px;
      }

      .login--bottom {
        background-color: $blue;
        padding: 20px;

        p {
          @include font(14px, white, $regular);
          padding-bottom: 0;

          a {
            color: white;
            text-decoration: underline;
            font-weight: $semibold;
          }

        }

      }

    }

  }

}

.section--split {
  &.-brokers {

    .flex {
      align-items: stretch;

      .img {
        flex: 1 0 auto;
      }

      .copy {

        h2 {
          position: relative;
          padding-left: 50px;

          &:before {
            display: inline-block;
            content: '';
            background-image: url('../img/brokers/icon--schemes.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 35px 25px;
            width: 35px;
            height: 25px;
            position: absolute;
            left: 0;
            top: 11px;
          }

        }

      }

    }

  }
}


.last-login {
    @include clearfix;

    background-color: #C4C4C4;
    padding: {
        top: 15px;
        bottom: 15px;
    }

    p {
        color: #353535;
        font-family: $opensans;
        font-weight: $bold;
        font-size: 21px;
        padding-left: 40px;
        background-image: url('../img/svg/svg--icon-important-black.svg');
        background-repeat: no-repeat;
        background-size: 28px 28px;
        float: left;
    }

    .close {
        width: 28px;
        height: 28px;
        background-image: url('../img/svg/svg--icon-close.svg');
        display: block;
        float: right;
    }

    &.clicked {
        display: none;
    }
}

.login-form,
.login-contact {
    width: 100%;
    margin: {
        top: 15px;
        bottom: 15px;
    }
    float: left;
}

.login-icon {
    padding-left: 45px;
    background-image: url('../img/svg/svg--icon-elto.svg');
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 39px 35px;
}

.login-form {
    max-width: 235px;

    @media screen and (min-width: 830px) and (max-width: 997px) {
        max-width: 100%;
    }

    @media screen and (max-width: 696px) {
        max-width: 100%;
    }

    .form {
        .submit {
            margin-top: 10px;
        }
    }
}

.login-contact {
    max-width: 300px;
    margin-left: 70px;
    float: left;

    @media screen and (min-width: 830px) and (max-width: 937px) {
        margin-left: 0;
        max-width: 100%;
    }

    @media screen and (max-width: 696px) {
        margin-left: 0;
        max-width: 100%;
    }

    &--number,
    &--email  {
        color: #000;
        font-weight: $bold;
        background-position: center left;
        background-repeat: no-repeat;
        margin-top: 15px;
        padding-left: 35px;


        a {
            color: #000;
            text-decoration: none;
        }
    }

    &--number {
        background-image: url('../img/svg/svg--icon-helplines.svg');
        background-size: 22px auto;
        font-size: 26px;
    }

    &--email {
        background-image: url('../img/svg/svg--icon-email.svg');
        background-size: 27px 27px;
        font-size: 14px;
    }
}

.aside-section {

    &.-order_documents {

        .aside-content {

            p {
                margin-top: 10px;
            }

            ul {
                margin-left: 15px;
                padding: 10px 0;

                li {
                    font-size: 13px;
                    margin-top: 0px !important;
                    list-style: disc !important;
                    background-image: none !important;
                    padding: 0px !important;

                    span {
                        font-size: 10px;
                        font-weight: $semibold;
                    }
                }
            }
        }
    }

    &.-document_downloads {

        .aside-header {
            padding-right: 20px;
            padding-bottom: 8px;
        }

        .aside-content {

            ul {
                padding-bottom: 15px;

                li {
                    list-style: none !important;
                    background-image: none !important;
                    padding-left: 0px;
                    font-size: 13px;
                    font-weight: $regular;

                    a {
                        color: $red;
                        font-weight: $bold;
                        font-size: 13px;
                    }
                }
            }

            p {
                a {
                    color: $red;
                }
            }
        }
    }
}

.full-width {
    .policy-benefit-list {
        li {
            width: 100%;
        }
    }

    .download {
        font-size: 17px;
        color: #333;
        text-decoration: none;
        font-weight: $bold;
        margin-top: 20px;
        display: block;
        padding-left: 40px;
        padding-top: 8px;
        padding-bottom: 8px;
        background-image: url('../img/svg/svg--icon-documents.svg');
        background-position: center left;
        background-size: 30px auto;
        background-repeat: no-repeat;
    }
}

.member-news {
    margin-top: 30px;
}

.logged-in {
    margin-top: 20px;
    margin-bottom: 30px;
}

.keys {
    @include clearfix;

    .key {
        float: left;
        background-repeat: no-repeat;
        font-weight: $bold;
        padding: {
            left: 55px;
            top: 7px;
            bottom: 10px;
            right: 30px;
        }

        &--title {
            font-weight: $regular;
            padding-left: 0;
        }

        &--news {
            background-image: url('../img/svg/svg--icon-news.svg');
            background-size: 45px auto;
        }

        &--notice {
            background-image: url('../img/svg/svg--icon-understanding.svg');
            background-size: 37px auto;
            padding-left: 45px;
        }

        &--important {
            background-image: url('../img/svg/svg--icon-important.svg');
            background-size: 37px 37px;
            padding-left: 45px;
        }
    }
}

.notices {
    margin-top: 20px;

    .notice {
        border: 1px solid #000;
        // border-radius: 15px;
        margin-top: 20px;
        padding: {
            top: 20px;
            right: 20px;
            bottom: 20px;
            left: 20px;
        }

        &--important {
            border: 1px solid $red;
            background-color: #FFEFF0;
        }

        &-meta {
            @include clearfix;

            position: relative;
        }

        &-title {
            width: 70%;
            font-size: 25px;
            font-weight: $bold;
            background-repeat: no-repeat;
            background-position: left center;
            padding-left: 50px;
            box-sizing: border-box;

            @media screen and (max-width: 830px) {
                width: 100%;
            }

            &--notice {
                background-image: url('../img/svg/svg--icon-understanding.svg');
                background-size: 34px 34px;

            }

            &--news {
                background-image: url('../img/svg/svg--icon-news.svg');
                background-size: 40px 31px;
            }

            &--important {
                background-image: url('../img/svg/svg--icon-important.svg');
                background-size: 34px 34px;;
            }
        }

        &-date {
            font-size: 13px;
            bottom: 5px;
            right: 0;
            position: absolute;

            @media screen and (max-width: 830px) {
                float: none;
                position: static;
                margin-top: 5px;
            }
        }

        &-content {
            @include clearfix;
            margin-top: 15px;

            &--text {
                width: 56%;
                padding-left: 40px;
                box-sizing: border-box;
                float: right;

                p:not(:first-child) {
                    margin-top: 10px;
                }

                @media screen and (max-width: 650px) {
                    width: 100%;
                    padding-left: 0;
                }

                &.full-width {
                    width: 100%;
                    padding-left: 0;
                }
            }

            &--image {
                width: 44%;
                float: left;

                @media screen and (max-width: 650px) {
                    width: 100%;
                }

                img {
                    width: 100%;
                }
            }
        }
    }
}

.profile {
    margin-top: 20px;
}

// .long-title-fix {
//     li:nth-child(3) {
//         .document-amount {
//             @media screen and (min-width: 1046px) {
//                 margin-top: -32px;
//             }
//         }
//     }
// }
