.-request {
    
    a {
        color: $red;
        text-decoration: none;
        font-weight: $bold;
    }
}

.request-form {
    .form-halves {
        padding: 30px;

        &.remove-bottom {
            padding-bottom: 0;
        }

        &.remove-top {
            padding-top: 0;
        }
    }
}