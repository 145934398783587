.-sponsorship {
    p {
        margin-bottom: 10px;

        a {
            color: $red;
            text-decoration: none;
            font-weight: $bold;
        }
    }

    ul {
        li {
            font-size: 13px;
            list-style: disc;
            margin: {
                left: 30px;
                bottom: 5px;
            }

            a {
                text-decoration: none;
                color: $text-black;
            }

            ul {
                li {
                    list-style: circle;
                }
            }
        }
    }
}