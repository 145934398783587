.news--strip {
  background-color: $blue;
  padding: 20px 0;

  .flex {
    @include flex;
    align-items: flex-start;

    @media screen and (max-width: 720px) {
      flex-wrap: wrap;
    }

    .col {
      display: inline-block;
      width: 50%;

      @media screen and (max-width: 720px) {
        width: 100%;
      }

      a {
        @include font(16px, white, $regular);
        text-decoration: none;
        display: block;

        strong { font-weight: $bold }
      }

      p {
        @include font(16px, white, $regular);
      }

      &.-subscribe {
        padding-right: 20px;

        @media screen and (max-width: 720px) {
          padding-right: 0;
        }

        .ns--accordion {
          margin: 9px 0 0 0;

          .accord-title {
            background: none;
            padding: 0;
            border: none;
            position: relative;
            cursor: pointer;

            &:after {
              display: inline-block;
              content: '';
              background-image: url('../img/global/arrow--white.svg');
              background-repeat: no-repeat;
              background-position: right center;
              background-size: 11px 16px;
              width: 11px;
              height: 16px;
              @include animate;
              @include valign;
              position: absolute;
              right: 0;
            }

            &.open {
              &:after {
                transform: rotate(90deg);
              }
            }

            h4 {
              @include font(18px, white, $regular);
              margin: 0;
              position: relative;
              padding-left: 50px;

              &:before {
                display: inline-block;
                content: '';
                background-image: url('../img/news/icon--newsletter.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: 35px 36px;
                width: 35px;
                height: 36px;
                @include valign;
                position: absolute;
                left: 0;
              }

            }

          }

          .accord-content {
            border: none;
            border-radius: 0;
            margin-top: 0;
            max-height: 0;
            overflow: hidden;
            opacity: 0;
            padding-top: 8px;
            transition: max-height .4s ease-in-out, opacity .4s ease-in-out;

            &.open {
              max-height: 9999px;
              opacity: 1;
            }

            a {
              font-size: 12px;
            }

            .main--form {
              width: 70%;

              @media screen and (max-width: 720px) {
                width: 100%;
              }

            }

          }

        }

      }

      &.-email {
        padding-left: 20px;
        border-left: 1px solid #60D1FF;

        @media screen and (max-width: 720px) {
          border: none;
          padding-left: 0;
          margin-top: 20px;
        }

        a {
          background-image: url('../img/global/arrow--white.svg');
          background-repeat: no-repeat;
          background-position: right center;
          background-size: 11px 16px;
          position: relative;
          padding: 0 20px 0 55px;

          &:before {
            display: inline-block;
            content: '';
            background-image: url('../img/news/icon--email.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 37px 25px;
            width: 37px;
            height: 25px;
            @include valign;
            position: absolute;
            left: 0;
          }

          strong {
            font-weight: $bold;

            @media screen and (max-width: 720px) {
              word-break: break-all;
            }

          }

        }

      }

    }

  }

}

.news--list-main {
  padding: 60px 0;

  .flex {
    @include flex;
    flex-wrap: wrap;

    .col {
      display: inline-block;
      width: calc(50% - 15px);
      margin-right: 30px;

      &:nth-child(even) { margin-right: 0 }

      @media screen and (max-width: 600px) {
        width: 100%;
        margin-right: 0;
      }

      .image {
        min-height: 290px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;

        @media screen and (max-width: 900px) {
          min-height: 200px;
        }

      }

      .news--copy {
        padding: 30px;

        @media screen and (max-width: 500px) {
          padding: 20px 0;
        }

        h3 {
          @include font(24px, black, $bold);
          margin: 0 0 15px 0;
        }

        p {
          @include font(16px, black, $regular);
        }

        .date {
          color: #797979;
          padding-bottom: 15px;
        }

        .read {
          display: inline-block;
          width: auto;
          @include font(16px, $red, $regular);
          text-decoration: none;
          background-image: url('../img/global/arrow--red.svg');
          background-repeat: no-repeat;
          background-position: right center;
          background-size: 8px 12px;
          padding-right: 18px;
          margin-top: 20px;
          @include animate;

          &:hover {
            color: black;
          }

        }

      }

    }

  }

}

.blog--post {
  padding: 60px 0;

  .blog--intro {
    @include flex;
    align-items: flex-start;
    margin-bottom: 40px;

    @media screen and (max-width: 750px) {
      flex-wrap: wrap;
      margin-bottom: 20px;
    }

    .col {
      display: inline-block;
      width: 50%;

      @media screen and (max-width: 750px) {
        width: 100%;
      }

      &.copy {
        padding-right: 40px;
        width: calc(50% - 40px);

        @media screen and (max-width: 750px) {
          width: 100%;
          padding-right: 0;
          margin-bottom: 20px;
        }

        h1 {
          @include font(38px, black, $bold);
          margin: 0;
        }

        .date {
          display: block;
          @include font(14px, #797979, $regular);
          margin: 20px 0;
        }

        p, li {
          @include font(16px, black, $regular);
          padding-bottom: 20px;

          &:last-child { padding-bottom: 0 }

          a {
            text-decoration: underline;
            color: $red;
            font-weight: $semibold;
          }

          strong { font-weight: $bold }

        }

        ul {
          list-style: disc;
          padding-left: 20px;
          padding-bottom: 20px;

          li {
            padding-bottom: 10px;
          }

        }

      }

      .img-mob {
        display: none;

        img {
          display: block;
          width: 100%;
          height: auto;
        }

        @media screen and (max-width: 750px) {
          display: block;
          width: 100%;
          margin-bottom: 20px;
        }

      }

      .img {
        width: 100%;
        margin-bottom: 20px;

        @media screen and (max-width: 750px) {
          display: none;
        }

        img {
          display: block;
          width: 100%;
          height: auto;
        }

      }

    }

  }

  // .blog--copy {
  //   column-count: 2;
  //   column-gap: 30px;
  //
  //   @media screen and (max-width: 750px) {
  //     column-count: 1;
  //   }
  //
  //   h2 {
  //     @include font(18px, black, $semibold);
  //     margin: 0 0 15px 0;
  //   }
  //
  //   p, li {
  //     @include font(16px, black, $regular);
  //     padding-bottom: 20px;
  //
  //     &:last-child { padding-bottom: 0 }
  //
  //     a {
  //       text-decoration: underline;
  //       color: $red;
  //       font-weight: $semibold;
  //     }
  //
  //     strong { font-weight: $bold }
  //
  //   }
  //
  //   ul {
  //     list-style: disc;
  //     padding-left: 20px;
  //
  //     li {
  //       padding-bottom: 10px;
  //     }
  //
  //   }
  //
  // }

}

.blog--bottom {

  &.-articles {
    margin: 30px 0 60px 0;
  }

  .flex {
    @include flex;

    @media screen and (max-width: 720px) {
      flex-wrap: wrap;
    }

    .col {
      display: inline-block;
      background-color: #F7F7F7;
      width: calc(50% - 55px);
      padding: 20px;
      text-align: center;

      &:first-child { margin-right: 30px }

      @media screen and (max-width: 750px) {
        width: 100%;
        margin-right: 0 !important;

        &:first-child { margin-bottom: 20px }

      }

      &.-back {

        a {
          @include font(16px, black, $regular);
          text-decoration: none;

          &:before {
            display: inline-block;
            content: '';
            background-image: url('../img/global/arrow--black.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 9px 12px;
            width: 9px;
            height: 12px;
            transform: rotate(180deg);
            margin-right: 10px;
          }

        }

      }

      &.-share {

        .flex {
          align-items: center;
          justify-content: center;

          p {
            @include font(16px, black, $regular);
            display: inline-block;
            margin-right: 10px;
          }

          a {
            display: inline-block;
            width: 24px;
            height: 24px;
            background-repeat: no-repeat;
            background-size: 24px 24px;
            background-position: center;
            margin-right: 5px;

            &:last-child { margin-right: 0 }

            &.tw {
              background-image: url('../img/global/social--tw.svg');
            }

            &.fb {
              background-image: url('../img/global/social--fb.svg');
            }

            &.li {
              background-image: url('../img/global/social--li.svg');
            }

            &.email {
              background-image: url('../img/global/share--email.svg');
            }

          }

        }

      }

      &.-article {
        @include flex;
        background-color: white;
        border: 1px solid #C4C4C4;
        text-decoration: none;
        color: black;
        padding: 0;
        align-items: center;
        width: calc(50% - 15px);

        @media screen and (max-width: 720px) {
          width: 100%;
        }

        .img {
          display: inline-block;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          width: 100%;
          max-width: 150px;
          height: 100%;
          min-height: 100%;
        }

        .copy {
          padding: 20px 40px 20px 20px;
          width: calc(100% - 60px);
          text-align: left;
          background-image: url('../img/global/arrow--red.svg');
          background-repeat: no-repeat;
          background-position: right 15px center;
          background-size: 9px 15px;

          p {
            @include font(16px, black, $regular);

            &.date {
              color: #797979;
              font-size: 12px;
              padding-top: 10px;
            }

          }

        }

      }

    }

  }

}

.news--form-flex {
  @include flex;
  width: 100%;

  @media screen and (max-width: 650px) {
    flex-wrap: wrap;
  }

  .col {
    display: inline-block;
    width: 100%;

    @media screen and (max-width: 650px) {
      max-width: 100% !important;
      margin-right: 0 !important;
      margin-bottom: 0;
    }

    &.main--form {
      margin-right: 15px;

      @media screen and (max-width: 650px) {
        margin-bottom: 15px;
      }

    }

    &.submit--form {
      max-width: 150px;

      label {
        font-weight: $regular;
        font-size: 12px;

        a {
          color: white;
          text-decoration: underline;
        }

      }

    }

  }

  .form--item {
    @include flex;
    align-items: center;
    margin-bottom: 10px;

    label {
      min-width: 90px;
    }

    &.-select {
      @media screen and (max-width: 900px) {
        flex-wrap: wrap;

        label {
          display: block;
          width: 100%;
          margin-bottom: 5px;
        }

      }
    }

  }

  label {
    @include font(14px, white, $semibold);
  }

  select {
    display: block;
    background-color: white;
    @include font(14px, #272727, $regular);
    padding: 5px 10px;
    width: 100%;
    border: none;
    border-radius: 0;

    &:focus { outline: none !important }

  }

  input[type="text"] {
    margin-bottom: 10px;
  }

  input[type="text"], input[type="email"] {
    display: block;
    width: calc(100% - 20px);
    background-color: white;
    border: none !important;
    @include font(14px, #272727, $regular);
    padding: 5px 10px;

    &:focus { outline: none }

  }

  input[type="submit"] {
    display: block;
    background-color: rgba(0,0,0,0);
    @include font(14px, white, $regular);
    border: none;
    background-image: url('../img/index/arrow--submit.svg');
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 15px;
    background-size: 7px 10px;
    float: right;

    &:focus { outline: none }

  }

}
