.claims--list {
  background-color: #F7F7F7;
  padding: 60px 0;

  @media screen and (max-width: 720px) {
    padding: 40px 0;
  }

  .flex {
    @include flex;
    flex-wrap: wrap;

    .col {
      display: inline-block;
      background-color: white;
      padding: 25px;

      @media screen and (max-width: 750px) {
        width: 100% !important;
        margin-right: 0 !important;
      }

      &.-tel {
        width: calc(50% - 62.5px);
        margin-right: 25px;

        @media screen and (max-width: 750px) {
          margin-bottom: 25px;
        }

        h2 {
          &:before {
            background-image: url('../img/global/icon--phone-red.svg');
            background-size: 26px 26px;
            width: 26px;
            height: 26px;
          }
        }

      }

      &.-email {
        width: calc(50% - 62.5px);

        h2 {
          &:before {
            background-image: url('../img/global/icon--email.svg');
            background-size: 26px 18px;
            width: 26px;
            height: 18px;
          }
        }

      }

      &.-forms {
        width: calc(100% - 49px);
        margin-top: 25px;
        padding-bottom: 20px;

        h2 {
          &:before {
            background-image: url('../img/index/icon--claim.svg');
            background-size: 26px 32px;
            width: 26px;
            height: 32px;
          }
        }

      }

      h2 {
        @include font(26px, black, $bold);
        margin: 0 0 20px 0;
        position: relative;
        padding-left: 37px;

        &:before {
          display: inline-block;
          content: '';
          background-repeat: no-repeat;
          background-position: center;
          @include valign;
          position: absolute;
          left: 0;
        }

      }

      h3 {
        @include font(18px, black, $semibold);
        margin: 0 0 15px 0;

        strong {
          color: $red;
          font-weight: $bold;
        }

      }

      p, li {
        @include font(14px, black, $regular);
        padding-bottom: 15px;

        &:last-child { padding-bottom: 0 }

        a {
          color: $red;
          text-decoration: underline;
        }

        strong {
          font-weight: $bold;

          a {
            text-decoration: none;
          }

        }

      }

      .buttons {

        a {
          display: inline-block;
          // width: calc(100% / 3 - 55px);
          max-width: calc(100% / 3 - 55px);
          @include font(16px, black, $regular);
          padding: 15px 30px 15px 15px;
          text-decoration: none;
          text-align: left;
          background-color: #F7F7F7;
          background-image: url('../img/global/arrow--black.svg');
          background-repeat: no-repeat;
          background-position: right 15px center;
          background-size: 8px 12px;
          margin-right: 15px;
          margin-bottom: 15px;
          @include flex;
          align-items: center;
          text-align: left;
          @include animate;

          &:hover {
            background-color: $grey;
          }

          @media screen and (max-width: 750px) {
            max-width: 100%;

            &:nth-child(odd) {
              margin-right: 15px;
            }

            &:nth-child(even) {
              margin-right: 0;
            }

          }

          @media screen and (max-width: 500px) {
            width: calc(100% - 45px) !important;
            max-width: 100% !important;
            flex: auto;
            margin-right: 0 !important;
          }

          &:nth-child(3n+3) { margin-right: 0 }

          span {
            width: 100%;
            max-width: 20px;
            margin-right: 10px;

            img {
              display: block;
              width: 100%;
              height: auto;
            }

          }

        }

      }

    }

  }

  .bottom--copy {
    margin-top: 40px;

    p, li {
      @include font(14px, black, $regular);
      padding-bottom: 20px;

      &:last-child { padding-bottom: 0 }

      a {
        color: $red;
        text-decoration: underline;
      }

      strong { font-weight: $bold }

    }

    ul {
      list-style: disc;
      padding-left: 20px;

      li {
        padding-bottom: 10px;
      }

    }

    .bottom--copy-flex {
      @include flex;

      .col {
        display: inline-block;
        width: calc(50% - 15px);
        align-items: flex-start;

        &:first-child { margin-right: 30px }

      }

    }

  }

}

.claims--checklist {
  padding: 60px 0;
  background-color: $light-blue;

  @media screen and (max-width: 720px) {
    padding: 40px 0;
  }

  h2 {
    @include font(26px, black, $bold);
    margin: 0 0 20px 0;
  }

  .flex {
    @include flex;

    @media screen and (max-width: 720px) {
      flex-wrap: wrap;
    }

    .col {
      display: inline-block;
      width: calc(50% - 20px);

      @media screen and (max-width: 720px) {
        width: 100%;
        margin-right: 0 !important;
      }

      p, li {
        @include font(14px, black, $regular);
        padding-bottom: 20px;

        &:last-child { padding-bottom: 0 }

        a {
          color: $red;
          text-decoration: underline;
        }

        strong { font-weight: $bold }

      }

      ul {
        list-style: disc;
        padding-left: 20px;

        li {
          padding-bottom: 10px;
        }

      }

      &.copy {
        margin-right: 40px;

        @media screen and (max-width: 720px) {
          margin-bottom: 20px;
        }

        h2 {
          @include font(26px, black, $bold);
          margin: 0 0 20px 0;
        }

        ul {
          list-style: none;
          padding-left: 0;
          li {
            margin-left: 1em;

            &:before {
              display: inline-block;
              content: '\2713';
              color: $red;
              font-size: 16px;
              width: 1em;
              margin-left: -1em;
            }
          }
        }

      }

      &.buttons {

        h3 {
          @include font(24px, black, $regular);
          margin: 0 0 20px 0;
        }

        ul {
          list-style: none;
          padding-left: 0;
          padding-bottom: 20px;
          li {
            margin-left: 1em;

            &:before {
              display: inline-block;
              content: '\2713';
              color: $red;
              font-size: 16px;
              width: 1em;
              margin-left: -1em;
            }
          }
        }

        .button--list {

          a {
            display: inline-block;
            width: calc(100% - 45px);
            align-items: center;
            @include font(16px, black, $regular);
            padding: 15px 30px 15px 15px;
            text-decoration: none;
            text-align: left;
            background-color: white;
            background-image: url('../img/global/arrow--black.svg');
            background-repeat: no-repeat;
            background-position: right 15px center;
            background-size: 8px 12px;
            margin-right: 15px;
            border-bottom: 1px solid #DBDBDB;
            @include flex;
            align-items: center;
            text-align: left;
            flex: 1 0 auto;
            @include animate;

            &:hover {
              background-color: $grey;
            }

            @media screen and (max-width: 380px) {
              width: calc(100% - 45px) !important;
              flex: auto;
            }

            &:last-child { border: 0 }
            &:nth-child(3n+3) { margin-right: 0 }

            span {
              width: 100%;
              max-width: 20px;
              margin-right: 10px;

              img {
                display: block;
                width: 100%;
                height: auto;
              }

            }

          }

        }

      }

    }

  }

}

.claims--process {
  background-color: white;
  padding: 60px 0;

  @media screen and (max-width: 720px) {
    padding: 40px 0;
  }

  h2 {
    @include font(26px, black, $bold);
    margin: 0;
  }

  .flex {
    @include flex;
    margin-top: 50px;

    @media screen and (max-width: 700px) {
      flex-wrap: wrap;
    }

    .col {
      display: inline-block;
      width: calc(100% / 4 - 45px);
      text-align: center;
      border-right: 1px solid #BFE2F0;
      padding: 0 30px;

      @media screen and (max-width: 700px) {
        width: calc(50% - 61px);
        padding: 0 30px !important;
        margin-bottom: 30px;

        &:nth-child(odd) {
          border-right: 1px solid #BFE2F0 !important;
        }

        &:nth-child(even) {
          border: 0 !important;
        }

      }

      @media screen and (max-width: 450px) {
        width: 100%;
        border-right: 0 !important;
        border-bottom: 1px solid #BFE2F0 !important;
        padding: 30px 0 !important;
        margin: 0;

        &:nth-child(odd), &:nth-child(even) {
          border-right: 0 !important;
          border-bottom: 1px solid #BFE2F0 !important;
        }

        &:last-child {
          border-bottom: 0 !important;
          padding-bottom: 0 !important;
        }
        &:first-child { padding-top: 0 !important }

      }

      &:first-child { padding-left: 0 }

      &:last-child {
        border: 0;
        padding-right: 0;
      }

      h4 {
        @include font(70px, $blue, $bold);
        margin: 0 0 10px 0;

        @media screen and (max-width: 785px) {
          font-size: 60px;
        }

      }

      p {
        @include font(14px, black, $bold);
      }

    }

  }

  .footnote {
    text-align: right;
    margin-top: 30px;

    p {
      @include font(12px, black, $regular);
    }

  }

}

.section--split {
  &.-emergency {
    background-color: #F7F7F7;

    .flex {
      flex-direction: row-reverse;

      .copy {
        padding-left: 0;
        padding-right: 40px;
        width: 50%;

        @media screen and (max-width: 650px) {
          width: 100%;
          padding: 20px 0 0 0 !important;
        }

        h2 {
          position: relative;
          padding-left: 40px;

          &:before {
            display: inline-block;
            content: '';
            background-image: url('../img/helplines/icon--emergency.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 30px 32px;
            width: 30px;
            height: 32px;
            @include valign;
            position: absolute;
            left: 0;
          }

        }

        p, li {
          strong {
            a {
              text-decoration: none;
            }
          }
        }

        h4 {
          padding-left: 20px;
          strong {
            color: $red;
            font-weight: $bold;
          }
        }

      }

      .img {
        width: calc(50% - 40px);

        @media screen and (max-width: 650px) {
          width: 100%;
          min-height: 180px;
        }

      }

    }

  }
}

.pathways {

    &.-make-claim {
      margin-top: 50px;

        .pathway {

            margin-top: 40px;

            ul {

                li {
                    background-image: url('../img/svg/svg--arrow.svg');
                    background-repeat: no-repeat;
                    background-size: 8px 12px;
                    background-position: left center;
                    padding-left: 15px;
                    margin: {
                        top: 10px;
                        bottom: 10px;
                    }

                    a {
                        color: $text-black;
                        text-decoration: none;
                        font-weight: $bold;
                    }
                }

                &.-red-arrow {
                    li {
                        background-image: url('../img/svg/svg--arrow-red.svg');
                    }
                }

                &.-dark-red-arrow {
                    li {
                        background-image: url('../img/svg/svg--arrow-dark-red.svg');
                    }
                }
            }
        }
    }
}

.content {
    &.-make-claim {
        p {
            margin-top: 10px;
            margin-bottom: 10px;

            strong {
                color: $red;
            }
        }
    }
}
