.section--split {
  padding: 60px 0;

  @media screen and (max-width: 720px) {
    padding: 40px 0;
  }

  .flex {
    @include flex;
    align-items: center;
    margin-bottom: 40px;

    &:last-child { margin-bottom: 0 }

    &:nth-child(even) {
      flex-direction: row-reverse;

      .copy {
        padding-left: 0;
        padding-right: 40px;
      }

    }

    @media screen and (max-width: 650px) {
      flex-wrap: wrap;
    }

    .img {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      display: inline-block;
      width: 50%;
      min-height: 300px;

      @media screen and (max-width: 650px) {
        width: 100%;
        min-height: 180px;
      }

    }

    .copy {
      display: inline-block;
      width: calc(50% - 40px);
      padding: 20px 0 20px 40px;

      @media screen and (max-width: 650px) {
        width: 100%;
        padding: 20px 0 0 0 !important;
      }

      h2 {
        @include font(32px, black, $bold);
        margin: 0 0 20px 0;

        a {
          color: $red;
          text-decoration: none;
        }

      }

      h3 {
        @include font(26px, black, $semibold);
        margin: 0 0 15px 0;

        a {
          color: $red;
          text-decoration: none;
        }

      }

      h4 {
        @include font(18px, black, $semibold);
        margin: 0 0 15px 0;

        a {
          color: $red;
          text-decoration: none;
        }

      }

      p, li {
        @include font(14px, black, $regular);
        padding-bottom: 20px;

        &:last-child { padding-bottom: 0 }

        strong { font-weight: $bold }

      }

      a {
        color: $red;
        text-decoration: underline;
      }

      ul {
        list-style: disc;
        padding-left: 20px;

        li {
          padding-bottom: 10px;

          &:last-child {
            padding-bottom: 20px;
          }

        }

      }

      .btn {
        color: white;
        text-decoration: none;
      }

    }

  }

  &.-supporting {
    background-color: $light-blue;

    #sustainability {
      .flex {
        flex-direction: row-reverse;

        .copy {
          padding-left: 0;
          padding-right: 40px;
        }

      }
    }

    .flex {
      align-items: stretch;
      .copy {
        .link--list {

          ul {
            list-style: none;
            padding: 0;

            li {
              background-color: white;
              background-image: url('../img/global/arrow--black.svg');
              background-repeat: no-repeat;
              background-position: right 15px center;
              background-size: 8px 12px;
              border-bottom: 1px solid #DBDBDB;
              padding-bottom: 0;

              &:last-child { border-bottom: 0 }

              a {
                display: block;
                @include font(16px, black, $bold);
                text-decoration: none;
                padding: 15px 30px 15px 15px;
              }

            }

          }

        }
      }
    }

  }

  &.-blue {
    background-color: $light-blue;
  }

}
