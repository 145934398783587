.tabs {

    .general_complaints {
      border: 1px solid $off-black;
      padding: 20px;
      margin-left: 1px;
        .column {
          display: inline-block;
          margin-right: 9%;
          h3 {
            font-size: 18px;
            font-weight: $bold;
            margin-bottom: 15px;
          }
          p {
            font-size: 15px;
            color: black;
            font-weight: $regular;
            margin-bottom: 10px;
            strong {
              color: $red;
              font-weight: $bold;
            }
            span {
              float: left;
              margin-right: 10px;
                &.email {
                  display: block;
                  background-image: url('../img/svg/svg--icon-email.svg');
                  width: 21px;
                  height: 15px;
                  background-size: 21px 15px;
                }

                &.phone {
                  display: block;
                  background-image: url('../img/svg/svg--icon-helplines.svg');
                  width: 18px;
                  height: 19px;
                  background-size: 18px 19px;
                }

            }
            a {
              text-decoration: underline;
              color: $red;
              font-weight: $bold;
            }
          }
        }
        .column:last-child {
          margin-right: 0px !important;
        }

        &.-second_tab {
          .column {
            p:first-child, p:last-child {
              color: #000000;
              font-weight: $regular;
            }
          }
        }

    }

}


.pathways {

    &.-complain {
        .pathway {
            width: 46%;
            margin: {
                top: 50px;
                right: 4%;
            }
            padding: {
                top: 30px;
                left: 30px;
                right: 30px;
            }

            p {
                font-size: 14px;
                font-weight: $regular;
            }

            &.-response {
                margin: {
                    right: 0;
                    left: 4%;
                }
            }

            @media screen and (max-width: 905px) {
                width: 100%;
                margin: {
                    right: 0;
                    left: 0;
                }

                &.-response {
                    margin: {
                        right: 0;
                        left: 0;
                    }
                }
            }

            @media screen and (max-width: 450px) {
                padding: {
                    top: 55px;
                }

                &:before {
                    height: 65px;
                }

                .title {
                    padding-right: 50px;
                }
            }
        }
    }
}

.complain-info {
    @include clearfix;

    margin-top: 40px;

    p {
        font-size: 13px;
        margin-bottom: 15px;

        strong {
            font-size: 16px;
        }
    }

    .fos-telephone {
        strong {
            color: $red;
            font-size: 18px;
            padding-left: 35px;
            margin-bottom: -15px;

            display: block;
            background-image: url('../img/svg/svg--icon-helplines.svg');
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    .fos-email {
        a {
            color: $red;
            font-weight: $bold;
            padding-left: 35px;
            background-image: url('../img/svg/svg--icon-email.svg');
            background-repeat: no-repeat;

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                background-size: contain;
                background-position: -180px center;
            }
        }
    }

    .fos-web {
        a {
            color: $red;
            font-weight: $bold;
        }
    }

}
