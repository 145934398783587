.social-icons {
    @include clearfix;

    .social-icon {
        width: 21px;
        height: 21px;
        display: block;
        background-size: cover;
        float: left;
        margin-left: 10px;

        &.-twitter {
            background-image: url('../img/svg/svg--social-twitter.svg');
        }

        &.-facebook {
            background-image: url('../img/svg/svg--social-facebook.svg');
        }

        &.-youtube {
            background-image: url('../img/svg/svg--social-youtube.svg');
        }
    }

    @media screen and (max-width: 900px) {
        width: 93px;
        float: none;
        margin: {
            top: 20px;
            left: auto;
            bottom: 0;
            right: auto;
        }

        .social-icon {
            margin: {
                left: 5px;
                right: 5px;
            }
        }
    }
}