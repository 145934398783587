.big--form-banner {
  padding: 40px 0;

  h1 {
    @include font(36px, black, $bold);
    margin: 0 0 30px 0;
  }

  .flex {
    @include flex;

    @media screen and (max-width: 700px) {
      flex-wrap: wrap;
    }

    .col {
      display: inline-block;
      width: calc(50% - 25px);

      @media screen and (max-width: 700px) {
        width: 100%;
      }

      &:first-child {
        margin-right: 50px;

        @media screen and (max-width: 700px) {
          margin-right: 0;
          margin-bottom: 20px;
        }

      }

      p {
        @include font(14px, black, $regular);
        padding-bottom: 20px;

        &:last-child { padding-bottom: 0 }

        a {
          color: black;
          text-decoration: underline;
          font-weight: $semibold;
        }

        strong {
          font-weight: $bold;
        }

      }

    }

  }

}

.big--form {
  background-color: $light-blue;
  padding: 60px 0;

  .form {
    margin-top: 0;

    h2 {
      @include font(22px, black, $regular);
      margin: 0 0 60px 0;
      text-align: center;
    }

    .accordion {
      &:first-child {
        margin-top: 0;
      }

      &.completed {
        .accord-title {
          color: black;
          span {
            background-image: url("../img/svg/svg--arrow.svg");
          }
        }
      }

      .accord-title {
        border-color: white;
        background-color: white;

        &.open {
          background-color: $red;
          border-color: $red;
        }

      }

      .accord-content {
        background-color: white;
        border-color: $red;
      }

      .submit {
        border-radius: 0;
      }

    }

  }

}
