.footer {
  padding: 60px 0 30px 0;

  @media screen and (max-width: 720px) {
    padding: 30px 0 0 0;
  }

  .footer--flex {
    @include flex;
    width: calc(100% - 40px);
    max-width: 1440px;
    padding: 0 20px;
    margin: 0 auto;

    @media screen and (max-width: 840px) {
      flex-wrap: wrap;
    }

    .col {
      display: inline-block;
      margin-right: 40px;
      margin-bottom: 30px;
      flex: 1;

      &.-info {
        // flex: 2.5;

        @media screen and (max-width: 600px) {
          width: 100%;
          margin-right: 0;
          flex: auto;
          order: 5;
        }

        .footer--logo {
          display: block;
          background-image: url('../img/svg/svg--logo.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 244px 73px;
          width: 244px;
          height: 73px;
          margin-bottom: 30px;

          @media screen and (max-width: 600px) {
            display: none;
          }

        }

        p {
          @include font(12px, #656565, $regular);
          padding-bottom: 10px;

          &:last-child { padding-bottom: 0 }

        }

      }

      &.-links {
        // flex: 0.5;
        max-width: 125px;

        // @media screen and (max-width: 600px) {
        //   // max-width: 50%;
        //   width: 50%;
        //   flex: auto;
        // }

        ul {
          li {
            text-align: right;
            margin-bottom: 5px;
            &:last-child { margin-bottom: 0 }
            a {
              @include font(12px, #656565, $regular);
              text-decoration: none;
            }
          }
        }

      }

      &.-contact {
        max-width: 240px;

        a, p {
          @include font(15px, black, $bold);
          text-decoration: none;
          text-align: right;
          display: block;
          margin-bottom: 10px;
          position: relative;
          padding-right: 30px;

          &:last-child { margin-bottom: 0 }

          &:after {
            display: inline-block;
            content: '';
            background-repeat: no-repeat;
            background-position: center;
            position: absolute;
            right: 0;
            top: 0;
          }

          &.tel {
            &:after {
              background-image: url('../img/global/icon--phone.svg');
              background-size: 17px 18px;
              width: 17px;
              height: 18px;
              top: 1px;
            }
          }

          &.email {
            &:after {
              background-image: url('../img/global/icon--email-footer.svg');
              background-size: 19px 13px;
              width: 19px;
              height: 13px;
              top: 4px;
            }
          }

          &.addr {
            &:after {
              background-image: url('../img/global/icon--pin.svg');
              background-size: 15px 21px;
              width: 15px;
              height: 21px;
              right: 2px;
            }
          }

        }

      }

      &.-social {
        max-width: 96px;
        margin-right: 0;

        a {
          display: inline-block;
          width: 24px;
          height: 24px;
          background-repeat: no-repeat;
          background-size: 24px 24px;
          background-position: center;

          &.tw {
            background-image: url('../img/global/social--tw.svg');
          }

          &.fb {
            background-image: url('../img/global/social--fb.svg');
          }

          &.yt {
            background-image: url('../img/global/social--yt.svg');
          }

        }

      }

    }

  }

}
